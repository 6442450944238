import React from "react";
import { useMsal } from "@azure/msal-react";
import { Button } from "react-bootstrap";
import { LogoutOutlined } from "@ant-design/icons";

export const SignOutButton = () => {
  const { instance } = useMsal();

  const handleLogout = (logoutType) => {
    localStorage.clear();
    
    if (logoutType === "redirect") {
      instance.logoutRedirect({
        postLogoutRedirectUri: "/",
      });
    }
  };
  return (
    <Button
      onClick={() => handleLogout("redirect")}
      className="d-flex align-items-center justify-content-around btn-logout "
      style={{
        height: "41px",
        width: "113px",
        fontWeight: "500",
        color: "green",
        backgroundColor: "transparent",
        border: "2px solid green",
        textDecoration: "underline",
        transition: "background-color 0.3s ease",
      }}
    >
      <LogoutOutlined /> <span>Log out</span>
    </Button>
  );
};
