import { UserOutlined, MenuOutlined, DeleteOutlined } from "@ant-design/icons";

import {
  Addition,
  EditIcon,
  GreyClose,
  TrashIcon,
  UploadCloud,
  userGroup,
} from "../../assets/Icons";

import { useEffect, useState } from "react";

import "./Candidates.css";
import ReactCountryFlag from "react-country-flag";

import { getCountryCode } from "../../services/GetCountryCode";

import { useParams } from "react-router-dom";

import {
  deleteCandidateByID,
  getCandidateByElection,
  postCandidate,
  updateCandidate,
} from "../../services/Candidate";

import { fetchToken } from "../../services/Token";

import { notification } from "antd";

import DOMPurify from "dompurify";

const Candidates = ({ EditModeTest }) => {
  const { electionId } = useParams();

  const [showPopUp, setShowPopUp] = useState(false);

  const [selectedCountry, setSelectedCountry] = useState("");

  const [gender, setGender] = useState(null);

  const [image, setImage] = useState(null);

  const [candidateTitle, setCandidateTitle] = useState("");

  const [candidateName, setCandidateName] = useState("");

  const [candidateSurname, setCandidateSurname] = useState("");

  const [candidateSecondSurname, setCandidateSecondSurname] = useState("");

  const [localData, setLocalData] = useState(null);

  const [token, setToken] = useState(null);

  const [imageee, setImageee] = useState(null);

  const [loading, setLoading] = useState(false);

  const [candidates, setCandidates] = useState(null);

  const [showPopUpEdit, setShowPopUpEdit] = useState(false);

  const [candidateToEdit, setCandidateToEdit] = useState(null);

  const [imageEdited, setImageEdited] = useState(null);
  const [showPopUpDelete, setShowPopUpDelete] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);

  const openShowPopUpDelete = (item) => {
    setShowPopUpDelete(true);
    setItemToDelete(item);
  };
  const handlePopupDeleteClose = () => {
    setShowPopUpDelete(false);
    setItemToDelete(null);
  };
  const handleSave = async () => {
    if (!navigator.onLine) {
      notification.error({
        message: "No Internet Connection",
        description: "Please check your internet connection and try again.",
      });
      return; // Exit function if no internet connection
    }

    if (!candidateName) {
      notification.error({
        description: "Last Name is required. Please check your input.",
      });
      return;
    }

    if (!candidateSurname) {
      notification.error({
        description: "First Name is required. Please check your input.",
      });
      return;
    }

    if (!gender || gender === "gender") {
      notification.error({
        description: "Gender is required. Please select a valid gender.",
      });
      return;
    }

    if (!selectedCountry || selectedCountry === "Select a country") {
      notification.error({
        description: "Country is required. Please select a valid country.",
      });
      return;
    }
    const candidateData = {
      electionId: electionId,

      title: candidateTitle,

      name: candidateName,

      surname: candidateSurname,

      secondSurname: candidateSecondSurname,

      country: selectedCountry,

      gender: gender,

      image: image ? imageee : null,
    };

    setLoading(true);

    const response = await postCandidate(
      token,
      candidateData,
      candidateData.image
    );

    setLoading(false);

    if (response.status === 201) {
      getCandidateByElection(token, electionId).then((result) => {
        setCandidates(result);
      });
      closePopup();
      notification.success({
        description: `Candidate MR/Mme ${candidateData.name} was added successfully .`,
      });
    } else if (response.response.status === 400) {
      if (
        response.response.data.errors?.image[0] ===
        "The image field is required."
      ) {
        notification.error({
          description: "Image is required. Please upload an image.",
        });
      } else {
        notification.error({
          description: "This candidate already exists. Please check again.",
        });
      }
    }
  };

  const handleUpdateCandidate = async () => {
    if (!navigator.onLine) {
      notification.error({
        message: "No Internet Connection",
        description: "Please check your internet connection and try again.",
      });
      return; // Exit function if no internet connection
    }

    if (!candidateName) {
      notification.error({
        description: "Last Name is required. Please check your input.",
      });
      return;
    }

    if (!candidateSurname) {
      notification.error({
        description: "First Name is required. Please check your input.",
      });
      return;
    }

    if (!gender || gender === "gender") {
      notification.error({
        description: "Gender is required. Please select a valid gender.",
      });
      return;
    }

    if (!selectedCountry || selectedCountry === "Select a country") {
      notification.error({
        description: "Country is required. Please select a valid country.",
      });
      return;
    }

    const candidateData = {
      id: candidateToEdit.id,

      electionId: electionId,

      title: candidateTitle,

      name: candidateName,

      surname: candidateSurname,

      secondSurname: candidateSecondSurname,

      country: selectedCountry,

      gender: gender,

      image: imageEdited ? imageEdited : null,
    };

    setLoading(true);

    const response = await updateCandidate(token, candidateData);

    setLoading(false);

    if (response?.status === 200) {
      getCandidateByElection(token, electionId).then((result) => {
        setCandidates(result);
      });

      closePopupEdit();

      notification.success({
        description: `Candidate MR/Mme ${candidateData.name} was updated successfully .`,
      });
    } else {
      notification.error({
        description:
          response.response.data == "PostCandidate : Candidate already exists!"
            ? "Candidate already exists!"
            : "Error updating candidate. Please try again.",
      });
    }
  };

  useEffect(() => {
    let idTokenClaims = JSON.parse(localStorage.getItem("idTokenClaims"));

    setLocalData(idTokenClaims);
  }, []);

  useEffect(() => {
    if (localData !== null) {
      fetchToken(localData).then((result) => {
        setToken(result);
      });
    }
  }, [localData]);

  useEffect(() => {
    if (token !== null) {
      getCandidateByElection(token, electionId).then((result) => {
        setCandidates(result);
      });
    }
  }, [token]);

  useEffect(() => {
    if (candidates !== null) {
    }
  }, [candidates]);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    // console.log(file.type);
    if (file) {
      // Check file type
      if (
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/jpg"
      ) {
        const reader = new FileReader();

        reader.onload = (e) => {
          const img = new Image();

          img.onload = () => {
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");

            canvas.width = 1080;
            canvas.height = 1080;

            ctx.drawImage(img, 0, 0, 1080, 1080);

            const resizedImage = canvas.toDataURL("image/jpeg");

            setImage({
              dataURL: resizedImage,
              name: file.name,
              size: file.size,
            });

            setImageee(file);
          };

          img.src = e.target.result;
        };

        reader.readAsDataURL(file);
      } else {
        // File type is not supported
        // console.log("Unsupported image type. Please upload a JPG or PNG file.");
        // You can provide feedback to the user here
        notification.error({
          message: "Unsupported image type",
          description: "Please upload a JPG/PNG/JPEG file only.",
          placement: "topRight",
          duration: 3,
        });
      }
    }
  };

  const handleImageEdit = (event) => {
    const file = event.target.files[0];

    if (file) {
      if (
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/jpg"
      ) {
        const reader = new FileReader();

        reader.onload = (e) => {
          const img = new Image();

          img.onload = () => {
            const canvas = document.createElement("canvas");

            const ctx = canvas.getContext("2d");

            canvas.width = 1080;

            canvas.height = 1080;

            ctx.drawImage(img, 0, 0, 1080, 1080);

            const resizedImage = canvas.toDataURL("image/jpeg");

            setImage({
              dataURL: resizedImage,
              name: file.name,
              size: file.size,
            });

            setImageEdited(file);
          };

          img.src = e.target.result;
        };

        reader.readAsDataURL(file);
      } else {
        // File type is not supported
        // console.log("Unsupported image type. Please upload a JPG or PNG file.");
        notification.error({
          message: "Unsupported image type",
          description: "Please upload a JPG/PNG/JPEG file only.",
          placement: "topRight",
          duration: 3,
        });
        // You can provide feedback to the user here
      }
    }
  };

  const closePopup = () => {
    setShowPopUp(false);

    setImage(null);

    setSelectedCountry("");

    setGender(null);

    setCandidateTitle("");

    setCandidateName("");

    setCandidateSurname("");

    setCandidateSecondSurname("");
  };

  const closePopupEdit = () => {
    setShowPopUpEdit(false);

    setImage(null);

    setSelectedCountry("");

    setGender(null);

    setCandidateTitle("");

    setCandidateName("");

    setCandidateSurname("");

    setCandidateSecondSurname("");

    setImageEdited(null);
  };

  const handleGenderChange = (event) => {
    setGender(event.target.value);
  };

  const handleSurnameChange = (event) => {
    setCandidateSurname(event.target.value);
  };

  const handleNameChange = (event) => {
    setCandidateName(event.target.value);
  };

  const handleSecondSurnameChange = (event) => {
    setCandidateSecondSurname(event.target.value);
  };

  const handleCountryChange = (event) => {
    setSelectedCountry(event.target.value);
  };

  const handleCandidateTitle = (event) => {
    setCandidateTitle(event.target.value);
  };

  const handleEditCandidate = (candidate) => {
    setCandidateTitle(candidate.title);

    setCandidateName(candidate.lastName);

    setCandidateSurname(candidate.surname);

    setCandidateSecondSurname(candidate.secondSurname);

    const parsed = candidate.firstName.split(" ");

    const surname = parsed[0];

    const secondSurname = parsed.slice(1).join(" ");

    setCandidateSurname(surname);

    setCandidateSecondSurname(secondSurname);

    const candidateToEditData = {
      id: candidate.id,

      electionId: electionId,

      title: candidate.title,

      name: candidate.lastName,

      surname: surname,

      secondSurname: secondSurname,

      country: candidate.country,

      gender: candidate.gender,

      image: candidate.urlPhoto, // Pass the File object directly
    };

    setCandidateToEdit(candidateToEditData);

    setShowPopUpEdit(true);

    setSelectedCountry(candidate.country);

    setGender(candidate.gender);
  };

  const handleCandidateDelete = async (token, candidateID) => {
    if (!navigator.onLine) {
      notification.error({
        message: "No Internet Connection",
        description: "Please check your internet connection and try again.",
      });
      return; // Exit function if no internet connection
    }

    try {
      const status = await deleteCandidateByID(token, candidateID);

      if (status === 204) {
        const updatedCandidates = candidates.filter(
          (candidate) => candidate.id !== candidateID
        );
        setShowPopUpDelete(false);
        setItemToDelete(null);
        setCandidates(updatedCandidates);

        notification.success({
          description: "Candidate was successfully Deleted .",
        });
      } else {
        notification.error({
          description: "Failed to delete candidate. Please try again.",
        });
      }
    } catch (error) {
      notification.error({
        description: "Failed to delete candidate. Please try again.",
      });
    }
  };

  const handleButtonClick = () => {
    setShowPopUp(true);
  };

  const countriesWithoutCodes = [
    "Afghanistan",

    "Albania",

    "Algeria",

    "American Samoa",

    "Andorra",

    "Angola",

    "Anguilla",

    "Antarctica",

    "Antigua and Barbuda",

    "Argentina",

    "Armenia",

    "Aruba",

    "Australia",

    "Austria",

    "Azerbaijan",

    "Bahamas (the)",

    "Bahrain",

    "Bangladesh",

    "Barbados",

    "Belarus",

    "Belgium",

    "Belize",

    "Benin",

    "Bermuda",

    "Bhutan",

    "Bolivia",

    "Bonaire",

    "Bosnia and Herzegovina",

    "Botswana",

    "Bouvet Island",

    "Brazil",

    "British Indian Ocean Territory (the)",

    "Brunei Darussalam",

    "Bulgaria",

    "Burkina Faso",

    "Burundi",

    "Cabo Verde",

    "Cambodia",

    "Cameroon",

    "Canada",

    "Cayman Islands (the)",

    "Central African Republic",

    "Chad",

    "Chile",

    "China",

    "Christmas Island",

    "Cocos (Keeling) Islands (the)",

    "Colombia",

    "Comoros",

    "RD Congo",

    "Congo (the)",

    "Cook Islands (the)",

    "Costa Rica",

    "Croatia",

    "Cuba",

    "Curaçao",

    "Cyprus",

    "Czechia",

    "Cote d'ivoire",

    "Denmark",

    "Djibouti",

    "Dominica",

    "Dominican Republic (the)",

    "Ecuador",

    "Egypt",

    "El Salvador",

    "Equatorial Guinea",

    "Eritrea",

    "Estonia",

    "Eswatini",

    "Ethiopia",

    "Falkland Islands (the) [Malvinas]",

    "Faroe Islands (the)",

    "Fiji",

    "Finland",

    "France",

    "French Guiana",

    "French Polynesia",

    "French Southern Territories (the)",

    "Gabon",

    "Gambia (the)",

    "Georgia",

    "Germany",

    "Ghana",

    "Gibraltar",

    "Greece",

    "Greenland",

    "Grenada",

    "Guadeloupe",

    "Guam",

    "Guatemala",

    "Guernsey",

    "Guinea",

    "Guinea-Bissau",

    "Guyana",

    "Haiti",

    "Heard Island and McDonald Islands",

    "Holy See (the)",

    "Honduras",

    "Hong Kong",

    "Hungary",

    "Iceland",

    "India",

    "Indonesia",

    "Iran",

    "Iraq",

    "Ireland",

    "Isle of Man",

    "Italy",

    "Jamaica",

    "Japan",

    "Jersey",

    "Jordan",

    "Kazakhstan",

    "Kenya",

    "Kiribati",

    "Korea (the Democratic People's Republic of)",

    "Korea (the Republic of)",

    "Kuwait",

    "Kyrgyzstan",

    "Lao People's Democratic Republic (the)",

    "Latvia",

    "Lebanon",

    "Lesotho",

    "Liberia",

    "Libya",

    "Liechtenstein",

    "Lithuania",

    "Luxembourg",

    "Macao",

    "Madagascar",

    "Malawi",

    "Malaysia",

    "Maldives",

    "Mali",

    "Malta",

    "Marshall Islands (the)",

    "Martinique",

    "Mauritania",

    "Mauritius",

    "Mayotte",

    "Mexico",

    "Micronesia (Federated States of)",

    "Moldova (the Republic of)",

    "Monaco",

    "Mongolia",

    "Montenegro",

    "Montserrat",

    "Morocco",

    "Mozambique",

    "Myanmar",

    "Namibia",

    "Nauru",

    "Nepal",

    "Netherlands (the)",

    "New Caledonia",

    "New Zealand",

    "Nicaragua",

    "Niger (the)",

    "Nigeria",

    "Niue",

    "Norfolk Island",

    "Northern Mariana Islands (the)",

    "Norway",

    "Oman",

    "Pakistan",

    "Palau",

    "Palestine, State of",

    "Panama",

    "Papua New Guinea",

    "Paraguay",

    "Peru",

    "Philippines (the)",

    "Pitcairn",

    "Poland",

    "Portugal",

    "Puerto Rico",

    "Qatar",

    "Republic of North Macedonia",

    "Romania",

    "Russian Federation (the)",

    "Rwanda",

    "Réunion",

    "Saint Barthélemy",

    "Saint Helena, Ascension and Tristan da Cunha",

    "Saint Kitts and Nevis",

    "Saint Lucia",

    "Saint Martin (French part)",

    "Saint Pierre and Miquelon",

    "Saint Vincent and the Grenadines",

    "Samoa",

    "San Marino",

    "Sao Tome and Principe",

    "Saudi Arabia",

    "Senegal",

    "Serbia",

    "Seychelles",

    "Sierra Leone",

    "Singapore",

    "Sint Maarten (Dutch part)",

    "Slovakia",

    "Slovenia",

    "Solomon Islands",

    "Somalia",

    "South Africa",

    "South Georgia and the South Sandwich Islands",

    "South Sudan",

    "Spain",

    "Sri Lanka",

    "Sudan (the)",

    "Suriname",

    "Svalbard and Jan Mayen",

    "Sweden",

    "Switzerland",

    "Syrian Arab Republic",

    "Taiwan (Province of China)",

    "Tajikistan",

    "Tanzania, United Republic of",

    "Thailand",

    "Timor-Leste",

    "Togo",

    "Tokelau",

    "Tonga",

    "Trinidad and Tobago",

    "Tunisia",

    "Turkey",

    "Turkmenistan",

    "Turks and Caicos Islands (the)",

    "Tuvalu",

    "Uganda",

    "Ukraine",

    "United Arab Emirates (the)",

    "United Kingdom of Great Britain and Northern Ireland (the)",

    "United States Minor Outlying Islands (the)",

    "United States of America (the)",

    "Uruguay",

    "Uzbekistan",

    "Vanuatu",

    "Venezuela (Bolivarian Republic of)",

    "Viet Nam",

    "Virgin Islands (British)",

    "Virgin Islands (U.S.)",

    "Wallis and Futuna",

    "Western Sahara",

    "Yemen",

    "Zambia",

    "Zimbabwe",

    "Åland Islands",
  ];

  return (
    <div
      className=""
      style={{
        height: "100%",
        marginLeft: "2%",
        marginRight: "2%",
        backgroundColor: "#FFF",
      }}
    >
      <div
        style={{ backgroundColor: "rgba(208, 208, 208, 0.07)", height: "80%" }}
      >
        <div
          className="d-flex align-items-center px-2"
          style={{  justifyContent: "space-between" }}
        >
          <div
            className="d-flex align-items-center"
          
          >
            <div className="d-flex">
              <UserOutlined style={{ color: "green", fontSize: "25px" }} />

              <MenuOutlined
                style={{
                  color: "green",
                  marginBottom: "5px",
                  marginLeft: "1px",
                }}
              />
            </div>

            <p
              style={{
                marginLeft: "1%",
                marginTop: "1%",
                color: "#2D3748",
                fontFamily: "DM Sans",
                fontSize: "23px",
                fontStyle: "normal",
                fontWeight: "700",
                whiteSpace:"pre"
            
              }}
            >
              Candidates List/Liste des candidats
            </p>
          </div>

          <div>
            {EditModeTest && (
              <button
                type="button"
                class="btn btn-outline-success"
                onClick={handleButtonClick}
              >
                {Addition} Add a Candidate
              </button>
            )}
          </div>
        </div>

        <div className="px-3 table-candidate" style={{ height: "100%" }}>
          <table style={{ width: "100%", borderCollapse: "collapse" }}>
            <thead>
              <tr>
                <th
                  style={{
                    backgroundColor: "rgba(255, 255, 255, 0.46)",
                    padding: "8px",
                    textAlign: "left",
                  }}
                >
                  Last Name / Nom
                </th>

                <th
                  style={{
                    backgroundColor: "rgba(255, 255, 255, 0.46)",
                    padding: "8px",
                    textAlign: "left",
                  }}
                >
                  First Name / Prénoms
                </th>
                <th
                  style={{
                    backgroundColor: "rgba(255, 255, 255, 0.46)",
                    padding: "8px",
                    textAlign: "left",
                  }}
                >
                  Title / Titre
                </th>
                <th
                  style={{
                    backgroundColor: "rgba(255, 255, 255, 0.46)",
                    padding: "8px",
                    textAlign: "left",
                  }}
                >
                  Gender / Genre
                </th>

                <th
                  style={{
                    backgroundColor: "rgba(255, 255, 255, 0.46)",
                    padding: "8px",
                    textAlign: "left",
                  }}
                >
                  Country / Pays
                </th>
                {EditModeTest && (
                  <th
                    style={{
                      backgroundColor: "rgba(255, 255, 255, 0.46)",
                      padding: "8px",
                      width: "10%",
                      textAlign: "left",
                    }}
                  >
                    Actions
                  </th>
                )}
              </tr>
            </thead>

            <tbody>
              {candidates?.map((candidate, index) => (
                <tr
                  key={index}
                  style={{
                    backgroundColor:
                      index % 2 === 0 ? "#F6F6F6" : "rgba(255, 255, 255, 0.46)",
                  }}
                >
                  <td
                    style={{
                      padding: "8px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={DOMPurify.sanitize(
                        `${candidate.urlPhoto}?${process.env.REACT_APP_SIGNATURE}`
                      )}
                      alt={candidate.name}
                      style={{
                        width: "45px",
                        height: "45px",
                        borderRadius: "30%",
                        marginRight: "10px",
                      }}
                    />

                    {candidate.lastName}
                  </td>

                  <td style={{ padding: "8px" }}>{candidate.firstName}</td>
                  <td style={{ padding: "8px" }}>{candidate.title}</td>
                  <td style={{ padding: "8px" }}>{candidate.gender}</td>
                  <td style={{ padding: "8px" }}>
                    {" "}
                    <span>
                      <ReactCountryFlag
                        countryCode={getCountryCode(candidate.country)}
                        svg
                        style={{
                          width: "1.18756rem",
                          height: "0.89063rem",
                          marginRight: "0.5rem",
                          marginLeft: "0.5rem",
                        }}
                      />
                    </span>
                    {candidate.country}
                  </td>
                  {EditModeTest && (
                    <td style={{ padding: "8px" }}>
                      <button
                        style={{
                          border: "none",
                          backgroundColor:
                            index % 2 === 0
                              ? "#F6F6F6"
                              : "rgba(255, 255, 255, 0.46)",
                        }}
                        onClick={() => handleEditCandidate(candidate)}
                      >
                        {EditIcon}
                      </button>

                      <button
                        style={{
                          border: "none",
                          backgroundColor:
                            index % 2 === 0
                              ? "#F6F6F6"
                              : "rgba(255, 255, 255, 0.46)",
                        }}
                        onClick={() => openShowPopUpDelete(candidate)}
                      >
                        {TrashIcon}
                      </button>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {showPopUpDelete && (
        <div className="PopupConfirmStart">
          <div className="contentConfirmStart">
            {/* Display based on showEndButton state */}
            <div className="Header">
              <div className="TickIcon">
                <DeleteOutlined
                  style={{ fontSize: "32px", color: "#BC0000" }}
                />
              </div>
              <button
                style={{ border: "none", backgroundColor: "#FFF" }}
                onClick={handlePopupDeleteClose}
              >
                {GreyClose}
              </button>
            </div>
            <p className="informingText">
              Would you please confrm deleting the candidate{" "}
              {itemToDelete.firstName}?
              <br />
              Please note that once the Candidate is deleted, it cannot be
              recovered .
            </p>
            {/* Buttons for confirmation and cancellation */}
            <div className="abstainButtons">
              <button
                className="buttonChange"
                style={{ border: "1px solid #BC0000", color: "#BC0000" }}
                onClick={handlePopupDeleteClose}
              >
                Retourner/Go back
              </button>

              <button
                className="buttonConfirm"
                style={{ background: "#BC0000", color: "#FFF" }}
                onClick={() => handleCandidateDelete(token, itemToDelete.id)}
              >
                Confirmer/Confirm
              </button>
            </div>
          </div>
        </div>
      )}
      {/***********************************************************POP UP TO ADD CANDIDATE*****************************************/}

      {loading ? (
        <div className="PopupValidation">
          <div
            className="contentConfirmStartvalidation"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              class="spinner-grow"
              style={{
                width: "2rem",
                height: "2rem",
                marginRight: "5px",
                color: "green",
              }}
              role="status"
            >
              <span class="visually-hidden">Loading...</span>
            </div>
            <div
              class="spinner-grow"
              style={{
                width: "2rem",
                height: "2rem",
                marginRight: "5px",
                color: "green",
              }}
              role="status"
            >
              <span class="visually-hidden">Loading...</span>
            </div>
            <div
              class="spinner-grow"
              style={{ width: "2rem", height: "2rem", color: "green" }}
              role="status"
            >
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      ) : (
        showPopUp && (
          <div className="PopupValidation condidateModal">
            <div className="contentConfirmStartvalidation">
              {/*************************************************HEADER OF POP UP */}

              <div
                className="d-flex"
                style={{ marginLeft: "5%", height: "10%", width: "95%" }}
              >
                <div style={{ width: "7%" }}>{userGroup} </div>
                <div style={{ width: "85%" }}>
                  <p
                    style={{
                      fontFamily: "DM Sans",
                      fontWeight: "700",
                      fontSize: "18px",
                      color: "#101828",
                      lineHeight: "28px",
                      marginLeft: "2%",
                    }}
                  >
                    Add a candidate
                  </p>
                </div>

                <button
                  style={{ border: "none", backgroundColor: "#FFF" }}
                  onClick={closePopup}
                >
                  {GreyClose}
                </button>
              </div>

              {/************************************************* 1ST LINE IN POP UP  *************************************************/}

              {/* Label and input for title */}

              <div
                style={{
                  marginLeft: "5%",
                  marginRight: "5%",
                  marginTop: "2%",
                  height: "10%",
                }}
              >
                <label
                  htmlFor="candidateTitle"
                  style={{
                    fontFamily: "DM Sans",
                    fontWeight: "500",
                    fontSize: "14px",
                    color: "#344054",
                    lineHeight: "20px",
                    display: "block",
                  }}
                >
                  Title
                </label>

                <input
                  type="text"
                  id="candidateTitle"
                  placeholder="Title Example"
                  style={{
                    width: "100%",

                    height: "44px",

                    padding: "10px 14px",

                    borderRadius: "8px",

                    border: "1px solid #D0D5DD",

                    boxShadow: "0px 1px 2px 0px #1018280D",

                    background:
                      "linear-gradient(0deg, #FFFFFF, #FFFFFF), linear-gradient(0deg, #D0D5DD, #D0D5DD)",

                    gap: "8px",

                    display: "block",
                  }}
                  onChange={handleCandidateTitle}
                />
              </div>

              {/************************************2ND LINE IN POP UP  *********************************************************/}

              {/* Label and input for Name */}

              <div
                style={{
                  marginLeft: "5%",
                  marginRight: "5%",
                  marginTop: "2%",
                  height: "10%",
                }}
              >
                <label
                  htmlFor="candidateName"
                  style={{
                    fontFamily: "DM Sans",
                    fontWeight: "500",
                    fontSize: "14px",
                    color: "#344054",
                    lineHeight: "20px",
                    display: "block",
                  }}
                >
                  Last Name
                </label>

                <input
                  type="text"
                  id="candidateName"
                  placeholder="Name Example"
                  style={{
                    width: "100%",

                    height: "44px",

                    padding: "10px 14px",

                    borderRadius: "8px",

                    border: "1px solid #D0D5DD",

                    boxShadow: "0px 1px 2px 0px #1018280D",

                    background:
                      "linear-gradient(0deg, #FFFFFF, #FFFFFF), linear-gradient(0deg, #D0D5DD, #D0D5DD)",

                    gap: "8px",

                    display: "block",
                  }}
                  onChange={handleNameChange}
                />
              </div>

              {/**********************************3RD Line IN POP UP **************************************************************/}

              <div
                className="d-flex"
                style={{
                  marginTop: "2%",
                  marginLeft: "5%",
                  height: "10%",
                  marginRight: "5%",
                }}
              >
                {/**Surname input */}

                <div style={{ backgroundColor: "", width: "50%" }}>
                  <label
                    htmlFor="candidateName"
                    style={{
                      fontFamily: "DM Sans",
                      fontWeight: "500",
                      fontSize: "14px",
                      color: "#344054",
                      lineHeight: "20px",
                      display: "block",
                    }}
                  >
                    First Name
                  </label>

                  <input
                    type="text"
                    id="candidateName"
                    placeholder="Surname example"
                    style={{
                      width: "95%",

                      height: "60%",

                      padding: "10px 14px",

                      borderRadius: "8px",

                      border: "1px solid #D0D5DD",

                      boxShadow: "0px 1px 2px 0px #1018280D",

                      background:
                        "linear-gradient(0deg, #FFFFFF, #FFFFFF), linear-gradient(0deg, #D0D5DD, #D0D5DD)",

                      gap: "8px",

                      display: "block",
                    }}
                    onChange={handleSurnameChange}
                  />
                </div>

                {/**Second Surname */}

                <div style={{ backgroundColor: "", width: "50%" }}>
                  <label
                    htmlFor="candidateName"
                    style={{
                      fontFamily: "DM Sans",
                      fontWeight: "500",
                      fontSize: "14px",
                      color: "#344054",
                      lineHeight: "20px",
                      display: "block",
                    }}
                  >
                    Second Name
                  </label>

                  <input
                    type="text"
                    id="candidateName"
                    placeholder="Second Surname example"
                    style={{
                      width: "100%",

                      height: "60%",

                      padding: "10px 14px",

                      borderRadius: "8px",

                      border: "1px solid #D0D5DD",

                      boxShadow: "0px 1px 2px 0px #1018280D",

                      background:
                        "linear-gradient(0deg, #FFFFFF, #FFFFFF), linear-gradient(0deg, #D0D5DD, #D0D5DD)",

                      gap: "8px",

                      display: "block",
                    }}
                    onChange={handleSecondSurnameChange}
                  />
                </div>
              </div>

              {/*************************************** 4TH Line IN POP UP *****************************************/}

              <div
                className="d-flex justify-content-between"
                style={{
                  marginTop: "2%",
                  marginLeft: "5%",
                  marginRight: "5%",
                  height: "10%",
                }}
              >
                {/**Country input */}

                <div style={{ width: "50%", alignItems: "center" }}>
                  <label
                    htmlFor="candidateName"
                    style={{
                      fontFamily: "DM Sans",
                      fontWeight: "500",
                      fontSize: "14px",
                      color: "#344054",
                      lineHeight: "20px",
                      display: "block",
                    }}
                  >
                    Country
                  </label>

                  <div class="input-group">
                    <div
                      class="input-group-prepend"
                      style={{ height: "2.5rem" }}
                    >
                      {selectedCountry && (
                        <span class="input-group-text">
                          <ReactCountryFlag
                            countryCode={getCountryCode(selectedCountry)}
                            svg
                            style={{
                              width: "1.5rem",

                              height: "1.5rem",
                            }}
                          />
                        </span>
                      )}
                    </div>

                    <select
                      value={selectedCountry}
                      onChange={handleCountryChange}
                      style={{
                        fontFamily: "DM Sans",
                        fontWeight: "500",
                        fontSize: "14px",
                        color: "#344054",
                        lineHeight: "15px",
                        width: "70%",
                      }}
                    >
                      <option value="">Select a country</option>

                      {countriesWithoutCodes.map((country, index) => (
                        <option key={index} value={country}>
                          {country}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                {/**Gender*/}

                <div
                  style={{ width: "50%", alignItems: "center", height: "100%" }}
                >
                  <label
                    htmlFor="candidateName"
                    style={{
                      fontFamily: "DM Sans",
                      fontWeight: "500",
                      fontSize: "14px",
                      color: "#344054",
                      lineHeight: "20px",
                      display: "block",
                    }}
                  >
                    Gender
                  </label>

                  <div
                    style={{ display: "flex", width: "100%", height: "2.5rem" }}
                  >
                    <select
                      className="custom-select"
                      id="inputGroupSelect01"
                      style={{ width: "100%" }}
                      onChange={handleGenderChange}
                      value={gender}
                    >
                      <option value="gender">Select a gender</option>

                      <option value="male">Male</option>

                      <option value="female">Female</option>
                    </select>
                  </div>
                </div>
              </div>

              {/**image drag and drop  */}

              <label
                htmlFor="candidateName"
                style={{
                  fontFamily: "DM Sans",
                  fontWeight: "500",
                  fontSize: "14px",
                  color: "#344054",
                  lineHeight: "20px",
                  display: "block",
                  marginLeft: "5%",
                  marginTop: "2%",
                }}
              >
                Picture*
              </label>

              <div
                style={{
                  width: "90%",

                  height: "30%",

                  alignItems: "center",

                  flexShrink: 0,

                  borderRadius: "0.5rem",

                  border: "1px solid var(--Gray-300, #D0D5DD)",

                  background: "var(--Base-White, #FFF)",

                  boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",

                  marginLeft: "5%",

                  marginRight: "5%",

                  overflow: "auto",

                  display: "flex",

                  marginBottom: "2%",
                }}
              >
                <input
                  type="file"
                  id="image"
                  accept="image/*"
                  onChange={handleImageUpload}
                  style={{ display: "none" }}
                />

                <label
                  htmlFor="image"
                  style={{ cursor: "pointer", width: "100%" }}
                >
                  <div
                    style={{ height: "100%", width: "100%", overflow: "auto" }}
                    className="d-flex align-items-center"
                  >
                    {image ? (
                      <>
                        <div
                          style={{
                            width: "30%",
                            height: "100%",
                            display: "flex",
                            justifyContent: "flex-start",
                          }}
                        >
                          <img
                            src={image.dataURL}
                            alt="Uploaded"
                            style={{
                              width: "100%",
                              height: "auto",
                              maxHeight: "100%",
                              marginRight: "90%",
                              padding: "10px",
                            }}
                          />
                        </div>

                        <div>
                          <div>
                            <p>Name: {image ? image.name : "-"}</p>

                            <p>
                              Size:{" "}
                              {image
                                ? (image.size / 1024).toFixed(2) + " KB"
                                : "-"}
                            </p>
                          </div>
                        </div>
                      </>
                    ) : (
                      <div
                        style={{
                          padding: "0.5rem",
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <div className="d-flex justify-content-center">
                          {UploadCloud}
                        </div>

                        <div>
                          <p
                            style={{
                              color: "#00A86A",
                              display: "inline-block",
                              marginRight: "8px",
                              fontFamily: "Inter",
                              fontSize: "1.125rem",
                              fontStyle: "normal",
                              fontWeight: "600",
                              lineHeight: "1.25rem",
                            }}
                          >
                            Click to upload
                          </p>

                          <p className="text-center">
                            SVG, PNG, JPG (max. 800x400px)
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                </label>
              </div>

              {/**Button Save & Cancel*/}

              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginRight: "5%",
                  marginTop: "1.5rem",
                }}
              >
                <button
                  type="button"
                  class="btn btn-outline-success"
                  style={{ width: "15%" }}
                  onClick={closePopup}
                >
                  Cancel
                </button>

                <button
                  type="button"
                  class="btn btn-success"
                  style={{ marginLeft: "2%", width: "15%" }}
                  onClick={handleSave}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        )
      )}

      {/***********************************************************END OF POP UP CODE*****************************************/}

      {/***********************************************************POP UP TO EDIT CANDIDATE*****************************************/}

      {loading ? (
        <div className="PopupValidation">
          <div
            className="contentConfirmStartvalidation"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              class="spinner-grow"
              style={{
                width: "2rem",
                height: "2rem",
                marginRight: "5px",
                color: "green",
              }}
              role="status"
            >
              <span class="visually-hidden">Loading...</span>
            </div>
            <div
              class="spinner-grow"
              style={{
                width: "2rem",
                height: "2rem",
                marginRight: "5px",
                color: "green",
              }}
              role="status"
            >
              <span class="visually-hidden">Loading...</span>
            </div>
            <div
              class="spinner-grow"
              style={{ width: "2rem", height: "2rem", color: "green" }}
              role="status"
            >
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      ) : (
        showPopUpEdit && (
          <div className="PopupValidation condidateModal">
            <div className="contentConfirmStartvalidation">
              {/*************************************************HEADER OF POP UP */}

              <div
                className="d-flex"
                style={{ marginLeft: "5%", height: "10%" }}
              >
                <div>{userGroup} </div>
                <div style={{ width: "85%" }}>
                  <p
                    style={{
                      fontFamily: "DM Sans",
                      fontWeight: "700",
                      fontSize: "18px",
                      color: "#101828",
                      lineHeight: "28px",
                      marginLeft: "2%",
                    }}
                  >
                    Update candidate
                  </p>
                </div>

                <button
                  style={{ border: "none", backgroundColor: "#FFF" }}
                  onClick={closePopupEdit}
                >
                  {GreyClose}
                </button>
              </div>

              {/************************************************* 1ST LINE IN POP UP  *************************************************/}

              {/* Label and input for title */}

              <div
                style={{
                  marginLeft: "5%",
                  marginRight: "5%",
                  marginTop: "2%",
                  height: "10%",
                }}
              >
                <label
                  htmlFor="candidateTitle"
                  style={{
                    fontFamily: "DM Sans",
                    fontWeight: "500",
                    fontSize: "14px",
                    color: "#344054",
                    lineHeight: "20px",
                    display: "block",
                  }}
                >
                  Title
                </label>

                <input
                  type="text"
                  id="candidateTitle"
                  placeholder="Title Example"
                  style={{
                    width: "100%",

                    height: "44px",

                    padding: "10px 14px",

                    borderRadius: "8px",

                    border: "1px solid #D0D5DD",

                    boxShadow: "0px 1px 2px 0px #1018280D",

                    background:
                      "linear-gradient(0deg, #FFFFFF, #FFFFFF), linear-gradient(0deg, #D0D5DD, #D0D5DD)",

                    gap: "8px",

                    display: "block",
                  }}
                  defaultValue={candidateToEdit.title}
                  onChange={handleCandidateTitle}
                />
              </div>

              {/************************************2ND LINE IN POP UP  *********************************************************/}

              {/* Label and input for Name */}

              <div
                style={{
                  marginLeft: "5%",
                  marginRight: "5%",
                  marginTop: "2%",
                  height: "10%",
                }}
              >
                <label
                  htmlFor="candidateName"
                  style={{
                    fontFamily: "DM Sans",
                    fontWeight: "500",
                    fontSize: "14px",
                    color: "#344054",
                    lineHeight: "20px",
                    display: "block",
                  }}
                >
                  Last Name
                </label>

                <input
                  type="text"
                  id="candidateName"
                  placeholder="Name Example"
                  style={{
                    width: "100%",

                    height: "44px",

                    padding: "10px 14px",

                    borderRadius: "8px",

                    border: "1px solid #D0D5DD",

                    boxShadow: "0px 1px 2px 0px #1018280D",

                    background:
                      "linear-gradient(0deg, #FFFFFF, #FFFFFF), linear-gradient(0deg, #D0D5DD, #D0D5DD)",

                    gap: "8px",

                    display: "block",
                  }}
                  onChange={handleNameChange}
                  defaultValue={candidateToEdit.name}
                />
              </div>

              {/**********************************3RD Line IN POP UP **************************************************************/}

              <div
                className="d-flex"
                style={{
                  marginTop: "2%",
                  marginLeft: "5%",
                  height: "10%",
                  marginRight: "5%",
                }}
              >
                {/**Surname input */}

                <div style={{ backgroundColor: "", width: "50%" }}>
                  <label
                    htmlFor="candidateName"
                    style={{
                      fontFamily: "DM Sans",
                      fontWeight: "500",
                      fontSize: "14px",
                      color: "#344054",
                      lineHeight: "20px",
                      display: "block",
                    }}
                  >
                    First Name
                  </label>

                  <input
                    type="text"
                    id="candidateName"
                    placeholder="Surname example"
                    style={{
                      width: "95%",

                      height: "60%",

                      padding: "10px 14px",

                      borderRadius: "8px",

                      border: "1px solid #D0D5DD",

                      boxShadow: "0px 1px 2px 0px #1018280D",

                      background:
                        "linear-gradient(0deg, #FFFFFF, #FFFFFF), linear-gradient(0deg, #D0D5DD, #D0D5DD)",

                      gap: "8px",

                      display: "block",
                    }}
                    onChange={handleSurnameChange}
                    defaultValue={candidateToEdit.surname}
                  />
                </div>

                {/**Second Surname */}

                <div style={{ backgroundColor: "", width: "50%" }}>
                  <label
                    htmlFor="candidateName"
                    style={{
                      fontFamily: "DM Sans",
                      fontWeight: "500",
                      fontSize: "14px",
                      color: "#344054",
                      lineHeight: "20px",
                      display: "block",
                    }}
                  >
                    Second Name
                  </label>

                  <input
                    type="text"
                    id="candidateName"
                    placeholder="Second Surname example"
                    style={{
                      width: "100%",

                      height: "60%",

                      padding: "10px 14px",

                      borderRadius: "8px",

                      border: "1px solid #D0D5DD",

                      boxShadow: "0px 1px 2px 0px #1018280D",

                      background:
                        "linear-gradient(0deg, #FFFFFF, #FFFFFF), linear-gradient(0deg, #D0D5DD, #D0D5DD)",

                      gap: "8px",

                      display: "block",
                    }}
                    onChange={handleSecondSurnameChange}
                    defaultValue={candidateToEdit.secondSurname}
                  />
                </div>
              </div>

              {/*************************************** 4TH Line IN POP UP *****************************************/}

              <div
                className="d-flex justify-content-between"
                style={{
                  marginTop: "2%",
                  marginLeft: "5%",
                  height: "10%",
                  marginRight: "5%",
                }}
              >
                {/**Country input */}

                <div style={{ width: "50%", alignItems: "center" }}>
                  <label
                    htmlFor="candidateName"
                    style={{
                      fontFamily: "DM Sans",
                      fontWeight: "500",
                      fontSize: "14px",
                      color: "#344054",
                      lineHeight: "20px",
                      display: "block",
                    }}
                  >
                    Country
                  </label>

                  <div class="input-group">
                    <div
                      class="input-group-prepend"
                      style={{ height: "2.5rem" }}
                    >
                      {selectedCountry && (
                        <span class="input-group-text">
                          <ReactCountryFlag
                            countryCode={getCountryCode(selectedCountry)}
                            svg
                            style={{
                              width: "1.5rem",

                              height: "1.5rem",
                            }}
                          />
                        </span>
                      )}
                    </div>

                    <select
                      value={selectedCountry}
                      onChange={handleCountryChange}
                      style={{
                        fontFamily: "DM Sans",
                        fontWeight: "500",
                        fontSize: "14px",
                        color: "#344054",
                        lineHeight: "15px",
                        width: "70%",
                      }}
                    >
                      <option>Select a country</option>

                      {countriesWithoutCodes.map((country, index) => (
                        <option key={index} value={country}>
                          {country}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                {/**Gender*/}

                <div
                  style={{ width: "50%", alignItems: "center", height: "100%" }}
                >
                  <label
                    htmlFor="candidateName"
                    style={{
                      fontFamily: "DM Sans",
                      fontWeight: "500",
                      fontSize: "14px",
                      color: "#344054",
                      lineHeight: "20px",
                      display: "block",
                    }}
                  >
                    Gender
                  </label>

                  <div
                    style={{ display: "flex", width: "100%", height: "2.5rem" }}
                  >
                    <select
                      className="custom-select"
                      id="inputGroupSelect01"
                      style={{ width: "100%" }}
                      onChange={handleGenderChange}
                      value={gender}
                    >
                      <option value="gender">Select a gender</option>

                      <option value="male">Male</option>

                      <option value="female">Female</option>
                    </select>
                  </div>
                </div>
              </div>

              {/**image drag and drop  */}

              <label
                htmlFor="candidateName"
                style={{
                  fontFamily: "DM Sans",
                  fontWeight: "500",
                  fontSize: "14px",
                  color: "#344054",
                  lineHeight: "20px",
                  display: "block",
                  marginLeft: "5%",
                  marginTop: "2%",
                }}
              >
                Picture*
              </label>

              <div
                style={{
                  width: "90%",

                  height: "28%",

                  alignItems: "center",

                  flexShrink: 0,

                  borderRadius: "0.5rem",

                  border: "1px solid var(--Gray-300, #D0D5DD)",

                  background: "var(--Base-White, #FFF)",

                  boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",

                  marginLeft: "5%",

                  marginRight: "5%",

                  overflow: "auto",

                  display: "flex",

                  marginBottom: "2%",
                }}
              >
                <input
                  type="file"
                  id="image"
                  accept="image/*"
                  onChange={handleImageEdit}
                  style={{ display: "none" }}
                />

                <label
                  htmlFor="image"
                  style={{ cursor: "pointer", width: "100%" }}
                >
                  <div
                    style={{ height: "100%", width: "100%", overflow: "auto" }}
                    className="d-flex align-items-center"
                  >
                    {candidateToEdit.image ? (
                      <>
                        <div
                          style={{
                            width: "30%",
                            height: "100%",
                            display: "flex",
                            justifyContent: "flex-start",
                          }}
                        >
                          <img
                            src={
                              imageEdited
                                ? image.dataURL
                                : DOMPurify.sanitize(
                                    `${candidateToEdit.image}?${process.env.REACT_APP_SIGNATURE}`
                                  )
                            }
                            alt={candidateToEdit.name}
                            style={{
                              width: "100%",
                              height: "auto",
                              maxHeight: "100%",
                              marginRight: "90%",
                              padding: "10px",
                            }}
                          />
                        </div>

                        <div>
                          <div>
                            <p>
                              Name:{" "}
                              {candidateToEdit ? candidateToEdit.name : "-"}
                            </p>
                          </div>
                        </div>
                      </>
                    ) : (
                      <div
                        style={{
                          padding: "15px",
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <div className="d-flex justify-content-center">
                          {UploadCloud}
                        </div>

                        <div>
                          <p
                            style={{
                              color: "#00A86A",
                              display: "inline-block",
                              marginRight: "8px",
                              fontFamily: "Inter",
                              fontSize: "1.125rem",
                              fontStyle: "normal",
                              fontWeight: "600",
                              lineHeight: "1.25rem",
                            }}
                          >
                            Click to upload
                          </p>

                          <p
                            style={{
                              display: "inline-block",
                              fontFamily: "DM Sans",
                              fontSize: "1.125rem",
                              fontStyle: "normal",
                              fontWeight: "400",
                              lineHeight: "1.25rem",
                            }}
                          >
                            or drag and drop.
                          </p>

                          <p className="text-center">
                            SVG, PNG, JPG (max. 800x400px)
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                </label>
              </div>

              {/**Button Save & Cancel*/}

              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginRight: "5%",
                }}
              >
                <button
                  type="button"
                  class="btn btn-outline-success"
                  style={{ width: "15%" }}
                  onClick={closePopupEdit}
                >
                  Cancel
                </button>

                <button
                  type="button"
                  class="btn btn-success"
                  style={{ marginLeft: "2%", width: "15%" }}
                  onClick={handleUpdateCandidate}
                >
                  Edit{" "}
                </button>
              </div>
            </div>
          </div>
        )
      )}
    </div>
  );
};

export default Candidates;
