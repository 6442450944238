import axios from "axios";

export const getCandidateData = async (secret, roundId) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/api/Candidates/GetCandidatesByRound?roundID=${roundId}`,
      {
        headers: {
          Authorization: `Bearer ${secret}`,
          Accept: "text/plain",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    // You might want to throw the error here to handle it in the calling function
    throw error;
  }
};

export const getViewCandidates = async (secret, roundId) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/api/Candidates/GetViewCandidates?roundID=${roundId}`,
      {
        headers: {
          Authorization: `Bearer ${secret}`,
          Accept: "text/plain",
        },
      }
    );
    return response.data;
  } catch (error) {
    // console.error('Error fetching data:', error);
    throw error;
  }
};

export const postCandidate = async (secret, candidateData, image) => {
  const formData = new FormData();
  formData.append("ElectionID", candidateData.electionId);
  formData.append("Country", candidateData.country);
  formData.append(
    "FirstName",
    `${candidateData.surname} ${candidateData.secondSurname}`
  );
  formData.append("LastName", candidateData.name);
  formData.append("Title", candidateData.title);
  formData.append("Gender", candidateData.gender);
  formData.append("image", image);
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/api/Candidates`,
      formData, // Pass candidateData as the second argument
      {
        headers: {
          Authorization: `Bearer ${secret}`,
          Accept: "text/plain",
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error fetching data:", error);
    return error;
  }
};

export const getCandidateByElection = async (secret, ElectionID) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/api/Candidates/GetCandidatesByElection?electionID=${ElectionID}`,
      {
        headers: {
          Authorization: `Bearer ${secret}`,
          Accept: "text/plain",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    // You might want to throw the error here to handle it in the calling function
    throw error;
  }
};
export const deleteCandidateByID = async (secret, candidateID) => {
  try {
    const response = await axios.delete(
      `${process.env.REACT_APP_API_BASE_URL}/api/Candidates/${candidateID}`,
      {
        headers: {
          Authorization: `Bearer ${secret}`,
          Accept: "text/plain",
        },
      }
    );
    return response.status;
  } catch (error) {
    console.error("Error deleting candidate:", error);
    throw error;
  }
};
export const updateCandidate = async (secret, candidateData) => {
  const formData = new FormData();
  formData.append("ID", candidateData.id);
  formData.append("ElectionID", candidateData.electionId);
  formData.append("Country", candidateData.country);
  formData.append(
    "FirstName",
    `${candidateData.surname} ${candidateData.secondSurname}`
  );
  formData.append("LastName", candidateData.name);
  formData.append("Title", candidateData.title);
  formData.append("Gender", candidateData.gender);
  formData.append("image", candidateData.image);
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_API_BASE_URL}/api/Candidates/UpdateCandidate`,
      formData, // Pass candidateData as the second argument
      {
        headers: {
          Authorization: `Bearer ${secret}`,
          Accept: "text/plain",
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error fetching data:", error);
    return error;
    //throw error;
  }
};
export const getWinnerByElection = async (secret, electionId) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/api/Candidates/GetCandidateWinner?IDElection=${electionId}`,
      {
        headers: {
          Authorization: `Bearer ${secret}`,
          Accept: "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("error fetching winner:", error);
    throw error;
  }
};
