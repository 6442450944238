import Navbar from "../../components/AdminBanner";
import { Breadcrumb } from "antd";
import "./ResultsRound.css";
import { GreenClock, whiteClock } from "../../assets/Icons";
import { useEffect, useState } from "react";
import { getViewCandidates } from "../../services/Candidate";
import { fetchToken } from "../../services/Token";
import { getRoundsByElection } from "../../services/Rounds";
import { getVoteStats } from "../../services/Stats";
import { getAllElections } from "../../services/Elections";
import { getBusinessLog } from "../../services/BusinessLog";
import { useNavigate } from "react-router-dom";

export const ResultsRound = () => {
  const [isExpanded, setExpanded] = useState(false);
  const [candidatesPerRound, setCandidatesPerRound] = useState(null);
  const [localData, setLocalData] = useState(null);
  const [token, setToken] = useState(null);
  const [roundsByElection, setRoundsByElection] = useState([]);
  const [stats, setStats] = useState(null);
  const [electionId, setElectionId] = useState(null);
  const [isPet, setIsPet] = useState(null);
  const [isSg, setIsSg] = useState(null);
  const [isChairman, setIsChairman] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    let idTokenClaims = JSON.parse(localStorage.getItem("idTokenClaims"));
    setLocalData(idTokenClaims);
  }, []);

  useEffect(() => {
    if (localData !== null) {
      fetchToken(localData).then((result) => {
        setToken(result);
      });
      setIsPet(localData?.roles?.includes("PET"));
      setIsChairman(localData?.roles?.includes("Chairman"));
      setIsSg(localData?.roles?.includes("SG"));
    }
  }, [localData]);
  useEffect(() => {
    if (isPet == false && isSg == false && isChairman == false) {
      navigate("/AccessDenied");
    }
  }, [isPet, isSg, isChairman]);
  useEffect(() => {
    if (token !== null) {
      getAllElections(token).then((result) => {
        // Filter elections with status Closed, Adjourned, or Started
        const filteredElections = result?.filter((election) =>
          ["Closed", "Adjourned", "Started"].includes(election.status)
        );
        if (filteredElections) {
          filteredElections.sort((a, b) => {
            return new Date(a.startDate) - new Date(b.startDate);
          });
        }
        // Sort filtered elections based on their order

        if (filteredElections?.length > 0) {
          // Set electionId to the id of the last element
          setElectionId(filteredElections[filteredElections.length - 1].id);
        } else {
          // If no matching elections found, set electionId to null or handle accordingly
          setElectionId(null);
        }
      });
    }
  }, [token]);

  const fetchRounds = async (token, electionId) => {
    if (electionId === null) return [];

    const rounds = await getRoundsByElection(token, electionId);
    return rounds?.filter((round) => round.status === "Closed") || [];
  };

  const validateRound = async (token, round) => {
    try {
      const results = await getBusinessLog(token, round.id);

      // Filter the results based on the "title" property
      const filteredResults = results.filter(
        (result) => result.title === "Validation"
      );

      // Check if there are exactly 3 items with title "Validation"
      if (filteredResults.length === 3) {
        return round; // Return the round if validation passes
      } else {
        console.error(
          `Round ${round.id} does not have 3 items with title "Validation"`
        );
        return null; // Return null to indicate validation failure
      }
    } catch (error) {
      console.error("Error fetching business log:", error);
      return null; // Return null to indicate validation failure
    }
  };

  const validateRounds = async (token, closedRounds) => {
    const validationPromises = closedRounds.map((round) =>
      validateRound(token, round)
    );
    const validatedRounds = await Promise.all(validationPromises);
    return validatedRounds.filter((round) => round !== null);
  };

  useEffect(() => {
    const fetchAndValidateRounds = async () => {
      const closedRounds = await fetchRounds(token, electionId);
      const roundsPassedValidation = await validateRounds(token, closedRounds);
      // Filter rounds where stageProcess is "5"
      const filteredRounds = roundsPassedValidation.filter(
        (round) => round.stageProcess === "5"
      );
      setRoundsByElection(filteredRounds);
    };

    if (electionId !== null) {
      fetchAndValidateRounds();
      fetchAndValidateRounds().then(() => {
        // After data is fetched, set loading to false
        setLoading(false);
      });
    }
  }, [electionId]);

  const formatPercentage = (percentage) => {
    const formattedPercentage = parseFloat(percentage).toFixed(2);
    return formattedPercentage;
  };
  useEffect(() => {
    const fetchStatsAndCandidatesForRound = async (round) => {
      try {
        if (token !== null && electionId !== null && round !== null) {
          const statsResult = await getVoteStats(token, electionId, round.id);
          const candidatesResult = await getViewCandidates(token, round.id);
          return { roundId: round.id, statsResult, candidatesResult };
        }
      } catch (error) {
        return { roundId: round.id, statsResult: null, candidatesResult: null };
      }
    };

    const fetchAllData = async () => {
      try {
        if (roundsByElection && roundsByElection.length > 0) {
          const fetchDataPromises = roundsByElection.map((round) =>
            fetchStatsAndCandidatesForRound(round)
          );
          const dataResults = await Promise.all(fetchDataPromises);
          const statsResults = dataResults.map((result) => result.statsResult);
          const candidatesResults = dataResults.map(
            (result) => result.candidatesResult
          );

          setStats(statsResults);
          setCandidatesPerRound(candidatesResults);
        }
      } catch (error) {}
    };

    fetchAllData();
  }, [roundsByElection]);

  function formatDate(dateString) {
    if (!dateString) {
      return "MM/DD/YYYY"; // Return "DD/MM/YYYY" if dateString is null or empty
    } else {
      const options = {
        day: "numeric",
        month: "numeric",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: false,
      };
      const formattedDate = new Date(dateString).toLocaleString(
        "en-GB",
        options
      );
      return formattedDate; // Transform to dd/mm/yyyy format if dateString is not null
    }
  }

  return (
    <>
      <div
        className="container-fluid"
        style={{ backgroundColor: "#DAE6B6", minHeight: "100vh" }}
      >
        <div
          className="row "
          style={{ height: "10%", backgroundColor: "#DAE6B6" }}
        >
          <div className="col">
            <Navbar />
          </div>
        </div>

        <div
          className="row"
          style={{ minHeight: "90%", backgroundColor: "#DAE6B6" }}
        >
          {/**BREADCRUMB COMPONENT*/}
          <div
            className="d-flex align-items-center"
            style={{ paddingLeft: "1rem", height: "5%" }}
          >
            <Breadcrumb separator=">" style={{ color: " rgba(2, 148, 85, 1)" }}>
              <Breadcrumb.Item>
                <a
                  style={{
                    color: "rgba(2, 148, 85, 1)",
                    cursor: "default",
                    fontFamily: " DM Sans",
                    fontSize: "16px",
                    fontWeight: "400",
                  }}
                >
                  Résultats des tours/Round results
                </a>
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <h4
            style={{
              fontFamily: "DM Sans",
              fontSize: "22px",
              fontWeight: "700",
              lineHeight: "31px",
              letterSpacing: "0em",
              textAlign: "left",
            }}
          >
            Résulats des tours/Round Results
          </h4>
          <div class="accordion" id="accordionExample">
            {loading && (
              // Loading state
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  height: "80%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div>Loading...</div>{" "}
                {/* You can replace this with a spinner */}
              </div>
            )}
            {!loading &&
              roundsByElection &&
              roundsByElection.length > 0 &&
              roundsByElection.map((round, index) => (
                <div class="accordion-item" key={index}>
                  <h2 class="accordion-header" id={`heading${index}`}>
                    <button
                      class="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#collapse${index}`}
                      aria-expanded={isExpanded}
                      aria-controls={`collapse${index}`}
                      onFocus={() => setExpanded(true)}
                      onBlur={() => setExpanded(false)}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <span className="me-2">{`Round ${index + 1}`}</span>
                      <span style={{ marginLeft: "auto" }}>
                        <span style={{ marginRight: "10px" }}>
                          {isExpanded ? whiteClock : GreenClock}
                        </span>
                        <span>{`Start Time: ${formatDate(
                          round.startTime
                        )} - End Time: ${formatDate(round.endTime)}`}</span>
                      </span>
                    </button>
                  </h2>
                  <div
                    id={`collapse${index}`}
                    class="accordion-collapse collapse"
                    aria-labelledby={`heading${index}`}
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      <div style={{ height: "10%" }}>
                        {stats && stats[index] && (
                          <div
                            style={{
                              display: "flex",
                              marginBottom: "1rem",
                              width: "100%",
                            }}
                          >
                            <div className="whiteStats">
                              <span className="greenNumber">
                                {stats
                                  ? formatPercentage(stats[index].voidBallots) +
                                    "%"
                                  : "0.00%"}
                              </span>
                              <br />
                              <span className="stat">
                                Bulletin null / Void ballot
                              </span>
                            </div>
                            <div className="whiteStats">
                              <span className="greenNumber">
                                {stats
                                  ? formatPercentage(
                                      stats[index].pourcentageVotingPower
                                    ) + "%"
                                  : "0.00%"}
                              </span>
                              <br />
                              <span className="stat">
                                Vote total/Total vote{" "}
                              </span>
                            </div>
                            <div className="whiteStats">
                              <span className="greenNumber">
                                {stats
                                  ? formatPercentage(
                                      stats[index].pourcentageRegional
                                    ) + "%"
                                  : "0.00%"}
                              </span>
                              <br />
                              <span className="stat">
                                Vote régional/Regional vote{" "}
                              </span>
                            </div>
                            <div className="whiteStats">
                              <span className="greenNumber">
                                {stats
                                  ? formatPercentage(
                                      stats[index].pourcentageAbstention
                                    ) + "%"
                                  : "0.00%"}
                              </span>
                              <br />
                              <span className="stat">
                                Abstention/Abstention
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                      <div style={{ height: "90%" }}>
                        <table
                          className="w-100"
                          style={{ marginLeft: "0.5rem" }}
                        >
                          <thead>
                            <tr className="HeaderTitlesPublication">
                              <th style={{ padding: "1rem" }}>
                                N/S
                                <br />
                                S/N
                              </th>
                              <th>
                                Candidat
                                <br />
                                Candidate
                              </th>
                              <th>
                                Pays
                                <br />
                                Country
                              </th>
                              <th>
                                Total des pouvoirs de vote
                                <br />
                                Total of voting power
                              </th>
                              <th>
                                Total des pouvoirs de vote régionaux
                                <br />
                                Total of regional voting power
                              </th>
                              <th>
                                Statut
                                <br />
                                Status
                              </th>
                            </tr>
                          </thead>
                          <tbody
                            style={{ padding: "1rem" }}
                            className="BodyTextPublication"
                          >
                            {candidatesPerRound && candidatesPerRound[index] ? (
                              candidatesPerRound[index].map(
                                (item, innerIndex) => (
                                  <tr
                                    key={innerIndex}
                                    style={{
                                      backgroundColor:
                                        innerIndex % 2 === 0
                                          ? "#F6F6F6"
                                          : "rgba(255, 255, 255, 0.46)",
                                      color: "var(--Gray-Gray-700, #2D3748)",
                                    }}
                                    className="BodyTablePublication"
                                  >
                                    <td style={{ padding: "1rem" }}>
                                      {item.sn}
                                    </td>
                                    <td>
                                      {item.firstName} {item.lastName}
                                    </td>
                                    <td>{item.country}</td>
                                    <td>{formatPercentage(item.total_VP)} %</td>
                                    <td>
                                      {formatPercentage(item.total_RVP)} %
                                    </td>
                                    <td>
                                      {item.status === "Winner"
                                        ? "Double majorité!/Double majority"
                                        : item.status === "Eliminated"
                                        ? "Eliminé/Eliminated"
                                        : item.status === "Qualified"
                                        ? "Qualifié/Qualified"
                                        : ""}
                                    </td>
                                  </tr>
                                )
                              )
                            ) : (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  className="spinner-border text-success"
                                  role="status"
                                  style={{
                                    width: "50px",
                                    height: "50px",
                                  }}
                                ></div>
                              </div>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            {!loading &&
              Array.isArray(roundsByElection) &&
              roundsByElection.length == 0 && (
                <p
                  style={{
                    position: "absolute",
                    color: "black",
                    fontWeight: 600,
                    fontSize: "30px",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                >
                  No rounds available.
                </p>
              )}
          </div>
        </div>
      </div>
    </>
  );
};
