import React from "react";
import "./Voting.css";

const CircleComponent = ({ number, pourcentage }) => {
  return (
    <div className="Group1000003149_202_58">
      <div className="Group1000003136_202_59">
        <svg
          style={{
            borderRadius: "50%",
            transform: "translate(2px, 0px) rotate(0deg) scale(1, 1)",
            transformOrigin: "1px 1px",
            position: "absolute",
            top: 0,
            left: 0,
          }}
          width="90"
          height="90"
          viewBox="0 0 145 145"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M145 72.5C145 112.541 112.541 145 72.5 145C32.4594 145 0 112.541 0 72.5C0 32.4594 32.4594 0 72.5 0C112.541 0 145 32.4594 145 72.5Z"
            fill="rgba(199.75000709295273, 199.75000709295273, 199.75000709295273, 0.28999999165534973)"
          />
        </svg>
        <svg
          style={{
            borderRadius: "50%",
            transform:
              "translate(7.590909004211426px, 6.590909004211426px) rotate(0deg) scale(1, 1)",
            transformOrigin: "1px 1px",
            position: "absolute",
            top: 0,
            left: 0,
          }}
          width="78"
          height="78"
          viewBox="0 0 133.13636779785156 132.47727966308594"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M133.136 66.2386C133.136 102.821 103.333 132.477 66.5682 132.477C29.8036 132.477 0 102.821 0 66.2386C0 29.656 29.8036 0 66.5682 0C103.333 0 133.136 29.656 133.136 66.2386Z"
            fill="rgba(2.000000118277967, 148.000006377697, 85.0000025331974, 1)"
          />
        </svg>
        <svg
          style={{
            borderRadius: "50%",
            transform:
              "translate(8.7303px, 6.39394px) rotate(0deg) scale(1, 1)",
            transformOrigin: "1px 1px",
            position: "absolute",
            top: 0,
            left: 0,
            filter:
              "drop-shadow(0px 4px 10px rgba(146.0765951871872, 146.0765951871872, 163.82421165704727, 0.4159999907016754))",
          }}
          width="75"
          height="75"
          viewBox="0 0 129.18182373046875 129.18182373046875"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M129.182 64.5909C129.182 100.263 100.263 129.182 64.5909 129.182C28.9183 129.182 0 100.263 0 64.5909C0 28.9183 28.9183 0 64.5909 0C100.263 0 129.182 28.9183 129.182 64.5909Z"
            fill="rgba(255, 255, 255, 1)"
          />
        </svg>
        <div className="GrowthInfo_202_63">
          <p className="TEXT_202_64 m-0">{number}</p>
          <p className="TEXT_202_65 m-0">{pourcentage}</p>
        </div>
      </div>
    </div>
  );
};

export default CircleComponent;
