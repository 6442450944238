import { useEffect, useState } from "react";
import Navbar from "../components/AdminBanner";
import { redRestrict } from "../assets/Icons";
import { SignOutButton } from "../components/SignOutButton";

export const AccessDenied = () => {
  const [isGovernor, setIsGovernor] = useState(false);
  const [userData, setUserData] = useState("");

  useEffect(() => {
    const user = localStorage.getItem("idTokenClaims");
    const parsedUserData = JSON.parse(user);
    setUserData(parsedUserData);
  }, []);

  useEffect(() => {
    if (userData !== null) {
      setIsGovernor(userData?.roles?.includes("Governors"));
    }
  }, [userData]);

  return (
    <div className="container-fluid" style={{ backgroundColor: "#FAFBFC" }}>
      <div className="row">
        {!isGovernor && (
          <div className="col">
            <Navbar />
          </div>
        )}

        <div
          className="row"
          style={{
            height: !isGovernor ? "90vh" : "100vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          {redRestrict}
          <h6
            style={{
              fontFamily: "DM Sans",
              fontSize: "22px",
              fontWeight: "700",
              lineHeight: "31px",
              letterSpacing: "0em",
              marginBottom: "1rem",
              marginTop: "1rem",
            }}
          >
            Accès refusé/ Access denied
          </h6>
          <SignOutButton />
        </div>
      </div>
    </div>
  );
};
