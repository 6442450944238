import axios from "axios";

export const publishResults = async (resultsArray, secret) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/api/ElectionResults`,
      resultsArray,
      {
        headers: {
          Authorization: `Bearer ${secret}`,
          Accept: "text/plain",
          "Content-Type": "application/json",
        },
      }
    );

    return response;
  } catch (error) {
    console.error("Error posting data:", error);
    return error;
  }
};

export const getElectionResultsByCandidate = async (
  secret,
  idCandidate,
  idElection
) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/api/ElectionResults/GetElectionResultsbyCandidate?idCandidate=${idCandidate}&idElection=${idElection}`,
      {
        headers: {
          Authorization: `Bearer ${secret}`,
          Accept: "text/plain",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};
