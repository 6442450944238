import { useNavigate } from "react-router-dom";
import { greenTick } from "../../assets/Icons";
import Navbar from "../../components/AdminBanner";
import "./AdjournScreen.css";
import { useEffect, useState } from "react";

export const AdjournScreen = () => {
  const [isPet, setIsPet] = useState(null);
  const [isSg, setIsSg] = useState(null);
  const [isChairman, setIsChairman] = useState(null);
  const [localData, setLocalData] = useState(null);
  useEffect(() => {
    let idTokenClaims = JSON.parse(localStorage.getItem("idTokenClaims"));
    setLocalData(idTokenClaims);
  }, []);

  useEffect(() => {
    if (localData !== null) {
      setIsPet(localData?.roles?.includes("PET"));
      setIsChairman(localData?.roles?.includes("Chairman"));
      setIsSg(localData?.roles?.includes("SG"));
    }
  }, [localData]);

  useEffect(() => {
    if (isPet && isSg && isChairman) {
      navigate("/AccessDenied");
    }
  }, [isPet, isSg, isChairman]);

  const navigate = useNavigate();
  return (
    <div
      className="adjscreen"
      style={{ height: "100vh", overflow: "auto", backgroundColor: "#dae6b6" }}
    >
      <Navbar />
      <div style={{ backgroundColor: "#dae6b6" }}>
        <div className="popupAdjourn">
          <div className="contentAdjourn">
            <div
              style={{ width: "0.875rem", height: "10rem", flexShrink: "0" }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="160"
                viewBox="0 0 14 160"
                fill="none"
              >
                <path d="M0 0H14V160H0V0Z" fill="#029455" />
              </svg>
            </div>
            <div style={{ marginTop: "1rem", marginLeft: "1.5rem" }}>
              <div className="Header">
                <div className="TickIcon">{greenTick}</div>
              </div>
              <div className="Text">
                <div>
                  <div>
                    <h6>Élections ajournées/ Elections adjourned</h6>
                  </div>
                  <div>
                    <p className="informingText">
                      Les élections ont été ajournées avec succès/The election
                      has been successfully adjourned
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
