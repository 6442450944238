import React, { Children, useEffect, useState, useRef } from "react";
import { Pie, Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Switch } from "antd";
import "chartjs-plugin-datalabels";
import { CloseCircleOutlined } from "@ant-design/icons";
import {
  adjournElection,
  getCurrentElectionID,
} from "../../../services/Elections";
import createWebSocketConnection from "../../../services/webSocketService";
import {
  PostRallCall,
  UpdateStatusRallCallIdVoter,
  endRallCall,
  getCurrentRoundRallCall,
  getRallCallByElection,
} from "../../../services/RallCall";
import { useNavigate } from "react-router-dom";
import {
  endCurrentRound,
  getLastRound,
  updateStageRound,
} from "../../../services/Rounds";
import { GreyClose, Vector, greenTick } from "../../../assets/Icons";
import { getVotersByRound } from "../../../services/Voters";
import { notification } from "antd";
import CheckConnection from "../../../services/Detector.js";
import { useConnection } from "../../../Context/ConnectionContext";
import createWebSocketConnectionVotes from "../../../services/WebSocketVotesService.js";

ChartJS.register(ArcElement, Tooltip, Legend);

const RollCallComponent = ({
  electionId,
  voters,
  connectedUsers,
  state,
  setState,
  roundId,
  switchColors,
  token,
  setElectionId,
  setConnectedUsers,
  localData,
  setUpdatedCount,
  setSwitchColors,
  roundData,
  setVotersPerRound,
  setRoundId,
  setRoundNumber,
  roundNumber,
  setPopUpRoundCreated,
  popupRoundCreated,
  hasAccess,
  myStateRef,
}) => {
  const navigate = useNavigate();
  const [roundRallCallData, setRoundRallCallData] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [switchCount, setSwitchCount] = useState(0);
  const [switchCountVotingPower, setSwitchCountVotingPower] = useState(0);
  const [startedByButton, setStartedByButton] = useState(false);
  const { isOnline } = useConnection();
  const [delayedRender, setDelayedRender] = useState(false);
  const [disableStartRallCallButton, setDisableStartRallCallButton] =
    useState(false);
  const prevIsOnline = useRef(isOnline);

  const fetchRoundId = async () => {
    try {
      if (token !== null && electionId !== null) {
        const result = await getLastRound(token, electionId);
        setRoundId(result?.id);
        setRoundNumber(result?.number);
      }
    } catch (error) {
      console.error("Error fetching roundId:", error);
    }
  };

  const handlePopupRoundCreatedClose = () => {
    setPopUpRoundCreated(false);
  };
  const handleSwitchButtonChangeRealTime = async (isChecked, voter, index) => {
    getCurrentRoundRallCall(token, roundId).then((result) => {
      const uniqueResults = Array.from(
        new Set(result?.map(JSON.stringify))
      )?.map(JSON.parse);
      setRoundRallCallData(uniqueResults);
    });

    if (isChecked) {
      setSwitchCount((prevCount) => prevCount + 1);
      const countToAdd = voter?.country?.votingPower[0]?.power || 0;
      setUpdatedCount((prevCount) => prevCount + countToAdd);
      setSwitchCountVotingPower((prevCount) => prevCount + countToAdd);
    } else {
      const countToAdd = voter?.country?.votingPower[0]?.power || 0;
      setUpdatedCount((prevCount) => prevCount + countToAdd);
      setSwitchCountVotingPower((prevCount) =>
        prevCount > 0 ? prevCount - countToAdd : 0
      );
      setSwitchCount((prevCount) => (prevCount > 0 ? prevCount - 1 : 0));
    }
  };

  const handleSwitchButtonChange = async (isChecked, voter, index) => {
    if (!navigator.onLine) {
      notification.error({
        message: "No Internet Connection",
        description: "Please check your internet connection and try again.",
      });
      return; // Exit function if no internet connection
    }
    const updatedRoundRallCallData = roundRallCallData.map((item) => {
      if (item.votersID === voter.id) {
        const status = isChecked ? "Participant" : "Not participant";
        return { ...item, status };
      }
      return item;
    });
    const itemIndex = roundRallCallData.findIndex(
      (item) => item.votersID === voter.id
    );
    const indexToIntroduce = index;
    const statusTointroduce =
      roundRallCallData[itemIndex].status == "Participant"
        ? "Not participant"
        : "Participant";
    // Set the updated roundRallCallData
    setRoundRallCallData(updatedRoundRallCallData);
    if (state == 1) {
      const response = await UpdateStatusRallCallIdVoter(
        token,
        voter.id,
        isChecked ? "Participant" : "Not participant",
        roundId,
        itemIndex
      );
    }

    if (isChecked) {
      setSwitchCount((prevCount) => prevCount + 1);
      const countToAdd = voter.country.votingPower[0]?.power || 0;
      setUpdatedCount((prevCount) => prevCount + countToAdd);
      setSwitchCountVotingPower((prevCount) => prevCount + countToAdd);
    } else {
      const countToAdd = voter.country.votingPower[0]?.power || 0;
      setUpdatedCount((prevCount) => prevCount + countToAdd);
      setSwitchCountVotingPower((prevCount) =>
        prevCount > 0 ? prevCount - countToAdd : 0
      );
      setSwitchCount((prevCount) => (prevCount > 0 ? prevCount - 1 : 0));
    }
  };
  const handleSwitchButtonParticipant = async (isChecked, voter) => {
    // Find the corresponding item in roundRallCallData array
    const itemIndex = roundRallCallData.findIndex(
      (item) => item.votersID === voter.id
    );

    if (itemIndex !== -1) {
      const updatedRoundRallCallData = [...roundRallCallData];
      // Toggle the status from "Participant" to "Non Participant" or vice versa
      updatedRoundRallCallData[itemIndex].status = isChecked
        ? "Participant"
        : "Not participant";
      setRoundRallCallData(updatedRoundRallCallData);
      const response = await UpdateStatusRallCallIdVoter(
        token,
        voter.id,
        isChecked ? "Participant" : "Not participant",
        roundId,
        itemIndex
      );
      if (isChecked) {
        setSwitchCount((prevCount) => prevCount + 1);
        const countToAdd = voter?.country?.votingPower[0]?.power || 0;
        setUpdatedCount((prevCount) => prevCount + countToAdd);
        setSwitchCountVotingPower((prevCount) => prevCount + countToAdd);
      } else {
        const countToAdd = voter?.country?.votingPower[0]?.power || 0;
        setUpdatedCount((prevCount) => prevCount + countToAdd);
        setSwitchCountVotingPower((prevCount) =>
          prevCount > 0 ? prevCount - countToAdd : 0
        );
        setSwitchCount((prevCount) => (prevCount > 0 ? prevCount - 1 : 0));
      }
    }
  };
  const handleSwitchButtonParticipantRealTime = async (
    isChecked,
    voter,
    itemindex
  ) => {
    // Find the corresponding item in roundRallCallData array

    if (itemindex != -1) {
      const updatedRoundRallCallData = [...roundRallCallData];
      // Toggle the status from "Participant" to "Non Participant" or vice versa
      updatedRoundRallCallData[itemindex].status = isChecked
        ? "Participant"
        : "Not participant";
      setRoundRallCallData(updatedRoundRallCallData);
    }

    if (isChecked) {
      setSwitchCount((prevCount) => prevCount + 1);
      const countToAdd = voter?.country?.votingPower[0]?.power || 0;
      setUpdatedCount((prevCount) => prevCount + countToAdd);
      setSwitchCountVotingPower((prevCount) => prevCount + countToAdd);
    } else {
      const countToAdd = voter?.country?.votingPower[0]?.power || 0;
      setUpdatedCount((prevCount) => prevCount + countToAdd);
      setSwitchCountVotingPower((prevCount) =>
        prevCount > 0 ? prevCount - countToAdd : 0
      );
      setSwitchCount((prevCount) => (prevCount > 0 ? prevCount - 1 : 0));
    }
  };
  useEffect(() => {
    if (state == 1 && delayedRender) {
      let participantCount = 0;
      let participantVotingPower = 0;
      const uniqueVoterIDs = new Set();

      if (roundData?.number < 2) {
        getCurrentRoundRallCall(token, roundId).then((result) => {
          const uniqueResults = Array.from(
            new Set(result?.map(JSON.stringify))
          )?.map(JSON.parse);
          uniqueResults.forEach((item) => {
            const matchingVoter = voters.find(
              (voter) => voter.id === item.votersID
            );
            if (matchingVoter) {
              if (item.status === "Participant") {
                const votingPower =
                  matchingVoter.country.votingPower[0]?.power || 0;
                participantCount++;
                participantVotingPower += votingPower;
              }
            }
          });
          setSwitchCount((prevCount) => prevCount + participantCount);
          setSwitchCountVotingPower(
            (prevPower) => prevPower + participantVotingPower
          );
          setRoundRallCallData(uniqueResults);
        });
      } else {
        getCurrentRoundRallCall(token, roundId).then((result) => {
          // Filtrer roundRallCallData pour obtenir une version sans doublons basée sur l'identifiant du votant
          const uniqueRoundRallCallData = result.filter((item) => {
            // Vérifier si l'identifiant du votant a déjà été rencontré
            if (!uniqueVoterIDs.has(item.votersID)) {
              // Ajouter l'identifiant du votant au Set pour marquer qu'il a été rencontré
              uniqueVoterIDs.add(item.votersID);
              return true; // Garder l'élément s'il est unique
            }
            return false; // Exclure l'élément s'il n'est pas unique
          });
          uniqueRoundRallCallData.forEach((item) => {
            const matchingVoter = voters.find(
              (voter) => voter.id === item.votersID
            );
            if (matchingVoter) {
              if (item.status === "Participant") {
                const votingPower =
                  matchingVoter.country.votingPower[0]?.power || 0;
                participantCount++;
                participantVotingPower += votingPower;
              }
            }
          });
          setSwitchCount((prevCount) => prevCount + participantCount);
          setSwitchCountVotingPower(
            (prevPower) => prevPower + participantVotingPower
          );
        });
      }
    }
  }, [state, delayedRender]);

  const handleClickStartRollCall = async () => {
    setDisableStartRallCallButton(true);
    if (!navigator.onLine) {
      notification.error({
        message: "No Internet Connection",
        description: "Please check your internet connection and try again.",
      });
      setDisableStartRallCallButton(false);
      return; // Exit function if no internet connection
    }
    if (state == 0) {
      const responseRollcall = await PostRallCall(token, roundRallCallData);
      if (responseRollcall.status == 200) {
        setDisableStartRallCallButton(false);
      } else {
        setDisableStartRallCallButton(false);
      }
      setShowPopup(false);
      setStartedByButton(true);
      const response = await updateStageRound(token, roundId, "1");
      if (response.status == 204) {
        notification.success({
          description: `Rollcall started successfully!`,
        });
        setDisableStartRallCallButton(false);
      } else {
        notification.error({
          description: "Error",
        });
        setDisableStartRallCallButton(false);
      }
    }
  };

  const showPopUp = () => {
    setShowPopup(true);
  };
  const handlePopupClose = () => {
    setShowPopup(false);
  };
  const AdjournmentRedirect = async () => {
    if (!navigator.onLine) {
      notification.error({
        message: "No Internet Connection",
        description: "Please check your internet connection and try again.",
      });
      return; // Exit function if no internet connection
    }
    try {
      const response = await adjournElection(token, electionId);
      if (response.status === 204) {
        await endCurrentRound(token, roundId);
        navigate("/ElectionContent/Adjournment");
      } else {
        notification.error({
          description: "Error",
        });
      }
    } catch (error) {
      console.error("Error adjourning election:", error);
    }
  };

  //End roll call button function
  const handleClickEndRollCall = async () => {
    setDisableStartRallCallButton(true);
    if (!navigator.onLine) {
      notification.error({
        message: "No Internet Connection",
        description: "Please check your internet connection and try again.",
      });
      setDisableStartRallCallButton(false);
      return; // Exit function if no internet connection
    }
    if (state == 1) {
      setDisableStartRallCallButton(true);

      const responseUpdateStageRound = await updateStageRound(
        token,
        roundId,
        "2"
      );
      if (responseUpdateStageRound.status == 204) {
        setState(2);
        endRallCall(token, roundId);
      }
      const votersByRound = await getVotersByRound(token, roundId);
      setVotersPerRound(votersByRound.data);
      if (votersByRound.status == 200) {
        setDisableStartRallCallButton(false);
        notification.success({
          description: `Rollcall ended successfully!`,
        });
      } else {
        setDisableStartRallCallButton(false);
      }
    }
  };

  useEffect(() => {
    fetchRoundId();

    const timer = setTimeout(() => {
      setDelayedRender(true);
    }, 1200);
    return () => {
      clearTimeout(timer);
    };
  }, []);
  useEffect(() => {
    if (localData !== null) {
    }
  }, [localData]);

  useEffect(() => {
    if (isOnline && prevIsOnline.current != isOnline) {
      const socket = createWebSocketConnection();
      socket.onopen = () => {
        // Send the initial message to start receiving data
        const initialMessage =
          JSON.stringify({ protocol: "json", version: 1 }) +
          String.fromCharCode(0x1e);
        socket.send(initialMessage);
      };
      // Handle connection errors
      socket.onerror = (error) => {
        console.error("WebSocket error occurred:", error);
      };
      prevIsOnline.current = isOnline;
    } else if (prevIsOnline.current !== isOnline) {
      createWebSocketConnection();
      prevIsOnline.current = isOnline;
    }
  }, [isOnline]);
  useEffect(() => {
    if (token !== null && delayedRender) {
      getCurrentElectionID(token).then((result) => setElectionId(result?.id));
      // Create WebSocket connection
      const socketAdmin = createWebSocketConnectionVotes();
      socketAdmin.onopen = () => {
        // Send the initial message to start receiving data
        const initialMessage =
          JSON.stringify({ protocol: "json", version: 1 }) +
          String.fromCharCode(0x1e);
        socketAdmin.send(initialMessage);
      };
      // Handle connection errors
      socketAdmin.onerror = (error) => {
        console.error("WebSocket error occurred:", error);
      };
      socketAdmin.onmessage = (event) => {
        const rawData = event.data;
        const messages = rawData
          .split(String.fromCharCode(0x1e))
          .filter(Boolean);
        if (messages.length > 1) {
          const parsedData = JSON.parse(messages[1]);
          if (parsedData.target === "SendStatusRallCall") {
            // setConnectedUsers(parsedData.arguments[0]);
            const itemSocket = parsedData.arguments[0];
            const index = parsedData.arguments[1];
            const voterSearched = voters.find(
              (voter) => voter.id === itemSocket.votersID
            );
            let status = itemSocket.status === "Participant" ? true : false;
            if (roundNumber < 2 && myStateRef.current == false) {
              const BoolValue =
                parsedData.arguments[0].status == "Participant" ? true : false;
              const index = parsedData.arguments[1];
              handleSwitchButtonChangeRealTime(BoolValue, voterSearched, index);
            } else if (roundNumber > 1 && myStateRef.current == false) {
              handleSwitchButtonParticipantRealTime(
                status,
                voterSearched,
                parsedData.arguments[1]
              );
            }
          }
        } else if (messages.length == 1) {
          const data = messages[0];
          const parsedData = JSON.parse(data);
          const typevalue = parsedData.type;
          if (parsedData.target === "SendStatusRallCall") {
            const itemSocket = parsedData.arguments[0];

            const voterSearched = voters?.find(
              (voter) => voter.id === itemSocket.votersID
            );
            let status = itemSocket.status === "Participant" ? true : false;
            const index = parsedData.arguments[1];
            if (roundNumber < 2 && myStateRef.current == false) {
              const BoolValue =
                parsedData.arguments[0].status == "Participant" ? true : false;
              handleSwitchButtonChangeRealTime(
                BoolValue,
                voterSearched,
                parsedData.arguments[1]
              );
            } else if (roundData?.number > 1 && myStateRef.current == false) {
              handleSwitchButtonParticipantRealTime(
                status,
                voterSearched,
                parsedData.arguments[1]
              );
            }
          }
        }
      };

      const socket = createWebSocketConnection();
      socket.onopen = () => {
        // Send the initial message to start receiving data
        const initialMessage =
          JSON.stringify({ protocol: "json", version: 1 }) +
          String.fromCharCode(0x1e);
        socket.send(initialMessage);
      };
      socket.onmessage = (event) => {
        const rawData = event.data;
        const messages = rawData
          .split(String.fromCharCode(0x1e))
          .filter(Boolean);
        if (messages.length > 1) {
          const parsedData = JSON.parse(messages[1]);
          if (parsedData.target === "SendListConnectedUser") {
            setConnectedUsers(parsedData.arguments[0]);
          }
        } else if (messages.length == 1) {
          const data = messages[0];
          const parsedData = JSON.parse(data);
          const typevalue = parsedData.type;
          if (parsedData.target === "SendListConnectedUser") {
            if (typevalue === 1) {
              setConnectedUsers(parsedData.arguments[0]);
            }
          }
        }
      };
      return () => {};
    }
  }, [token, delayedRender]);

  let pieChartData = null; // Initialize the variable
  let doughnutChartData = null;

  useEffect(() => {
    if (roundId !== null && token !== null && roundData !== null) {
      if (roundNumber == 1 && roundData?.stageProcess == 1) {
        getCurrentRoundRallCall(token, roundId).then((result) => {
          Array.from(new Set(result?.map(JSON.stringify)))?.map(JSON.parse);
        });
      } else if (roundNumber > 1) {
        if (roundData?.stageProcess == 0) {
          getRallCallByElection(token, electionId).then((result) => {
            // Utiliser un Set pour supprimer les doublons
            const uniqueResults = Array.from(
              new Set(result?.map(JSON.stringify))
            )?.map(JSON.parse);

            const updatedResults = uniqueResults.map((item) => ({
              ...item,
              roundID: roundId,
            }));
            setRoundRallCallData(updatedResults);
          });
        } else if (roundData?.stageProcess == 1) {
          getCurrentRoundRallCall(token, roundId).then((result) => {
            setRoundRallCallData(result);
          });
        }
      }
    }
  }, [roundId, token, roundNumber, state, roundData]);
  const formatPercentage = (percentage) => {
    const formattedPercentage = parseFloat(percentage).toFixed(3);
    const correctedNumber = Object.is(formattedPercentage, -0)
      ? 0
      : formattedPercentage;
    return correctedNumber;
  };

  useEffect(() => {
    if (roundRallCallData !== null) {
      if (state == 1) {
        const updatedSwitchColors = roundRallCallData.map(
          (item) => item.status === "Participant"
        );
        setSwitchColors(updatedSwitchColors);
      }
    }
  }, [roundRallCallData]);

  if (voters) {
    if (state == 1) {
      pieChartData = {
        labels: [], // Label for switchCount
        datasets: [
          {
            data: [switchCount, voters.length - switchCount],
            backgroundColor: ["#029455", "#EEF3F5"], // Change colors as needed
            hoverBackgroundColor: ["#029455", "#EEF3F5"], // Change hover colors if required
          },
        ],
        plugins: {
          datalabels: {
            formatter: (value, context) => {
              if (context.dataIndex !== 0) {
                return value; // Display switchCount value inside the pie
              }
              return "";
            },
            color: "#fff", // Set font color to white
          },
        },
      };
      doughnutChartData = {
        labels: [],
        datasets: [
          {
            data: [switchCountVotingPower, 100 - switchCountVotingPower],
            backgroundColor: ["#029455", "#EEF3F5"], // Change colors as needed
            hoverBackgroundColor: ["#029455", "#EEF3F5"], // Change hover colors if required
          },
        ],
      };
    } else if (state == 0) {
      pieChartData = {
        labels: [], // Label for switchCount
        datasets: [
          {
            data: [0, voters.length - switchCount],
            backgroundColor: ["#029455", "#EEF3F5"], // Change colors as needed
            hoverBackgroundColor: ["#029455", "#EEF3F5"], // Change hover colors if required
          },
        ],
        plugins: {
          datalabels: {
            formatter: (value, context) => {
              if (context.dataIndex !== 0) {
                return value; // Display switchCount value inside the pie
              }
              return "";
            },
            color: "#fff", // Set font color to white
          },
        },
      };
      doughnutChartData = {
        labels: [],
        datasets: [
          {
            data: [0, 100 - switchCountVotingPower],
            backgroundColor: ["#029455", "#EEF3F5"], // Change colors as needed
            hoverBackgroundColor: ["#029455", "#EEF3F5"], // Change hover colors if required
          },
        ],
      };
    }
  }

  return (
    <div
      className="row bloc-roll"
      style={{
        paddingLeft: "2rem",
        backgroundColor: "#DAE6B6",
        height: "80vh",
      }}
    >
      {/* Table Component */}
      <CheckConnection />
      {voters ? (
        <div
          className="col-12  col-xl-9"
          style={{
            backgroundColor: "#DAE6B6",
            height: "fit-content",

            border: "3px solid white",
            padding: "1%",
            borderRadius: "1.25rem",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "10px",
              marginBottom: "20px",
            }}
          >
            <h4 style={{ marginRight: "10px" }}>Rollcall / Rollcall</h4>
            {localData &&
              (state == 0 ? (
                <button
                  type="button"
                  className={
                    localData?.roles[0] === "Chairman" || !hasAccess
                      ? "disabledButton"
                      : "btn btn-success"
                  }
                  onClick={showPopUp}
                  disabled={localData?.roles[0] === "Chairman" || !hasAccess}
                >
                  Lancer l'appel nominal/Start RollCall
                </button>
              ) : (
                <button
                  type="button"
                  className={
                    localData?.roles[0] === "Chairman" || !hasAccess
                      ? "disabledButton"
                      : "btn btn-success"
                  }
                  onClick={showPopUp}
                  disabled={localData?.roles[0] === "Chairman" || !hasAccess}
                >
                  Terminer l'appel nominal/ End RollCall
                </button>
              ))}
          </div>
          {showPopup && (
            <div className="PopupConfirmStart">
              <div className="contentConfirmStart">
                <div>
                  <div className="Header">
                    <div className="TickIcon">{greenTick}</div>
                    <button
                      style={{ border: "none", backgroundColor: "#FFF" }}
                      onClick={handlePopupClose}
                    >
                      {GreyClose}
                    </button>
                  </div>
                  <div className="Text">
                    <div>
                      <div>
                        {state > 0 && switchCountVotingPower < 70 ? (
                          <p
                            style={{
                              color: "#2A2F48",
                              fontFamily: "DM Sans",
                              fontSize: "1.0625rem",
                              fontStyle: "normal",
                              fontWeight: "700",
                              lineHeight: "normal",
                            }}
                          >
                            Suspendre l'élection/ Suspend Election
                          </p>
                        ) : (
                          <p
                            style={{
                              color: "#2A2F48",
                              fontFamily: "DM Sans",
                              fontSize: "1.0625rem",
                              fontStyle: "normal",
                              fontWeight: "700",
                              lineHeight: "normal",
                            }}
                          >
                            Confimer/Confirm
                          </p>
                        )}
                      </div>
                      <div>
                        {state > 0 ? (
                          switchCountVotingPower < 70 ? (
                            <p className="informingText">
                              Le quorum n’est pas atteint à la fin du RollCall,
                              vous choisissez de suspendre
                              <br /> l’éléction <br />
                              Quorum is not reached by ending the RollCall you
                              are choosing to suspend the
                              <br />
                              election
                            </p>
                          ) : (
                            <p className="informingText">
                              Voulez-vous terminer le RallCall
                              <br />
                              Would you like to end the RallCall
                            </p>
                          )
                        ) : (
                          <p className="informingText">
                            Voulez-vous démarrer le Rollcall
                            <br />
                            Would you start the Rollcall
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* Buttons for confirmation and cancellation */}
                  <div className="abstainButtons">
                    <button
                      className="buttonChange"
                      style={{ border: "1px solid #029455", color: "#029455" }}
                      onClick={handlePopupClose}
                    >
                      Retourner/Go back
                    </button>
                    {state < 1 ? (
                      <button
                        className="buttonConfirm"
                        style={{ background: "#029455", color: "#FFF" }}
                        onClick={handleClickStartRollCall}
                        disabled={disableStartRallCallButton}
                      >
                        Confirmer/Confirm
                      </button>
                    ) : (
                      <button
                        className="buttonConfirm"
                        style={{ background: "#029455", color: "#FFF" }}
                        onClick={
                          switchCountVotingPower > 70
                            ? handleClickEndRollCall
                            : AdjournmentRedirect
                        }
                        disabled={
                          switchCountVotingPower > 70 &&
                          disableStartRallCallButton
                        }
                      >
                        {switchCountVotingPower > 70
                          ? "Confirmer/Confirm"
                          : "Do not proceed with the election"}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}

          <div
            className="w-100 resp-height"
            style={{ overflowY: "auto", height: "calc(100vh - 323px)" }}
          >
            <table className="w-100 rollCall-Table">
              <thead style={{ textAlign: "left", fontFamily: "DM Sans" }}>
                <tr>
                  <th>
                    Pays
                    <br />
                    Country
                  </th>
                  <th>
                    Régional/Non Régional
                    <br />
                    Regional/Non Regional
                  </th>
                  <th
                    style={
                      state < 1
                        ? {
                            pointerEvents: "none",
                            cursor: "no-drop",
                            textAlign: "center",
                          }
                        : {}
                    }
                  >
                    Electeurs
                    <br />
                    Voters
                  </th>
                  <th>
                    Statut
                    <br />
                    Status
                  </th>
                </tr>
              </thead>
              <tbody style={{ textAlign: "center" }}>
                {voters &&
                  voters.map((voter, index) => {
                    const isConnected = connectedUsers?.includes(
                      voter.email.trim().toLowerCase()
                    );
                    let isParticipant;
                    isParticipant = roundRallCallData?.some(
                      (item) =>
                        item.votersID === voter.id &&
                        item.status === "Participant"
                    );
                    const existingItemIndex = roundRallCallData.findIndex(
                      (item) => item.votersID === voter.id
                    );
                    if (existingItemIndex === -1) {
                      setRoundRallCallData((prevData) => [
                        ...prevData,
                        {
                          roundID: roundId,
                          votersID: voter.id,
                          startDate: "null",
                          endDate: "null",
                          status:
                            isConnected && roundData?.number < 2
                              ? "Participant"
                              : "Not participant",
                          email: voter.email,
                        },
                      ]);
                    }
                    return (
                      <tr
                        key={index}
                        className={
                          voter.country.regional ? "yellow-row" : "green-row"
                        }
                        style={{ borderBottom: "1px solid #92D390" }}
                      >
                        <td style={{ width: "30%" }}>{voter.country.title}</td>
                        {voter.country.regional ? (
                          <td>Regional/Regional</td>
                        ) : (
                          <td>Non Regional/Non Regional</td>
                        )}
                        {roundData && roundData?.number < 2 ? (
                          state == 0 ? (
                            <td
                              style={{
                                pointerEvents: "none",
                                cursor: "no-drop",
                              }}
                            >
                              <div className="form-check form-switch">
                                <p
                                  style={{
                                    display: "inline-block",
                                    marginRight: "10px",
                                  }}
                                  htmlFor={`flexSwitchCheck${index}`}
                                >
                                  Not present
                                </p>
                                <Switch
                                  // checked={connectedUsers?.includes(
                                  //   voter.email.trim().toLowerCase()
                                  // )}
                                  onChange={(isChecked) =>
                                    handleSwitchButtonChange(isChecked, voter)
                                  }
                                  style={{ backgroundColor: "#C7C7CC" }}
                                />
                                <p
                                  style={{
                                    display: "inline-block",
                                    marginLeft: "10px",
                                  }}
                                  className="form-check-label"
                                  htmlFor={`flexSwitchCheck${index}`}
                                >
                                  Present
                                </p>
                              </div>
                            </td>
                          ) : (
                            <td
                              style={
                                myStateRef.current == false
                                  ? {
                                      pointerEvents: "none",
                                      cursor: "no-drop",
                                    }
                                  : null
                              }
                            >
                              <div className="form-check form-switch">
                                <p
                                  style={{
                                    display: "inline-block",
                                    marginRight: "10px",
                                  }}
                                  htmlFor={`flexSwitchCheck${index}`}
                                >
                                  Not present
                                </p>
                                <Switch
                                  checked={switchColors[index] === true}
                                  style={
                                    switchColors[index] === true
                                      ? {
                                          backgroundColor: "#029455",
                                        }
                                      : isConnected
                                      ? { backgroundColor: "orange" }
                                      : { backgroundColor: "grey" }
                                  }
                                  onClick={() =>
                                    handleSwitchButtonChange(
                                      !switchColors[index],
                                      voter,
                                      index
                                    )
                                  }
                                />
                                <p
                                  style={{
                                    display: "inline-block",
                                    marginLeft: "10px",
                                  }}
                                  className="form-check-label"
                                  htmlFor={`flexSwitchCheck${index}`}
                                >
                                  Present
                                </p>
                              </div>
                            </td>
                          )
                        ) : state == 0 ? (
                          <td
                            style={{
                              pointerEvents: "none",
                              cursor: "no-drop",
                            }}
                          >
                            <div className="form-check form-switch">
                              <p
                                style={{
                                  display: "inline-block",
                                  marginRight: "10px",
                                }}
                                htmlFor={`flexSwitchCheck${index}`}
                              >
                                Not present
                              </p>
                              <Switch
                                checked={isParticipant ? true : false} // Check status for each 'item'
                                onChange={(isChecked) =>
                                  handleSwitchButtonParticipant(
                                    isChecked,
                                    voter
                                  )
                                }
                                style={{ backgroundColor: "#C7C7CC" }}
                              />
                              <p
                                style={{
                                  display: "inline-block",
                                  marginLeft: "10px",
                                }}
                                className="form-check-label"
                                htmlFor={`flexSwitchCheck${index}`}
                              >
                                Present
                              </p>
                            </div>
                          </td>
                        ) : (
                          <td
                            style={
                              myStateRef.current == false
                                ? { pointerEvents: "none", cursor: "no-drop" }
                                : null
                            }
                          >
                            <div className="form-check form-switch">
                              <p
                                style={{
                                  display: "inline-block",
                                  marginRight: "10px",
                                }}
                                htmlFor={`flexSwitchCheck${index}`}
                              >
                                Not present
                              </p>
                              <Switch
                                checked={isParticipant ? true : false} // Check status for each 'item'
                                onChange={(isChecked) =>
                                  handleSwitchButtonParticipant(
                                    isChecked,
                                    voter
                                  )
                                }
                                style={
                                  isParticipant
                                    ? {
                                        backgroundColor: "#029455",
                                      }
                                    : isConnected
                                    ? { backgroundColor: "orange" }
                                    : { backgroundColor: "grey" }
                                }
                              />
                              <p
                                style={{
                                  display: "inline-block",
                                  marginLeft: "10px",
                                }}
                                className="form-check-label"
                                htmlFor={`flexSwitchCheck${index}`}
                              >
                                Present
                              </p>
                            </div>
                          </td>
                        )}

                        <td
                          style={
                            isConnected
                              ? { color: "#029455", fontSize: "18px" }
                              : {}
                          }
                        >
                          {isConnected
                            ? "En ligne/Online"
                            : "Hors ligne/Offline"}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <div className="container-fluid" style={{ height: "80vh" }}>
          <div
            className="spinner-border text-success"
            role="status"
            style={{
              marginLeft: "50%",
              marginTop: "10%",
              width: "70px",
              height: "70px",
            }}
          ></div>
        </div>
      )}

      {/* STATS COMPONENT */}
      <div className="col-12 resp-card col-xl-3 card-canva">
        {/* COMPONENT 1 */}
        <div
          className="canva-resp"
          style={{
            height: "33%",
            backgroundColor: "#DAE6B6",
            marginBottom: "4%",
            borderRadius: "1.25rem",
            paddingRight: "0.75rem",
            paddingLeft: "0.75rem",
            border: "3px solid white",
          }}
        >
          {" "}
          <div style={{ height: "20%" }}>
            <p
              style={{
                color: "#2B3674",
                fontFamily: "DM Sans",
                fontWeight: "700",
                lineHeight: "1rem",
                fontStyle: "normal",
              }}
            >
              Pays présents / Countries present
            </p>{" "}
          </div>
          <div className="d-flex" style={{ height: "80%" }}>
            {voters && (
              <>
                <div style={{ width: "35%", marginBottom: "20px" }}>
                  <Pie data={pieChartData} style={{}} />
                </div>
                <div style={{ width: "65%" }}>
                  <p
                    style={{
                      color: "#2D3748",
                      fontFeatureSettings: "'clig' off, 'liga' off",
                      fontFamily: "Inter",
                      fontSize: "2.375rem",
                      fontWeight: "700",
                      lineHeight: "3.25rem",
                    }}
                  >
                    {switchCount}/{voters.length}
                  </p>
                  <div
                    className="d-flex  align-items-center"
                    style={{
                      width: "90%",
                      height: "35%",
                      backgroundColor: "#0BB7830A",
                      borderRadius: "0.625rem",
                      marginLeft: "5%",
                    }}
                  >
                    <h1
                      style={{
                        color: "#029455",
                        fontSize: "45px",
                        fontWeight: "bold",
                        marginBottom: "13%",
                      }}
                    >
                      .
                    </h1>
                    <p
                      style={{
                        color: "#1E1B39",
                        fontFeatureSettings: "'clig' 0, 'liga' 0",
                        fontFamily: "Inter",
                        fontSize: "0.875rem",
                        fontStyle: "normal",
                        fontWeight: "500",
                      }}
                    >
                      Présent / Present
                    </p>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>

        {/* COMPONENT 2 */}
        <div
          className="canva-resp"
          style={{
            height: "33%",
            backgroundColor: "#DAE6B6",
            marginBottom: "4%",
            borderRadius: "1.25rem",
            paddingRight: "0.75rem",
            paddingLeft: "0.75rem",
            border: "3px solid white",
          }}
        >
          {" "}
          <div style={{ height: "20%" }}>
            <p
              style={{
                color: "#2B3674",
                fontFamily: "DM Sans",
                fontWeight: "700",
                lineHeight: "1rem",
                fontStyle: "normal",
              }}
            >
              Pouvoir de vote / Voting Power
            </p>
          </div>
          {voters && (
            <div
              className="d-flex justify-content-center align-items-center resp-card-img"
              style={{ height: "80%" }}
            >
              <h4 style={{ color: "black", marginRight: "2rem" }}>
                {formatPercentage(doughnutChartData.datasets[0].data[0])} %
              </h4>
              <Doughnut
                data={doughnutChartData}
                style={{ marginBottom: "10%" }}
              />
            </div>
          )}
        </div>

        {/* COMPONENT 3 */}
        <div
          className="canva-resp"
          style={{
            height: "28%",
            backgroundColor: "#DAE6B6",
            borderRadius: "1.25rem",
            paddingRight: "0.75rem",
            paddingLeft: "0.75rem",
            border: "3px solid white",
          }}
        >
          {" "}
          <div style={{ height: "29%" }}>
            <p
              style={{
                color: "#2B3674",
                fontFamily: "DM Sans",
                fontWeight: "700",
                lineHeight: "2rem",
                fontStyle: "normal",
              }}
            >
              Quorum/Quorum
            </p>
          </div>
          <div className="d-flex justify-content-center align-items-center">
            <div
              className="d-flex align-items-center p-3 justify-content-center"
              style={{
                backgroundColor: "#0BB7830A",

                width: "90%",
              }}
            >
              {switchCountVotingPower && switchCountVotingPower > 70 ? (
                <>
                  <div
                    className="d-flex align-items-center justify-content-center"
                    style={{ width: "30%", height: "100%" }}
                  >
                    <img src="/assets/approveLogo.svg" alt="Approved" />
                  </div>
                  <div
                    className="d-flex align-items-center"
                    style={{ width: "70%" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <p
                        style={{
                          margin: 0,
                          color: "#2B3674",
                          fontFamily: "DM Sans",
                          fontSize: "0.8rem",
                          fontStyle: "normal",
                          fontWeight: "700",
                          lineHeight: "2rem",
                        }}
                      >
                        Quorum atteint
                      </p>

                      <p
                        style={{
                          margin: 0,
                          color: "#2B3674",
                          fontFamily: "DM Sans",
                          fontSize: "0.8rem",
                          fontStyle: "normal",
                          fontWeight: "700",
                          lineHeight: "2rem",
                        }}
                      >
                        Quorum met
                      </p>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div
                    className="d-flex align-items-center justify-content-end"
                    style={{ width: "30%", height: "100%" }}
                  >
                    <CloseCircleOutlined
                      style={{
                        backgroundColor: "#BC0000",
                        color: "white",
                        borderRadius: "2.5rem",
                        fontSize: "30px",
                      }}
                    />
                  </div>
                  <div
                    className="d-flex align-items-center"
                    style={{ width: "70%" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <p
                        className="mb-2"
                        style={{
                          margin: 0,
                          color: "#2B3674",
                          fontFamily: "DM Sans",
                          fontSize: "0.8rem",
                          fontStyle: "normal",
                          fontWeight: "700",
                          lineHeight: "1rem",
                        }}
                      >
                        Quorum non atteint
                      </p>
                      <p
                        style={{
                          margin: 0,
                          color: "#2B3674",
                          fontFamily: "DM Sans",
                          fontSize: "0.8rem",
                          fontStyle: "normal",
                          fontWeight: "700",
                          lineHeight: "1rem",
                        }}
                      >
                        Quorum not met
                      </p>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {popupRoundCreated && (
        <div className="PopupConfirmStart">
          <div className="contentConfirmStart">
            <div>
              <div className="Header">
                <div className="TickIcon">{greenTick}</div>
                <button
                  style={{ border: "none", backgroundColor: "#FFF" }}
                  onClick={handlePopupRoundCreatedClose}
                >
                  {GreyClose}
                </button>
              </div>
              <div className="Text">
                <div>
                  <div>
                    <h6>Tour crée avec succès /Round successfully created</h6>
                  </div>
                  <div>
                    <p className="informingText">
                      Vous avez créer le tour avec succès / You have
                      successfully created the round
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default RollCallComponent;
