import Navbar from "../../components/AdminBanner";
import { Breadcrumb } from "antd";
import { ClockCircleOutlined } from "@ant-design/icons";
import { useState, useEffect } from "react";
import { fetchToken } from "../../services/Token";
import {
  getAllElections,
  getCurrentElectionYear,
} from "../../services/Elections";
import { getRoundsByElection } from "../../services/Rounds";
import { getViewCandidates } from "../../services/Candidate";
import ReactCountryFlag from "react-country-flag";
import { getCountryCode } from "../../services/GetCountryCode";
import { getElectionResultsByCandidate } from "../../services/ElectionResults";
import DOMPurify from "dompurify";
import { getBusinessLog } from "../../services/BusinessLog";
import { useNavigate } from "react-router-dom";
export const CandidateRound = () => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [localData, setLocalData] = useState(null);
  const [token, setToken] = useState(null);
  const [electionId, setElectionId] = useState(null);
  const [rounds, setRounds] = useState(null);
  const [roundSelected, setRoundSelected] = useState(null);
  const [roundCandidates, setRoundCandidates] = useState(null);
  const [currentYear, setCurrentYear] = useState(null);
  const [isPet, setIsPet] = useState(null);
  const [isSg, setIsSg] = useState(null);
  const [isChairman, setIsChairman] = useState(null);
  const navigate = useNavigate();

  const lastItemStyle = {
    display: "inline",
    color: "#029455",
    fontFamily: "DM Sans",
    fontStyle: "normal",
    fontWeight: "500",
  };
  const handleRoundSelect = (round) => {
    setRoundSelected(round);
    setStartDate(round.startTime);
    setEndDate(round.endTime);
  };
  useEffect(() => {
    let idTokenClaims = JSON.parse(localStorage.getItem("idTokenClaims"));
    setLocalData(idTokenClaims);
  }, []);
  useEffect(() => {
    if (isPet && isSg && isChairman) {
      navigate("/AccessDenied");
    }
  }, [isPet, isSg, isChairman]);
  useEffect(() => {
    if (localData !== null) {
      fetchToken(localData).then((result) => {
        setToken(result);
      });
      setIsPet(localData?.roles?.includes("PET"));
      setIsChairman(localData?.roles?.includes("Chairman"));
      setIsSg(localData?.roles?.includes("SG"));
    }
  }, [localData]);
  useEffect(() => {
    if (token !== null) {
      getAllElections(token).then((result) => {
        // Filter elections with status Closed, Adjourned, or Started
        const filteredElections = result?.filter((election) =>
          ["Closed", "Adjourned", "Started"].includes(election.status)
        );

        if (filteredElections) {
          filteredElections.sort((a, b) => {
            return new Date(a.startDate) - new Date(b.startDate);
          });
        }

        // Sort filtered elections based on their order
        if (filteredElections?.length > 0) {
          // Set electionId to the id of the last element
          setElectionId(filteredElections[filteredElections.length - 1].id);
        } else {
          // If no matching elections found, set electionId to null or handle accordingly
          setElectionId(null);
        }
      });
    }
  }, [token]);

  const fetchRoundsByElection = async (token, electionId) => {
    if (electionId === null) return [];

    const rounds = await getRoundsByElection(token, electionId);
    return rounds?.filter((round) => round.status === "Closed") || [];
  };

  const validateRound = async (token, round) => {
    try {
      const results = await getBusinessLog(token, round.id);

      // Filter the results based on the "title" property
      const filteredResults = results.filter(
        (result) => result.title === "Validation"
      );

      // Check if there are exactly 3 items with title "Validation"
      if (filteredResults.length === 3) {
        return round; // Return the round if validation passes
      } else {
        console.error(
          `Round ${round.id} does not have 3 items with title "Validation"`
        );
        return null; // Return null to indicate validation failure
      }
    } catch (error) {
      console.error("Error fetching business log:", error);
      return null; // Return null to indicate validation failure
    }
  };

  const validateClosedRounds = async (token, closedRounds) => {
    const validationPromises = closedRounds.map((round) =>
      validateRound(token, round)
    );
    const validatedRounds = await Promise.all(validationPromises);
    return validatedRounds.filter((round) => round !== null);
  };

  useEffect(() => {
    const fetchData = async () => {
      const closedRounds = await fetchRoundsByElection(token, electionId);
      const validatedRounds = await validateClosedRounds(token, closedRounds);

      // Filter rounds where stageProcess is "5"
      const filteredRounds = validatedRounds.filter(
        (round) => round.stageProcess === "5"
      );

      setRounds(filteredRounds);
      try {
        const currentYearResult = await getCurrentElectionYear(
          token,
          electionId
        );
        if (currentYearResult !== null) {
          setCurrentYear(currentYearResult);
        }
      } catch (error) {
        console.error("Error occurred:", error);
      }
    };

    if (electionId !== null) {
      fetchData();
    }
  }, [electionId]);

  useEffect(() => {
    if (roundSelected !== null) {
      getViewCandidates(token, roundSelected.id).then((candidates) => {
        setRoundCandidates(candidates);
      });
    }
  }, [roundSelected]);

  useEffect(() => {
    const fetchResultsForCandidates = async () => {
      if (roundCandidates) {
        const fetchPromises = roundCandidates.map((candidate) =>
          fetchCandidateResults(candidate)
        );
        await Promise.all(fetchPromises);
      }
    };

    fetchResultsForCandidates();
  }, [token, electionId, roundCandidates]);

  const fetchCandidateResults = async (candidate) => {
    try {
      await getElectionResultsByCandidate(token, candidate.id, electionId);
    } catch (error) {
      console.error("Error fetching results for candidate", error);
    }
  };

  const formatPercentage = (percentage) => {
    const formattedPercentage = parseFloat(percentage).toFixed(2);
    return formattedPercentage;
  };

  function formatDate(dateString) {
    if (!dateString) {
      return "MM/DD/YYYY"; // Return "DD/MM/YYYY" if dateString is null or empty
    } else {
      const options = {
        day: "numeric",
        month: "numeric",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: false,
      };
      const formattedDate = new Date(dateString).toLocaleString(
        "en-GB",
        options
      );
      return formattedDate; // Transform to dd/mm/yyyy format if dateString is not null
    }
  }

  return (
    <div
      className="container-fluid"
      style={{ backgroundColor: "#DAE6B6", minHeight: "100vh" }}
    >
      <div className="row">
        <Navbar />
      </div>
      <div className="d-flex align-items-center px-2">
        <Breadcrumb separator=">" style={{ color: "rgba(0, 0, 0, 0.45)" }}>
          <Breadcrumb.Item>
            <p style={lastItemStyle}>Résultats des tours</p>
          </Breadcrumb.Item>
          <Breadcrumb.Item style={lastItemStyle}>
            <p style={lastItemStyle}>Round results</p>
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div
        style={{
          width: "98%",
          borderRadius: "2%",
          marginLeft: "1%",
        }}
      >
        <div
          style={{
            height: "15%",
            width: "100%",
            borderRadius: "3%",
            alignItems: "center",
            display: "flex",
            padding: "10px 0",
          }}
        >
          <img
            src="../../assets/Logo.png"
            alt="AFDBLOGO"
            style={{ height: "75px" }}
          />
          <div style={{ marginLeft: "15px" }}>
            <p
              style={{
                color: "#029455",
                fontFamily: "DM Sans",
                fontSize: "29px",
                fontWeight: "700",
                lineHeight: "30px",
                letterSpacing: "-0.02em",
              }}
            >
              {currentYear} BAD/ADB
            </p>
            <p
              style={{
                color: "#2D323E",
                fontFamily: "DM Sans",
                fontSize: "24px",
                fontWeight: "700",
                lineHeight: "24px",
                letterSpacing: "-0.02em",
              }}
            >
              Résultats des tours/Round results
            </p>
          </div>
          <div
            style={{
              alignItems: "center",
              display: "flex",
              height: "57px",
              backgroundColor: "#5968640A",
              marginLeft: "auto",
            }}
          >
            <ClockCircleOutlined
              style={{
                width: "2rem",
                justifyContent: "center",
                height: "3rem",
                flexShrink: "0",
                color: "green",
              }}
            />
            <p
              style={{
                fontFamily: "DM Sans",
                fontSize: "16px",
                fontWeight: "700",
                lineHeight: "20px",
                letterSpacing: "-0.02em",
                textAlign: "left",
              }}
            >
              {`Start Time: ${formatDate(startDate)} - End Time: ${formatDate(
                endDate
              )}`}
            </p>
          </div>
        </div>
        <div
          style={{
            margin: "1rem",
            width: "100%",
            borderRadius: "3%",
            alignItems: "center",
            display: "flex",
          }}
        >
          <p
            style={{
              color: "#2D323E",
              fontFamily: "DM Sans",
              fontSize: "16px",
              fontWeight: "700",
              lineHeight: "15px",
              letterSpacing: "-0.02em",
            }}
          >
            Choisir le tour/Select round :{" "}
          </p>
          <div className="btn-group" style={{ marginLeft: "1%" }}>
            <button
              className="btn btn-outline-success btn-sm dropdown-toggle"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              style={{ width: "200px", minHeight: "39px" }}
            >
              {roundSelected
                ? `Tour N° ${roundSelected?.number} / Round N°${roundSelected?.number}`
                : "Select Round  "}
            </button>
            <ul className="dropdown-menu">
              {rounds !== null && rounds.length !== 0 ? (
                rounds?.map((round) => (
                  <li key={round.id}>
                    <button
                      className="dropdown-item"
                      type="button"
                      onClick={() => handleRoundSelect(round)}
                    >
                      Tour N° {round?.number} / Round N° {round?.number}
                    </button>
                  </li>
                ))
              ) : (
                <li>
                  <button
                    className="dropdown-item"
                    type="button"
                    style={{ width: "100%" }}
                  >
                    No Data Available ...
                  </button>
                </li>
              )}
            </ul>
          </div>
        </div>
        <div style={{ width: "100%", borderRadius: "3%" }}>
          <div style={{ width: "100%", display: "flex", flexWrap: "wrap" }}>
            {roundCandidates?.map((candidate) => (
              <div
                key={candidate.id}
                className=""
                style={{
                  width: "23%",
                  marginBottom: "1rem",
                  marginRight: "2%",
                  border: `2px solid ${
                    candidate.status === "Qualified" ||
                    candidate.status === "Winner"
                      ? "#029455"
                      : "#BC0000"
                  }`,
                  borderRadius: "8px",
                }}
              >
                <div>
                  <p
                    className=""
                    style={{
                      textAlign: "center",
                      color: "var(--Secondary-Dark-Grey-900, #1B2559)",
                      fontFamily: "DM Sans",
                      fontSize: "1.8rem",
                      fontStyle: "normal",
                      fontWeight: "700",
                      lineHeight: "100%",
                      minHeight: "55px",
                      letterSpacing: "-0.05rem",
                    }}
                  >
                    {candidate?.firstName} {candidate?.lastName}
                  </p>
                </div>
                <div
                  className="candidateCountry"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <span>
                    <ReactCountryFlag
                      countryCode={getCountryCode(candidate?.country)}
                      svg
                      style={{
                        width: "1.5rem",
                        height: "1.5rem",
                        marginRight: "0.5rem",
                        marginLeft: "0.5rem",
                      }}
                    />
                  </span>
                  <span className="presidentCountry">{candidate?.country}</span>
                </div>
                <div
                  className="d-flex"
                  style={{
                    overflow: "hidden",
                    justifyContent: "center",
                    height: "160px",
                    padding: "0.5rem",
                  }}
                >
                  <img
                    src={DOMPurify.sanitize(
                      `${candidate?.urlPhoto}?${process.env.REACT_APP_SIGNATURE}`
                    )}
                    alt={`${candidate?.firstName} ${candidate?.lastName}`}
                    style={{
                      objectFit: "cover",
                      maxWidth: "100%",
                      maxHeight: "100%",
                    }}
                  />
                </div>

                <div className="stats">
                  <div className="stats-item">
                    Total de tous les votes/ Total of all votes
                    <br />
                    <p className="statsNumber">
                      {formatPercentage(candidate.total_VP)} %
                    </p>
                  </div>
                  <div className="vertical-line"></div>
                  <div className="stats-item">
                    Votes régionaux/ Regional vote
                    <br />
                    <p className="statsNumber">
                      {formatPercentage(candidate.total_RVP)} %
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
