import axios from "axios";

export const getVoterByEmailId = async (secret, electionId) => {
  const idTokenClaims = JSON.parse(localStorage.getItem("idTokenClaims"));
  const VoterEmail = idTokenClaims.preferred_username;
  try {
    if (idTokenClaims?.roles?.includes("Governors")) {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/Voters/GetVotersByEmail?Email=${VoterEmail}&electionID=${electionId}`,
        {
          headers: {
            Authorization: `Bearer ${secret}`,
            Accept: "text/plain",
          },
        }
      );
      return response.data;
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

export const checkEligibility = async (VoterId, RoundId, secret) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/api/Voters/CheckVoterEligibility?VotersId=${VoterId}&roundId=${RoundId}`,
      {
        headers: {
          Authorization: `Bearer ${secret}`,
          Accept: "text/plain",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

export const getVotersByElection = async (secret, electionID) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/api/Voters/GetVotersByElection?electionID=${electionID}`,
      {
        headers: {
          Authorization: `Bearer ${secret}`,
          Accept: "text/plain",
        },
      }
    );
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const checkIfVoteExists = async (secret, voterId, roundId) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/api/Voters/CheckIfVoteExist?VotersId=${voterId}&roundId=${roundId}`,
      {
        headers: {
          Authorization: `Bearer ${secret}`,
          Accept: "text/plain",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

export const getVotersByRound = async (secret, roundId) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/api/Voters/GetVotersByRound?roundID=${roundId}`,
      {
        headers: {
          Authorization: `Bearer ${secret}`,
          Accept: "text/plain",
        },
      }
    );
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const PostVoters = async (secret, voters) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/api/voters`,
      voters,
      {
        headers: {
          Authorization: `Bearer ${secret}`,
          Accept: "text/plain",
        },
      }
    );
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const deleteVotersByElectionID = async (secret, ElectionID) => {
  try {
    const response = await axios.delete(
      `${process.env.REACT_APP_API_BASE_URL}/api/Voters?electionId=${ElectionID}`,
      {
        headers: {
          Authorization: `Bearer ${secret}`,
          Accept: "text/plain",
        },
      }
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
