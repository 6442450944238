import Navbar from "../../components/AdminBanner";
import ItAdminElectionList from "./ItAdminElectionList";
import { Breadcrumb } from "antd";

const AdminContent = () => {
  const lastItemStyle = {
    color: "#029455",
    fontFamily: "DM Sans",
    fontStyle: "normal",
    fontWeight: "500",
    cursor: "default",
  };
  return (
    <div className="container-fluid" style={{ backgroundColor: "#FAFBFC" }}>
      <div className="row">
        <Navbar />
      </div>
      <div className="d-flex align-items-center" style={{ height: "5vh" }}>
        <Breadcrumb separator=">" style={{ color: "rgba(0, 0, 0, 0.45)" }}>
          <Breadcrumb.Item style={lastItemStyle}>
            <a style={lastItemStyle}>Election</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <a style={{ color: "rgba(0, 0, 0, 0.45)", cursor: "default" }}></a>
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="row">
        <ItAdminElectionList />
      </div>
    </div>
  );
};

export default AdminContent;
