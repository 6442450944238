import Navbar from "../../components/AdminBanner";
import { Breadcrumb } from "antd";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { fetchToken } from "../../services/Token";
import { getVotersByElection } from "../../services/Voters";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "../ElectionContent.css";
import "../ElectionContent.css";
import { getCurrentElectionID } from "../../services/Elections";
import VotingComponent from "./ElectionProcess/Voting";
import RollCallComponent from "./ElectionProcess/RallCall";
import createWebSocketConnectionVotes from "../../services/WebSocketVotesService";
import { getLastRound } from "../../services/Rounds";
import PublicationComponent from "./ElectionProcess/Publication";
import { TimerIcon, redRestrict } from "../../assets/Icons";
import { useConnection } from "../../Context/ConnectionContext";
import { useNavigate } from "react-router-dom";
import createWebSocketConnectUser from "../../services/WebSocketConnectService";

export const ElectionProcess = () => {
  const [roundNumber, setRoundNumber] = useState(null);
  const [localData, setLocalData] = useState(null);
  const [state, setState] = useState(0);
  const [token, setToken] = useState(null);
  const [voters, setVoters] = useState(null);
  const [connectedUsers, setConnectedUsers] = useState(null);
  const [electionId, setElectionId] = useState(null);
  const [buttonText, setButtonText] = useState(
    "Lancer Appel Nominal/Start RollCall"
  );
  const [updatedCount, setUpdatedCount] = useState(0);
  const [roundId, setRoundId] = useState(null);
  const [switchColors, setSwitchColors] = useState(
    Array(voters?.length).fill()
  );
  const [isPet, setIsPet] = useState(null);
  const [isSg, setIsSg] = useState(null);
  const [isChairman, setIsChairman] = useState(null);
  const [roundData, setRoundData] = useState(null);
  const [hasAccess, setHasAccess] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [connectedUser, setConnectedUser] = useState("");
  const [onGoingElection, setOngoingElection] = useState();
  const [votersPerRound, setVotersPerRound] = useState(null);
  const [popupRoundCreated, setPopUpRoundCreated] = useState(false);
  const [stage, setStage] = useState();
  const [delayedRender, setDelayedRender] = useState(false);
  const { isOnline } = useConnection();
  const prevIsOnline = useRef(isOnline);
  const navigate = useNavigate();
  const myStateRef = useRef(hasAccess);
  const hasAccessMemo = useMemo(() => myStateRef.current, []);
  const [secondsLeft, setSecondsLeft] = useState(10);

  const reloadPage = () => {
    window.location.reload();
  };
  useEffect(() => {
    if (isPet == false && isSg == false && isChairman == false) {
      navigate("/AccessDenied");
    }
  }, [isPet, isSg, isChairman]);
  useEffect(() => {
    if (connectedUser === "unlocked") {
      const interval = setInterval(() => {
        setSecondsLeft((prevSeconds) => {
          if (prevSeconds === 1) {
            clearInterval(interval);
            reloadPage();
          }
          return prevSeconds - 1;
        });
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [connectedUser]);
  useEffect(() => {
    const timer = setTimeout(() => {
      setDelayedRender(true);
    }, 1200);

    return () => {
      clearTimeout(timer);
    };
  }, []);
  useEffect(() => {
    const fetchRoundId = async () => {
      try {
        if (token !== null && electionId !== null) {
          const result = await getLastRound(token, electionId);
          setRoundId(result?.id);
          setRoundNumber(result?.number);
        }
      } catch (error) {
        console.error("Error fetching roundId:", error);
      }
    };
    fetchRoundId();
  }, [electionId]);

  useEffect(() => {
    let idTokenClaims = JSON.parse(localStorage.getItem("idTokenClaims"));
    setLocalData(idTokenClaims);
  }, []);

  useEffect(() => {
    if (localData !== null) {
      fetchToken(localData).then((result) => {
        setToken(result);
      });
      setIsPet(localData?.roles?.includes("PET"));
      setIsChairman(localData?.roles?.includes("Chairman"));
      setIsSg(localData?.roles?.includes("SG"));
    }
  }, [localData]);

  useEffect(() => {
    // Condition to show popup when a new round is created and other conditions are met
    if (
      !popupRoundCreated &&
      roundNumber !== 1 &&
      roundData?.stageProcess === "0"
    ) {
      setPopUpRoundCreated(true);
    }
  }, [roundData]);

  // WebSocket connection for ConnectedPETorSG
  useEffect(() => {
    if (token !== null && delayedRender) {
      const socket = createWebSocketConnectUser();

      socket.onopen = () => {
        // Send the initial message to start receiving data
        const initialMessage =
          JSON.stringify({ protocol: "json", version: 1 }) +
          String.fromCharCode(0x1e);
        socket.send(initialMessage);
      };
      // Handle connection errors
      socket.onerror = (error) => {
        console.error("WebSocket error occurred:", error);
      };
      socket.onmessage = async (event) => {
        const rawData = event.data;
        const messages = rawData
          .split(String.fromCharCode(0x1e))
          .filter(Boolean);
        if (messages.length > 1) {
          const parsedData = JSON.parse(messages[1]);
          if (parsedData.target === "SendConnectedPETorSG") {
            if (localData.preferred_username == parsedData.arguments[0]) {
              setHasAccess(true);
            }
            setPageLoading(false);
            setConnectedUser(parsedData.arguments[0]);
          }
        } else if (messages.length === 1) {
          const data = messages[0];
          const parsedData = JSON.parse(data);
          const typevalue = parsedData.type;

          if (typevalue === 1) {
            if (parsedData.target === "SendConnectedPETorSG") {
              if (localData.preferred_username == parsedData.arguments[0]) {
                setHasAccess(true);
              }
              setPageLoading(false);
              setConnectedUser(parsedData.arguments[0]);
            }
          }
        }
      };
      socket.onerror = (error) => {
        console.error("WebSocket error:", error);
      };
      return () => {
        socket.close();
      };
    }
  }, [token, delayedRender]);

  useEffect(() => {
    myStateRef.current = hasAccess;
  }, [hasAccess]);
  useEffect(() => {
    if (isOnline && prevIsOnline.current != isOnline) {
      const socket = createWebSocketConnectionVotes();
      socket.onopen = () => {
        // Send the initial message to start receiving data
        const initialMessage =
          JSON.stringify({ protocol: "json", version: 1 }) +
          String.fromCharCode(0x1e);
        socket.send(initialMessage);
      };
      // Handle connection errors
      socket.onerror = (error) => {
        console.error("WebSocket error occurred:", error);
      };
      prevIsOnline.current = isOnline;
    } else if (prevIsOnline.current !== isOnline) {
      prevIsOnline.current = isOnline;
    }
  }, [isOnline]);

  useEffect(() => {
    if (token !== null) {
      getCurrentElectionID(token).then((result) => {
        setElectionId(result?.id);
        // Set ongoingElection based on the result
        setOngoingElection(result ? true : false);
        setPageLoading(false);
      });

      const socket = createWebSocketConnectionVotes();
      socket.onopen = () => {
        // Send the initial message to start receiving data
        const initialMessage =
          JSON.stringify({ protocol: "json", version: 1 }) +
          String.fromCharCode(0x1e);
        socket.send(initialMessage);
      };
      socket.onmessage = async (event) => {
        const rawData = event.data;
        const messages = rawData
          .split(String.fromCharCode(0x1e))
          .filter(Boolean);
        if (messages.length > 1) {
          const parsedData = JSON.parse(messages[1]);
          if (parsedData.target === "SendStateProcess") {
            setState(parsedData.arguments[0]);
          }
          if (parsedData.target === "SendStateElection") {
            if (parsedData.arguments[0] == "Adjourned") {
              if (
                window.location.pathname.includes(
                  "/ElectionContent/Election_process"
                )
              ) {
                navigate("/ElectionContent/Adjournment");
              }
            } else if (parsedData.arguments[0] == "Started") {
              if (isChairman || isPet || isSg) {
                if (
                  window.location.pathname ===
                  "/ElectionContent/Election_process"
                ) {
                  window.location.reload();
                } else if (window.location.pathname === "/") {
                  navigate("/ElectionContent/Election_process");
                }
              }
            }
          }
        } else if (messages.length === 1) {
          const data = messages[0];
          const parsedData = JSON.parse(data);
          const typevalue = parsedData.type;

          if (typevalue === 1) {
            if (parsedData.target === "SendStateProcess") {
              setState(parsedData.arguments[0]);
            }
            if (parsedData.target === "SendStateElection") {
              if (parsedData.arguments[0] == "Adjourned") {
                if (
                  window.location.pathname.includes(
                    "/ElectionContent/Election_process"
                  )
                ) {
                  navigate("/ElectionContent/Adjournment");
                }
              }
              if (parsedData.arguments[0] == "Started") {
                if (isChairman || isPet || isSg) {
                  if (
                    window.location.pathname ===
                    "/ElectionContent/Election_process"
                  ) {
                    window.location.reload();
                  } else if (window.location.pathname === "/") {
                    navigate("/ElectionContent/Election_process");
                  }
                }
              }
            }
          }
        }
      };
    }
  }, [token]);

  useEffect(() => {
    if (token !== null && electionId !== null) {
      getLastRound(token, electionId).then((result) => {
        if (result) {
          setRoundId(result.id);
          setRoundData(result);
        }
      });
      getVotersByElection(token, electionId).then((result) => {
        if (result) {
          setVoters(result.data);
        }
      });
    }
  }, [electionId]);

  useEffect(() => {
    if (token !== null && electionId !== null && roundData !== null) {
      setStage(roundData?.stageProcess);
    }
  }, [roundData]);

  useEffect(() => {
    if (roundData !== null) {
      setState(roundData?.stageProcess);
    }
  }, [roundData]);

  const lastItemStyle = {
    color: "#029455",
    fontFamily: "DM Sans",
    fontSize: "1rem",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "24px",
    cursor: "default",
  };

  // Steps
  const steps = [
    { title: "Appel Nominal", titleEnglish: "RollCall", key: "0" },
    { title: "Vote", titleEnglish: "Voting", key: "2" },
    { title: "Validation", titleEnglish: "Validation", key: "4" },
    { title: "Publication", titleEnglish: "Publication", key: "5" },
  ];
  const renderSteps = () => {
    return steps?.map((step, index) => {
      let stepStyle = { fontSize: "16px", fontWeight: "bold" };

      return (
        <React.Fragment key={index}>
          {/* Circle with step number */}
          <div
            style={{
              display: "inline-flex",
              alignItems: "center",
            }}
          >
            <div
              style={
                Number(steps[index]?.key) <= state
                  ? {
                      borderRadius: "50%",
                      color: "white",
                      width: "30px",
                      height: "30px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginRight: "5px",
                      fontWeight: "bold",
                      border: "1px solid #029455",
                      backgroundColor: "#029455",
                    }
                  : {
                      borderRadius: "50%",
                      color: "#029455",
                      width: "30px",
                      height: "30px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginRight: "5px",
                      fontWeight: "bold",
                      border: "1px solid #029455",
                    }
              }
            >
              {index + 1}
            </div>
            {/* Step title */}

            <div
              className="stepper-item"
              style={{ marginRight: "30px", marginLeft: "3px" }}
            >
              <div>
                <span style={stepStyle}>{step.title}</span>
              </div>
              <div>
                <span style={stepStyle}>{step.titleEnglish}</span>
              </div>
            </div>
          </div>
          {index !== steps.length - 1 && (
            <span
              className="stepper-item"
              style={{ color: "black", fontSize: "30px", marginRight: "30px" }}
            >
              {" "}
              {">"}{" "}
            </span>
          )}
        </React.Fragment>
      );
    });
  };

  /*********************************************************Main Code for Page Election Process ***********************************************************/
  return (
    <>
      {pageLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh", // Use full viewport height
          }}
        >
          <div
            className="spinner-border text-success"
            role="status"
            style={{
              width: "70px",
              height: "70px",
            }}
          ></div>
        </div>
      ) : (
        <div className="container-fluid" style={{ backgroundColor: "#FAFBFC" }}>
          {connectedUser === "unlocked" && (
            <div className="PopupValidation">
              <div className="contentConfirmStart">
                {/* header */}
                <div className="Header d-flex justify-content-center">
                  <div>{TimerIcon}</div>
                </div>

                <div className="text-center">
                  <h6 className="textStylePopUp">
                    Plus aucun PET ne controlle le process donc veuillez cliquez
                    sur le boutton
                  </h6>
                  <h6 className="textStylePopUp">
                    pour rafraichir la page sinon la page redemarrera
                    automatiquement dans {secondsLeft} secondes.
                  </h6>
                </div>

                <div
                  className="d-flex justify-content-center "
                  style={{
                    backgroundColor: "white",
                    width: "100%",
                    marginTop: "4%",
                  }}
                >
                  <div
                    className="d-flex justify-content-center"
                    style={{
                      backgroundColor: "rgba(89, 104, 100, 0.08)",
                      marginBottom: "2%",
                      width: "60%",
                      border: "1px solid #ccc",
                    }}
                  >
                    <button
                      type="button"
                      class="btn btn-success"
                      style={{ width: "100%" }}
                      onClick={reloadPage}
                    >
                      Reload Page
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
          {/************************************************************NAVBAR COMPONENT CALL****************************************************/}
          <div className="row" style={{ backgroundColor: "#DAE6B6" }}>
            <div className="col">
              <Navbar />
            </div>
          </div>
          {onGoingElection ? (
            /*****************************************Breadcrumb and Current Step Components****************************************************/
            <div
              className="row"
              style={{
                backgroundColor: "#DAE6B6",
                height: "calc(100vh - 70px)",
                overflow: "auto",
              }}
            >
              {/**BREADCRUMB COMPONENT*/}
              <div className="d-flex align-items-center my-2">
                <Breadcrumb
                  separator=">"
                  style={{ color: "rgba(0, 0, 0, 0.45)" }}
                >
                  <Breadcrumb.Item>
                    <a
                      style={{
                        color: "rgba(0, 0, 0, 0.45)",
                        cursor: "default",
                      }}
                    >
                      Processus d'élection/Election process
                    </a>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item style={lastItemStyle}>
                    <a style={lastItemStyle}>
                      {state < 2
                        ? "Rollcall/Rollcall"
                        : state < 4
                        ? "Vote/Voting"
                        : state < 5
                        ? "Validation/Validation"
                        : "Publication/Publication"}
                    </a>
                  </Breadcrumb.Item>
                </Breadcrumb>
              </div>

              <div
                className="col d-flex align-items-center mb-3"
                style={{ flexWrap: "wrap" }}
              >
                <div>
                  <span
                    style={{
                      fontSize: "20px",
                      fontWeight: "bold",
                      textAlign: "left",
                    }}
                  >
                    Tour N:{roundNumber} / Round N:{roundNumber}
                  </span>
                </div>
                <div
                  className="stepper col d-flex align-items-center justify-content-center"
                  style={{ gap: "1.375rem", height: "8vh" }}
                >
                  {renderSteps()}
                </div>
              </div>

              {state < 2 ? (
                <RollCallComponent
                  voters={voters}
                  connectedUsers={connectedUsers}
                  state={state}
                  buttonText={buttonText}
                  roundId={roundId}
                  switchColors={switchColors}
                  setState={setState}
                  token={token}
                  setElectionId={setElectionId}
                  setConnectedUsers={setConnectedUsers}
                  localData={localData}
                  setSwitchColors={setSwitchColors}
                  setUpdatedCount={setUpdatedCount}
                  electionId={electionId}
                  roundData={roundData}
                  setVotersPerRound={setVotersPerRound}
                  votersPerRound={votersPerRound}
                  setRoundId={setRoundId}
                  setRoundNumber={setRoundNumber}
                  roundNumber={roundNumber}
                  popupRoundCreated={popupRoundCreated}
                  setPopUpRoundCreated={setPopUpRoundCreated}
                  hasAccess={hasAccess}
                  setHasAccess={setHasAccess}
                  hasAccessMemo={hasAccessMemo}
                  myStateRef={myStateRef}
                />
              ) : state < 5 && state >= 2 ? (
                <VotingComponent
                  votersPerRound={votersPerRound}
                  setVotersPerRound={setVotersPerRound}
                  token={token}
                  roundId={roundId}
                  electionId={electionId}
                  state={state}
                  setState={setState}
                  localData={localData}
                  roundData={roundData}
                  hasAccess={hasAccess}
                />
              ) : (
                <>
                  <PublicationComponent
                    token={token}
                    electionId={electionId}
                    state={state}
                    setState={setState}
                    localData={localData}
                    // voters={voters}
                    setRoundData={setRoundData}
                    roundId={roundId}
                    setRoundId={setRoundId}
                    hasAccess={hasAccess}
                  />
                </>
              )}
              <p
                style={{
                  fontFamily: "DM Sans",
                  fontSize: "16px",
                  fontWeight: "700",
                  lineHeight: "28px",
                  textAlign: "left",
                  paddingLeft: "1%",
                  color: "#2D3748",
                  marginTop: "10px",
                }}
              >
                The process is currently owned by{" "}
                <span style={{ color: "#029455" }}>
                  {myStateRef == false
                    ? `${connectedUser}`
                    : ` ${connectedUser}`}
                </span>
                .
              </p>
            </div>
          ) : (
            <div
              className="row"
              style={{
                height: "100vh",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                backgroundColor: "#DAE6B6",
              }}
            >
              {redRestrict}
              <h6
                style={{
                  fontFamily: "DM Sans",
                  fontSize: "22px",
                  fontWeight: "700",
                  lineHeight: "31px",
                  letterSpacing: "0em",
                  marginBottom: "1rem",
                  marginTop: "1rem",
                }}
              >
                Merci de patienter! Le vote n'a pas commencé / Please hold on!
                Election has not yet started
              </h6>
              <p
                style={{
                  fontFamily: "DM Sans",
                  fontSize: "19px",
                  fontWeight: "400",
                  lineHeight: "28.64px",
                  letterSpacing: "0em",
                }}
              >
                Nous vous informons qu'aucune élection n'est actuellement en
                cours. Veuillez vous abstenir de procéder d'avantage jusqu'au
                commencement officiel du processus électoral /
                <br />
                No election is currently in progress. Kindly refrain from
                proceeding further until the official commencement of the
                election process.
              </p>
            </div>
          )}
        </div>
      )}
    </>
  );
};
