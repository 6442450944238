import React, { createContext, useContext, useState } from "react";

const ConnectionContext = createContext();

export const useConnection = () => {
  return useContext(ConnectionContext);
};

export const ConnectionProvider = ({ children }) => {
  const [isOnline, setIsOnline] = useState(true); // Default value is true for online

  return (
    <ConnectionContext.Provider value={{ isOnline, setIsOnline }}>
      {children}
    </ConnectionContext.Provider>
  );
};
