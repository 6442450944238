import axios from "axios";

export const addVote = async (secret, roundID, votersID, candidateID) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/api/Votes`,
      {
        roundID: roundID,
        votersID: votersID,
        candidateID: candidateID,
      },
      {
        headers: {
          Authorization: `Bearer ${secret}`,
          Accept: "text/plain",
          "Content-Type": "application/json",
        },
      }
    );
    return response;
  } catch (error) {
    //console.error("Error fetching data:", error);
    return error;
  }
};
