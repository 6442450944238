import { useState, useEffect } from "react";
import { ClockCircleOutlined } from "@ant-design/icons";
export const Banner = ({ currentYear, roundData }) => {
  const logoImageUrl = "../../assets/Logo.png";

  const [startTimee, setStartTimee] = useState(null);
  const [timer, setTimer] = useState({ seconds: 0, minutes: 0, hours: 0 });
  const [roundNumber, setRoundNumber] = useState(null);

  useEffect(() => {
    if (roundData !== null) {
      // Check if roundData is a promise
      if (roundData instanceof Promise) {
        roundData.then((result) => setRoundNumber(result?.number));
        roundData.then((result) => setStartTimee(result?.startTime));
      } else {
        setRoundNumber(roundData?.number);
        setStartTimee(roundData?.startTime);
      }
    }
  }, [roundData]);

  useEffect(() => {
    if (startTimee !== null) {
      const interval = setInterval(() => {
        const now = new Date().getTime();
        const startTime = new Date(startTimee); // Parse startTimee into a Date object
        const start =
          startTime.getTime() - startTime.getTimezoneOffset() * 60 * 1000;
        const elapsedTime = now - start;
        const hours = Math.floor((elapsedTime / (1000 * 60 * 60)) % 24);
        const seconds = Math.floor((elapsedTime / 1000) % 60);
        const minutes = Math.floor((elapsedTime / (1000 * 60)) % 60);

        setTimer({ hours, minutes, seconds });
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [startTimee]);

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "0.2rem 0.5rem",
          alignItems: "center",
        }}
      >
        <div className="d-flex align-items-center justify-content-center ">
          <img src={logoImageUrl} alt="Logo" width="119px" />
          <h6
            style={{
              color: "#2B3674",
              fontFamily: "DM Sans",
              fontSize: "1rem",
              fontStyle: "normal",
              fontWeight: "700",
              letterSpacing: "0.03125rem",
            }}
          >
            {currentYear} BAD/ADB
          </h6>
        </div>
        <h2 style={{ color: "#2B3674", textAlign: "center" }}>
          JE VOTE POUR / I VOTE FOR
        </h2>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
          }}
        >
          <div
            style={{
              flexShrink: 0,
              marginRight: "0.5rem",
              color: "#2B3674",
              fontFamily: "DM Sans",
              fontSize: "0.9375rem",
              fontStyle: "normal",
              fontWeight: 700,
              letterSpacing: "0.03125rem",
            }}
          >
            <p>
              Tour N°{roundNumber} / Round N°{roundNumber}
            </p>
          </div>
          <div
            style={{
              backgroundColor: "white",
              display: "flex",
              alignItems: "center",
              borderRadius: "10px" /* Coins arrondis */,
              padding: "8px" /* Ajout de rembourrage */,
              width: "80px" /* Largeur du conteneur ajustée */,
              border: "1px solid grey",
            }}
          >
            <ClockCircleOutlined
              style={{
                marginRight: "5px",
                width: "1rem",
                height: "1rem",
                flexShrink: "0",
              }}
            />
            <p
              style={{
                color: "#737791",
                fontFamily: "Roboto",
                fontSize: "0.8125rem",
                fontStyle: "normal",
                fontWeight: "600",
                lineHeight: "1.59375rem",
                letterSpacing: "0.03125rem",
                margin:
                  "0" /* Réinitialisation de la marge du paragraphe pour aligner exactement avec l'icône */,
              }}
            >
              {timer.hours}:{timer.minutes}:{timer.seconds}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
