import axios from "axios";

export const getVoteStats = async (secret, electionId, roundId) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/api/Statistique/CalculateVote?idElection=${electionId}&idRound=${roundId}`,
      {
        headers: {
          Authorization: `Bearer ${secret}`,
          Accept: "text/plain",
        },
      }
    );
    return response.data;
  } catch (error) {
    // console.log("Error fetching vote stats",error)
  }
};

export const getReturningOfficerStats = async (secret, electionId, roundId) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/api/Statistique/ROStats?idElection=${electionId}&idRound=${roundId}`,
      {
        headers: {
          Authorization: `Bearer ${secret}`,
          Accept: "text/plain",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};
