import axios from "axios";

export const getBusinessLog = async (secret, roundId) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/api/BusnessLogs/GetBusnessLogByRound?RoundID=${roundId}`,
      {
        headers: {
          Authorization: `Bearer ${secret}`,
          Accept: "text/plain",
        },
      }
    );
    return response.data;
  } catch (error) {
    // console.error("Error fetching data:", error);
  }
};

export const ValidateResults = async (secret, roundId, sysdate, userEmail) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/api/BusnessLogs`,
      {
        roundId: roundId,
        title: "Validation",
        timestamp: sysdate,
        createdBy: userEmail,
        description: "Validation",
      },
      {
        headers: {
          Authorization: `Bearer ${secret}`,
          Accept: "text/plain",
          "Content-Type": "application/json",
        },
      }
    );
    return response;
  } catch (error) {
    //   console.error('Error posting data:', error);
  }
};
