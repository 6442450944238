import axios from "axios";

export const getCountryByElectionID = async (secret, ElectionID) => {
  const response = await axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/api/Countries/GetCountryByElectionID?idElection=${ElectionID}`,
    {
      headers: {
        Authorization: `Bearer ${secret}`,
        Accept: "text/plain",
      },
    }
  );
  return response; // Return the response data from the API
};

export const PostCountriesVotingPower = async (secret, Data) => {
  const response = await axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/api/Countries`,
    Data,
    {
      headers: {
        Authorization: `Bearer ${secret}`,
        Accept: "text/plain",
      },
    }
  );
  return response; // Return the response data from the API
};

export const deleteCountryByElectionID = async (secret, ElectionID) => {
  try {
    const response = await axios.delete(
      `${process.env.REACT_APP_API_BASE_URL}/api/Countries?electionId=${ElectionID}`,
      {
        headers: {
          Authorization: `Bearer ${secret}`,
          Accept: "text/plain",
        },
      }
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
