import React from "react";
import { Detector } from "react-detect-offline";
import { useConnection } from "../Context/ConnectionContext";

const CheckConnection = () => {
  const { setIsOnline } = useConnection();

  return (
    <Detector
      render={({ online }) => {
        setIsOnline(online);
        return <></>;
      }}
    />
  );
};

export default CheckConnection;
