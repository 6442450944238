import { useEffect, useState } from "react";
import {
  DeleteElectionsById,
  ExportElection,
  PostElection,
  UpdateElectionsById,
  getAllElections,
} from "../../services/Elections";
import { fetchToken } from "../../services/Token";
import {
  ViewIcon,
  EditIcon,
  TrashIcon,
  CheckIcon,
  EditIconDisabled,
  TrashIconDisabled,
  CheckIconDisabled,
  GreyClose,
  greenTick,
  exportgray,
  exportgreen,
} from "../../assets/Icons";
import { useNavigate } from "react-router-dom";
import { sha256 } from "js-sha256";
import { notification } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
const ItAdminElectionList = () => {
  const [token, setToken] = useState(null);
  const [localData, setLocalData] = useState(null);
  const [elections, setElections] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [itemToCheck, setItemToCheck] = useState(null);
  const [showPopUpDelete, setShowPopUpDelete] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const navigate = useNavigate();
  const name = JSON.parse(localStorage.getItem("idTokenClaims"))?.name;
  const secretKey = process.env.REACT_APP_SECRET_KEY;

  const hashValue = (value) => {
    return sha256.hmac(secretKey, value.toString());
  };
  const openShowPopUp = (item) => {
    setShowPopup(true);
    setItemToCheck(item);
    // Logic to determine showEndButton state based on your conditions
  };
  const handlePopupClose = () => {
    setShowPopup(false);
    setItemToCheck(null);
  };
  const openShowPopUpDelete = (item) => {
    setShowPopUpDelete(true);
    setItemToDelete(item);
    // Logic to determine showEndButton state based on your conditions
  };
  const handlePopupDeleteClose = () => {
    setShowPopUpDelete(false);
    setItemToDelete(null);
  };
  const handleCreateElection = async () => {
    if (!navigator.onLine) {
      notification.error({
        message: "No Internet Connection",
        description: "Please check your internet connection and try again.",
      });
      return; // Exit function if no internet connection
    }
    try {
      const response = await PostElection(token, name);
      if (response.status === 201) {
        notification.success({
          description: `Election created successfully!`,
        });
      }
      setElections((prevElections) => [...prevElections, response.data]);
      const hashedValue = hashValue("true");
      localStorage.setItem("editMode", hashedValue);
      navigate(`/editElection/${response?.data.id}?editMode=true`);
    } catch (error) {
      console.log("Please Try Again ....");
    }
  };
  const handleEditElection = (electionId) => {
    const hashedValue = hashValue("true");
    localStorage.setItem("editMode", hashedValue);
    navigate(`/editElection/${electionId}?editMode=true`);
  };

  const handleViewElection = (electionId) => {
    const hashedValue = hashValue("false");
    localStorage.setItem("editMode", hashedValue);
    navigate(`/editElection/${electionId}?editMode=false`);
  };

  const handleDeleteElection = async (token, electionId) => {
    if (!navigator.onLine) {
      notification.error({
        message: "No Internet Connection",
        description: "Please check your internet connection and try again.",
      });
      return; // Exit function if no internet connection
    }
    try {
      const response = await DeleteElectionsById(token, electionId);
      setElections((prevElections) =>
        prevElections.filter((election) => election.id !== electionId)
      );
      if (response.status === 204) {
        notification.success({
          description: `Successfully Deleted.`,
        });
        setItemToDelete(null);
        setShowPopUpDelete(false);
      }
    } catch (error) {
      console.log("Please Try Again ");
    }
  };
  const handleExport = async (token, id) => {
    if (!navigator.onLine) {
      notification.error({
        message: "No Internet Connection",
        description: "Please check your internet connection and try again.",
      });
      return; // Exit function if no internet connection
    }
    try {
      const response = await ExportElection(token, id);
      if (response.status === 200) {
        notification.success({
          description: `Successfully exported.`,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleCheckElection = async (token, item) => {
    if (!navigator.onLine) {
      notification.error({
        message: "No Internet Connection",
        description: "Please check your internet connection and try again.",
      });
      return; // Exit function if no internet connection
    }
    try {
      const statusCode = await UpdateElectionsById(token, item.id, {
        ...item,
        status: "Created",
      });
      setShowPopup(false);
      if (statusCode.status === 204) {
        // Operation successful, update the state
        setElections((prevElections) =>
          prevElections.map((election) =>
            election.id === item.id
              ? { ...election, status: "Created" }
              : election
          )
        );

        // Close the popup or perform any other necessary actions
        setShowPopup(false);
      } else {
        console.log("Unexpected status code:", statusCode);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    let idTokenClaims = JSON.parse(localStorage.getItem("idTokenClaims"));
    setLocalData(idTokenClaims);
  }, []);

  useEffect(() => {
    if (localData !== null) {
      if (!localData?.roles?.includes("Admin")) {
        navigate("/AccessDenied");
      }
      fetchToken(localData).then((result) => {
        setToken(result);
      });
    }
  }, [localData]);

  useEffect(() => {
    if (token !== null) {
      getAllElections(token).then((result) => {
        setElections(result);
      });
    }
  }, [token]);
  return (
    <div
      style={{
        backgroundColor: "",
        width: "98%",
        margin: "0 auto",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "10px",
          marginBottom: "20px",
        }}
      >
        <h4 style={{ marginRight: "10px" }}>Election</h4>

        <button
          type="button"
          className="btn btn-success"
          onClick={handleCreateElection}
        >
          Create an election
        </button>
      </div>
      {showPopup && (
        <div className="PopupConfirmStart">
          <div className="contentConfirmStart">
            {/* Display based on showEndButton state */}
            <div className="Header">
              <div className="TickIcon">{greenTick}</div>
              <button
                style={{ border: "none", backgroundColor: "#FFF" }}
                onClick={handlePopupClose}
              >
                {GreyClose}
              </button>
            </div>
            <p className="informingText">
              Are you sure you want to validate the election?
              <br />
              Please note that the election will become non-editable after
              validation
            </p>
            {/* Buttons for confirmation and cancellation */}
            <div className="abstainButtons">
              <button
                className="buttonChange"
                style={{ border: "1px solid #029455", color: "#029455" }}
                onClick={handlePopupClose}
              >
                Retourner/Go back
              </button>

              <button
                className="buttonConfirm"
                style={{ background: "#029455", color: "#FFF" }}
                onClick={() => handleCheckElection(token, itemToCheck)}
              >
                Confirmer/Confirm
              </button>
            </div>
          </div>
        </div>
      )}
      {showPopUpDelete && (
        <div className="PopupConfirmStart">
          <div className="contentConfirmStart">
            {/* Display based on showEndButton state */}
            <div className="Header">
              <div className="TickIcon">
                <DeleteOutlined style={{ fontSize: "25px", color: "green" }} />
              </div>
              <button
                style={{ border: "none", backgroundColor: "#FFF" }}
                onClick={handlePopupDeleteClose}
              >
                {GreyClose}
              </button>
            </div>
            <p className="informingText my-3">
              Are you sure you want to delete the election {itemToDelete.title}?
              <br />
              Please note that once the election is deleted, it cannot be
              recovered .
            </p>
            {/* Buttons for confirmation and cancellation */}
            <div className="abstainButtons">
              <button
                className="buttonChange"
                style={{ border: "1px solid green", color: "green" }}
                onClick={handlePopupDeleteClose}
              >
                Retourner/Go back
              </button>

              <button
                className="buttonConfirm btn btn-success"
                onClick={() => handleDeleteElection(token, itemToDelete.id)}
              >
                Confirmer/Confirm
              </button>
            </div>
          </div>
        </div>
      )}
      {elections ? (
        <div
          className="w-100"
          style={{ height: "calc(100vh - 200px)", overflow: "auto" }}
        >
          <table className="w-100 tab-election">
            <thead className="sticky">
              <tr className="HeaderTitlesPublication">
                <th style={{ padding: "1rem", textAlign: "left" }}>
                  Election name
                </th>
                <th style={{ textAlign: "center" }}>Created by</th>
                <th style={{ textAlign: "center" }}>Created on</th>

                <th style={{ textAlign: "center" }}>Start Date</th>
                <th style={{ textAlign: "center" }}>End Date</th>
                <th style={{ textAlign: "center" }}>Status</th>
                <th style={{ textAlign: "center" }}>Total of candidates</th>
                <th style={{ textAlign: "center" }}>Total of voters</th>
                <th style={{ textAlign: "center" }}>Actions</th>
              </tr>
            </thead>

            <tbody style={{ padding: "1rem" }} className="BodyTextPublication">
              {elections.map((item, index) => {
                const parsedCreatedOn = item.createdOn?.split("T")[0];
                const parsedStartDate = item.startDate?.split("T")[0];
                const parsedEndDate = item.endDate?.split("T")[0];
                return (
                  <tr
                    style={{
                      backgroundColor:
                        index % 2 === 0
                          ? "#F6F6F6"
                          : "rgba(255, 255, 255, 0.46)",
                    }}
                    key={index}
                  >
                    <td
                      style={{ padding: "1rem", textAlign: "left" }}
                      className="BodyTablePublication"
                    >
                      {item.title}
                    </td>
                    <td style={{ textAlign: "center" }}> {item.createdBy}</td>
                    <td style={{ textAlign: "center" }}> {parsedCreatedOn}</td>
                    <td style={{ textAlign: "center" }}>{parsedStartDate}</td>
                    <td style={{ textAlign: "center" }}>{parsedEndDate}</td>
                    <td
                      style={
                        item.status === "Closed"
                          ? { color: "#2D3748", textAlign: "center" }
                          : item.status === "Draft"
                          ? { color: "#DF8F16", textAlign: "center" }
                          : item.status === "Created"
                          ? { color: "#3A1EB5", textAlign: "center" }
                          : item.status === "Adjourned"
                          ? { color: "#E81414", textAlign: "center" }
                          : item.status === "Started"
                          ? { color: "#029455", textAlign: "center" }
                          : { color: "black", textAlign: "center" }
                      }
                    >
                      {item.status}
                    </td>

                    <td style={{ textAlign: "center" }}>
                      {item.candidate.length}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {item.voters.length}
                    </td>
                    <td>
                      <div
                        className="btn-actions"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <button
                          style={{ border: "none" }}
                          onClick={() => handleEditElection(item.id)}
                          disabled={item.status !== "Draft"}
                        >
                          {item.status === "Draft"
                            ? EditIcon
                            : EditIconDisabled}
                        </button>
                        <button
                          style={{ border: "none" }}
                          onClick={() => openShowPopUpDelete(item)}
                          disabled={
                            item.status == "Closed" ||
                            item.status == "Adjourned" ||
                            item.status == "Started"
                          }
                        >
                          {item.status == "Closed" ||
                          item.status == "Adjourned" ||
                          item.status == "Started"
                            ? TrashIconDisabled
                            : TrashIcon}
                        </button>
                        <button
                          style={{ border: "none" }}
                          onClick={() => handleViewElection(item.id)}
                        >
                          {ViewIcon}
                        </button>
                        <button
                          style={{ border: "none" }}
                          onClick={() => openShowPopUp(item)}
                          disabled={item.status !== "Draft"}
                        >
                          {item.status === "Draft"
                            ? CheckIcon
                            : CheckIconDisabled}
                        </button>
                        <button
                          style={{ border: "none" }}
                          onClick={() => handleExport(token, item.id)}
                          disabled={
                            item.status !== "Closed" &&
                            item.status !== "Adjourned"
                          }
                        >
                          {item.status === "Closed" ||
                          item.status === "Adjourned"
                            ? exportgreen
                            : exportgray}
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      ) : (
        <div
          className="loading"
          style={{
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            display: "flex",
            position: "absolute",
            backgroundColor: "#FAFBFC",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            class="spinner-grow"
            style={{
              width: "2rem",
              height: "2rem",
              marginRight: "5px",
              color: "green",
            }}
            role="status"
          >
            <span class="visually-hidden">Loading...</span>
          </div>
          <div
            class="spinner-grow"
            style={{
              width: "2rem",
              height: "2rem",
              marginRight: "5px",
              color: "green",
            }}
            role="status"
          >
            <span class="visually-hidden">Loading...</span>
          </div>
          <div
            class="spinner-grow"
            style={{
              width: "2rem",
              height: "2rem",
              marginRight: "5px",
              color: "green",
            }}
            role="status"
          >
            <span class="visually-hidden">Loading...</span>
          </div>

          <div
            class="spinner-grow"
            style={{
              width: "2rem",
              height: "2rem",
              marginRight: "5px",
              color: "green",
            }}
            role="status"
          >
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
    </div>
  );
};
export default ItAdminElectionList;
