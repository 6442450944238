import { useEffect, useState } from "react";
import { GeneralInformationIcon } from "../../assets/Icons";
import "./GeneralInformation.css";
import { UpdateElectionsById } from "../../services/Elections";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaCalendar } from "react-icons/fa";
import "react-time-picker/dist/TimePicker.css";
import "react-clock/dist/Clock.css";
import { notification } from "antd";
import PropTypes from "prop-types";

const CustomDatePickerInput = ({
  value = "",
  onClick = () => {},
  handleDateChange = () => {},
  selectedDate = null,
}) => (
  <button
    className="custom-datepicker-input-container"
    onClick={onClick}
    onKeyDown={(e) => {
      if (e.key === "Enter" || e.key === " ") {
        onClick(); // Allows activation with the Enter or Space key
      }
    }}
    aria-label="Select date" // Provides an accessible name for screen readers
    style={{
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
      outline: "none", // Removes the default outline when focused
      background: "none", // Removes default button background
      border: "none", // Removes default button border
    }}
  >
    <input
      type="text"
      value={value}
      className="custom-datepicker-input"
      readOnly
      aria-readonly="true" // Indicates that the input is read-only
    />
    <span className="custom-datepicker-icon">
      <FaCalendar
        aria-hidden="true" // Hides the icon from screen readers
      />
    </span>
  </button>
);

// Define PropTypes for the component
CustomDatePickerInput.propTypes = {
  value: PropTypes.string,
  onClick: PropTypes.func,
  handleDateChange: PropTypes.func,
  selectedDate: PropTypes.instanceOf(Date),
};

const GeneralInformation = ({
  electionData,
  setElectionData,
  token,
  EditModeTest,
}) => {
  const [selectedDate, setSelectedDate] = useState(
    electionData?.plannedStartDate
      ? moment(electionData.plannedStartDate).toDate()
      : null
  );
  const [selectedTime, setSelectedTime] = useState(
    electionData?.plannedStartDate
      ? moment(electionData?.plannedStartDate).format("HH:mm:ss")
      : "15:00:00"
  );
  const [titleValue, setTitleValue] = useState(electionData?.title);
  const [locationValue, setLocationValue] = useState(electionData?.location);
  const [date, setDate] = useState(null);
  const [firstTime, setFirstTime] = useState(0);

  const handleDateChange = (selectedDate) => {
    setSelectedDate(selectedDate);
    // Format the selected date in the desired format
    const formattedDate = moment(selectedDate).format("YYYY-MM-DD");
    updateElectionData(formattedDate, selectedTime);
  };

  const handleTimeChange = (event) => {
    const newTime = event.target.value;
    setSelectedTime(newTime);
    const formattedDate = moment(selectedDate).format("YYYY-MM-DD");
    updateElectionData(formattedDate, newTime);
  };

  const updateElectionData = (selectedDate, selectedTime) => {
    // Combine date and time when needed and update electionData state
    setElectionData((prevData) => ({
      ...prevData,
      plannedStartDate: selectedTime
        ? `${selectedDate}T${selectedTime}`
        : `${selectedDate}`,
    }));
  };

  const updateElectionDataTitle = (value) => {
    // Combine date and time when needed and update electionData state
    setElectionData((prevData) => ({
      ...prevData,
      title: `${value}`,
    }));
  };
  const updateElectionDataLocation = (value) => {
    // Combine date and time when needed and update electionData state
    setElectionData((prevData) => ({
      ...prevData,
      location: `${value}`,
    }));
  };
  const handleInputChangeTitle = (e) => {
    setTitleValue(e.target.value);
    updateElectionDataTitle(e.target.value);
  };
  const handleInputChangeLocation = (e) => {
    setLocationValue(e.target.value);
    updateElectionDataLocation(e.target.value);
  };
  const UpdateElection = async () => {
    if (!navigator.onLine) {
      notification.error({
        message: "No Internet Connection",
        description: "Please check your internet connection and try again.",
      });
      return; // Exit function if no internet connection
    }

    const response = await UpdateElectionsById(
      token,
      electionData.id,
      electionData
    );
    if (response.status == 204) {
      notification.success({
        description: `Data was saved successfully.`,
      });
    }
  };

  useEffect(() => {
    if (electionData !== null && firstTime === 0) {
      setSelectedDate(
        electionData?.plannedStartDate
          ? moment(electionData?.plannedStartDate).toDate()
          : null
      );
      setSelectedTime(
        electionData?.plannedStartDate &&
          moment(electionData?.plannedStartDate).format("HH:mm:ss")
      );
      setFirstTime(1);
    }
    if (electionData !== null) {
    }
  }, [electionData]);

  return (
    <div
      className=""
      style={{
        height: "100%",
        marginLeft: "2%",
        marginRight: "2%",
        backgroundColor: "#FFF",
      }}
    >
      <div
        className="px-3"
        style={{ height: "15%", display: "flex", alignItems: "center" }}
      >
        <div>{GeneralInformationIcon}</div>
        <div>
          <p
            style={{
              fontFamily: "DM Sans",
              fontSize: "23px",
              fontStyle: "normal",
              fontWeight: "700",
              lineHeight: "140%",
              marginLeft: "5px",
              color: "#2D3748",
            }}
          >
            General Information / Informations Générales
          </p>
        </div>
      </div>
      <div
        style={{
          height: "65%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        {/**premier input */}
        <div
          style={{
            height: "27%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <span style={{ marginLeft: "5%", width: "30%" }}>
            Name of the election / Nom de l'élection
          </span>
          <div
            className="input-group  "
            style={{ width: "60%", marginRight: "2%" }}
          >
            <input
              type="text"
              className="form-control"
              defaultValue={electionData?.title}
              aria-label="Username"
              aria-describedby="basic-addon1"
              onChange={handleInputChangeTitle}
              disabled={!EditModeTest}
            />
          </div>
        </div>
        {/**deuxieme input */}
        <div
          style={{
            height: "27%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <span style={{ marginLeft: "5%", width: "30%" }}>
            Scheduled start date / Date de début planifiée
          </span>
          <div
            className="input-group justify-content-between"
            style={{ width: "60%", marginRight: "2%" }}
          >
            <DatePicker
              selected={selectedDate}
              onChange={handleDateChange}
              dateFormat="yyyy/MM/dd"
              showPopperArrow={false} // Optional: Remove the popper arrow
              customInput={
                <CustomDatePickerInput
                  value={
                    selectedDate ? selectedDate.toISOString().substr(0, 10) : ""
                  }
                  onClick={() => {}}
                  handleDateChange={handleDateChange}
                  selectedDate={selectedDate}
                />
              }
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              popperPlacement="bottom-start" // Adjust the placement of the pop-up
              calendarClassName="custom-datepicker-calendar" // Apply additional styling to the calendar
              className="custom-datepicker-input" // Apply additional styling to the input
              style={{ width: "40%" }}
              disabled={!EditModeTest}
            />
            <div className="d-flex align-items-center">
              <span>Time * / Heure *</span>
            </div>

            <input
              id="appt-time"
              type="time"
              value={selectedTime}
              onChange={handleTimeChange}
              name="appt-time"
              step="1"
              pattern="[0-2][0-9]:[0-5][0-9]"
              style={{
                marginLeft: "3%",
                width: "40%",
                color: "green",
                borderRadius: "8px",
                padding: "0.3rem",
                border: "1px solid #dee2e6",
              }}
              disabled={!EditModeTest}
            />
          </div>
        </div>
        {/**3éme input */}
        <div
          style={{
            height: "27%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <span style={{ marginLeft: "5%", width: "30%" }}>Location / Lieu </span>
          <div
            className="input-group "
            style={{ width: "60%", marginRight: "2%" }}
          >
            <input
              type="text"
              className="form-control"
              placeholder=""
              aria-label="Username"
              aria-describedby="basic-addon1"
              onChange={handleInputChangeLocation}
              defaultValue={electionData?.location}
              disabled={!EditModeTest}
            />
          </div>
        </div>
        <div
          style={{ display: "flex", justifyContent: "flex-end", width: "98%" }}
        >
          {EditModeTest && (
            <button
              type="button"
              className="btn btn-success"
              style={{ width: "15%" }}
              onClick={() => UpdateElection()}
            >
              Save / Sauvegarder
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
export default GeneralInformation;
