import React, { useEffect, useState } from "react";
import { MenuOutlined, UserOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom"; // Import de Link
import "bootstrap/dist/css/bootstrap.min.css";
import { useLocation } from "react-router-dom";
import { SignOutButton } from "../../src/components/SignOutButton";
import { UnorderedListOutlined, SignalFilled } from "@ant-design/icons";
import { HomeIcon } from "../assets/Icons";

const Navbar = () => {
  const [userData, setUserData] = useState("");
  const [isPet, setIsPet] = useState(false);
  const [isSg, setIsSg] = useState(false);
  const [isChairman, setIsChairman] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  useEffect(() => {
    const user = localStorage.getItem("idTokenClaims");
    const parsedUserData = JSON.parse(user);
    setUserData(parsedUserData);
  }, []);

  useEffect(() => {
    if (userData !== null) {
      setIsPet(userData?.roles?.includes("PET"));
      setIsSg(userData?.roles?.includes("SG"));
      setIsChairman(userData?.roles?.includes("Chairman"));
    }
  }, [userData]);
  const location = useLocation();
  const currentPath = location.pathname;
  let height;

  if (isSg) {
    height = isHovered ? "10%" : "12%";
  } else {
    height = "15%";
  }
  return (
    <div
      style={{
        backgroundColor:
          currentPath === "/ElectionContent/Election_process" ||
          currentPath === "/ElectionContent/resultsRound" ||
          currentPath === "/ElectionContent/CandidateView" ||
          currentPath === "/ElectionContent/finalResults"
            ? "#DAE6B6"
            : "white",
      }}
    >
      <nav className="navbar navbar-expand-lg navbar-light ">
        <div className="container-fluid px-0">
          <div
            className="collapse d-flex navbar-collapse"
            id="navbarNavDropdown"
          >
            {(isPet == true || isSg == true || isChairman == true) && (
              <ul className="navbar-nav ml-auto">
                <li className="nav-item dropdown">
                  <a
                    className="nav-link"
                    href="#"
                    id="navbarDropdownMenuLink"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <MenuOutlined
                      style={{ color: "#029455", fontSize: "24px" }}
                    />
                  </a>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdownMenuLink"
                    style={{
                      width: "17.9375rem",
                      height: isSg
                        ? isHovered
                          ? "25rem"
                          : "19rem"
                        : isHovered
                        ? "22rem"
                        : "16rem",
                      flexShrink: 0,
                      borderRadius: "0.9375rem",
                      backgroundColor: "#FFF",
                      boxShadow: "0px 3px 19px 0px rgba(146, 164, 0,13)",
                      marginLeft: "7px",
                      marginTop: "5px",
                    }}
                  >
                    {/* Dropdown menu items */}
                    <li
                      style={{
                        height: height,
                        marginLeft: "3%",
                        marginTop: !isHovered && "2%",
                      }}
                    >
                      <div className="d-flex align-items-center">
                        <Link
                          to="/"
                          style={{
                            color: "black",
                            textDecoration: "none",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {" "}
                          {/* Lien vers /AdminOnly/Election_process */}
                          {HomeIcon}
                          <span
                            className="menu-text"
                            style={{ fontWeight: "bold", marginLeft: "1rem" }}
                          >
                            Home
                          </span>
                        </Link>
                      </div>
                    </li>
                    <li
                      style={{
                        height: isSg
                          ? isHovered
                            ? "10%"
                            : "13%"
                          : isHovered
                          ? "20%"
                          : "24%",
                        marginLeft: "3%",
                      }}
                    >
                      <div className="d-flex align-items-center">
                        <Link
                          to="/ElectionContent/Election_process"
                          style={{
                            color: "black",
                            textDecoration: "none",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {" "}
                          {/* Lien vers /AdminOnly/Election_process */}
                          <img
                            src={"../../assets/choice1.svg"}
                            alt="Logo 1"
                            style={{
                              width: "30px",
                              height: "25px",
                              marginRight: "10px",
                            }}
                          />
                          <span
                            className="menu-text"
                            style={{ fontWeight: "bold" }}
                          >
                            Processus d’election <br />
                            Election process
                          </span>
                        </Link>
                      </div>
                    </li>
                    <li
                      style={{
                        height: isHovered ? "45%" : "33%",
                        marginLeft: "3%",
                        position: "relative",
                        alignItems: "center",
                        display: "flex",
                      }}
                      onMouseEnter={() => setIsHovered(true)}
                      onMouseLeave={() => setIsHovered(false)}
                    >
                      <div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <img
                            src={"../../assets/choice2.svg"}
                            alt="Logo 1"
                            style={{
                              width: "30px",
                              height: "30px",
                              marginRight: "10px",
                            }}
                          />
                          <span style={{ fontWeight: "bold" }}>
                            Résultat des tours <br /> Round Results
                          </span>
                        </div>
                        {isHovered && (
                          <li>
                            <div style={{ marginTop: "3%", marginLeft: "10%" }}>
                              <li>
                                <Link
                                  to="/ElectionContent/resultsRound"
                                  style={{
                                    color: "black",
                                    textDecoration: "none",
                                  }}
                                  className="menu-text"
                                >
                                  <span
                                    className="menu-text"
                                    style={{ fontWeight: "bold" }}
                                  >
                                    Vue tabulaire / Table view
                                  </span>
                                </Link>
                              </li>
                            </div>
                            <div
                              style={{
                                height: "50%",
                                marginLeft: "10%",
                                marginTop: "3%",
                                marginBottom: "3%",
                              }}
                            >
                              <li>
                                <Link
                                  to="/ElectionContent/CandidateView"
                                  style={{
                                    color: "black",
                                    textDecoration: "none",
                                  }}
                                  className="menu-text"
                                >
                                  <span
                                    className="menu-text"
                                    style={{ fontWeight: "bold" }}
                                  >
                                    Vue des candidats / Candidates view
                                  </span>
                                </Link>
                              </li>
                            </div>
                          </li>
                        )}
                      </div>
                    </li>

                    <li
                      style={{ height: isSg ? "15%" : "33%", marginLeft: "3%" }}
                    >
                      <div className="d-flex align-items-center">
                        <Link
                          to="/ElectionContent/finalResults"
                          style={{ color: "black", textDecoration: "none" }}
                        >
                          {" "}
                          {/* Lien vers /AdminOnly/finalResults */}
                          <img
                            src={"../../assets/choice3.svg"}
                            alt="Logo 3"
                            style={{
                              width: "30px",
                              height: "30px",
                              marginRight: "10px",
                            }}
                          />
                          <span
                            className="menu-text"
                            style={{ fontWeight: "bold" }}
                          >
                            Résultats Finaux / Final results
                          </span>
                        </Link>
                      </div>
                    </li>
                    {isSg && (
                      <>
                        <li
                          style={{
                            height: isHovered ? "10%" : "15%",
                            marginLeft: "3%",
                          }}
                        >
                          <div className="d-flex align-items-center">
                            <Link
                              to="/SgElectionList"
                              style={{
                                color: "black",
                                textDecoration: "none",
                                display: "flex",
                              }}
                            >
                              {" "}
                              {/* Lien vers /AdminOnly/finalResults */}
                              <UnorderedListOutlined
                                style={{
                                  fontSize: "28px",
                                  marginRight: "10px",
                                }}
                              />
                              <span
                                className="menu-text"
                                style={{ fontWeight: "bold" }}
                              >
                                Export Report
                              </span>
                            </Link>
                          </div>
                        </li>
                        <li style={{ height: "15%", marginLeft: "3%" }}>
                          <div className="d-flex align-items-center">
                            <Link
                              to="/SgReturningOfficer"
                              style={{
                                color: "black",
                                textDecoration: "none",
                                display: "flex",
                              }}
                            >
                              {" "}
                              {/* Lien vers /AdminOnly/finalResults */}
                              <SignalFilled
                                style={{
                                  fontSize: "28px",
                                  marginRight: "10px",
                                }}
                              />
                              <span
                                className="menu-text"
                                style={{ fontWeight: "bold" }}
                              >
                                RO validation board
                              </span>
                            </Link>
                          </div>
                        </li>
                      </>
                    )}
                  </ul>
                </li>
              </ul>
            )}
            <img
              src="../../assets/Logo.png"
              alt="AFDBLOGO"
              style={{ height: "55px" }}
            />
          </div>
          <div className="ml-auto">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginRight: "8px",
              }}
            >
              <UserOutlined style={{ fontSize: "25px" }} />
              {userData && typeof userData === "object" && userData?.name && (
                <h6 style={{ marginLeft: "15px" }}>{userData.name}</h6>
              )}
              &nbsp; &nbsp;
              <SignOutButton />
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
