import React, { useEffect, useState } from "react";
import "./Voting.css";
import "./Publication.css";
import CircleComponent from "./CircleComponent";
import { getVoteStats } from "../../../services/Stats";
import { createNewRound, getLastRound } from "../../../services/Rounds";
import {
  GreyClose,
  alertMessageIcon,
  greenDone,
  greenTick,
} from "../../../assets/Icons";
import { getViewCandidates } from "../../../services/Candidate";
import { adjournElection, closeElection } from "../../../services/Elections";
import { useNavigate } from "react-router-dom";
import { notification } from "antd";
import createWebSocketConnectionVotes from "../../../services/WebSocketVotesService";

const PublicationComponent = ({
  token,
  setState,
  electionId,
  localData,
  setRoundData,
  setRoundId,
  roundId,
  hasAccess,
}) => {
  const [stat, setStat] = useState(null);
  const [roundNumber, setRoundNumber] = useState(null);
  const [showPopupStart, setshowPopupStart] = useState(false);
  const [showPopupAdjourn, setshowPopupAdjourn] = useState(false);
  const [showPopupPublish, setshowPopupPublish] = useState(false);
  const [candidatesPerRound, setCandidatesPerRound] = useState(null);
  const [alertMessage, setAlertMessage] = useState(false);
  const [hasWinner, setHasWinner] = useState(false);
  const [loading, setLoading] = useState(true);
  const [winnerDetails, setWinnerDetails] = useState(null);
  const [disableStartRallCallButton, setDisableStartRallCallButton] =
    useState(false);

  const navigate = useNavigate();

  const handleButtonCreateNewRound = () => {
    setshowPopupStart(true);
  };

  const handleButtonAdjourn = () => {
    setshowPopupAdjourn(true);
  };

  const handleButtonConfirmCreateNewRound = async () => {
    setDisableStartRallCallButton(true);
    // Check internet connectivity
    if (!navigator.onLine) {
      notification.error({
        message: "No Internet Connection",
        description: "Please check your internet connection and try again.",
      });
      setDisableStartRallCallButton(false);
      return; // Exit function if no internet connection
    }

    const response = await createNewRound(token, electionId);
    if (response.status == 201) {
      setDisableStartRallCallButton(false);
      await getLastRound(token, electionId).then(
        (result) => (setRoundId(result?.id), setRoundData(result))
      );
      setState(0);
      setshowPopupStart(false);
    } else {
      setDisableStartRallCallButton(false);
      notification.error({
        description: "Error",
      });
    }
  };

  const handleButtonConfirmAdjourn = async () => {
    // Check internet connectivity
    if (!navigator.onLine) {
      notification.error({
        message: "No Internet Connection",
        description: "Please check your internet connection and try again.",
      });
      return; // Exit function if no internet connection
    }

    setshowPopupAdjourn(false);
    const response = await adjournElection(token, electionId);
    if (response.status === 204) {
      navigate("/ElectionContent/Adjournment");
    } else {
      notification.error({
        description: "Error",
      });
    }
  };

  const handleButtonPublish = () => {
    setshowPopupPublish(true);
  };

  const handleButtonConfirmPublish = async () => {
    setDisableStartRallCallButton(true);
    // Check internet connectivity
    if (!navigator.onLine) {
      notification.error({
        message: "No Internet Connection",
        description: "Please check your internet connection and try again.",
      });
      setDisableStartRallCallButton(false);
      return; // Exit function if no internet connection
    }
    const response = await closeElection(token, electionId, "Closed");
    if (response.status == 204) {
      setDisableStartRallCallButton(false);
      setshowPopupPublish(false);
      navigate("/ElectionContent/finalResults");
    } else {
      setDisableStartRallCallButton(false);
    }
  };

  const handlePopupClose = () => {
    setshowPopupStart(false);
  };
  const handlePopupCloseAdjourn = () => {
    setshowPopupAdjourn(false);
  };
  const handlePopupClosePublish = () => {
    setshowPopupPublish(false);
  };

  useEffect(() => {
    const fetchRoundId = async () => {
      try {
        if (token !== null && electionId !== null) {
          const result = await getLastRound(token, electionId);
          setRoundId(result?.id);
          setRoundNumber(result?.number);
          if (result.number >= 5) {
            setAlertMessage(true);
          }
        }
      } catch (error) {
        console.error("Error fetching roundId:", error);
      }
    };
    fetchRoundId();
  }, [token, electionId]);

  useEffect(() => {
    if (roundId !== null) {
      getViewCandidates(token, roundId).then((result) => {
        // Check if there is a winner
        const winnerExists = result?.some((item) => item.status === "Winner");

        // Update the state directly for hasWinner
        setHasWinner(winnerExists);

        if (winnerExists) {
          const winnerDetails = result?.find(
            (item) => item.status === "Winner"
          );
          setWinnerDetails(winnerDetails);
        }

        // Update the state for candidatesPerRound
        setCandidatesPerRound(result);
        if (winnerExists) {
          setAlertMessage(false);
        }
      });
      setLoading(false);
    }
  }, [roundId]);

  useEffect(() => {
    const getStats = async () => {
      try {
        if (token !== null && electionId !== null && roundId !== null) {
          const result = await getVoteStats(token, electionId, roundId);
          setStat(result);
        }
      } catch (error) {
        console.error("Error fetching vote statistics:", error);
      }
    };
    getStats();
  }, [token, roundId, electionId]);
  useEffect(() => {
    if (token !== null) {
      const socket = createWebSocketConnectionVotes();
      socket.onopen = () => {
        // Send the initial message to start receiving data
        const initialMessage =
          JSON.stringify({ protocol: "json", version: 1 }) +
          String.fromCharCode(0x1e);
        socket.send(initialMessage);
      };
      socket.onmessage = async (event) => {
        const rawData = event.data;
        const messages = rawData
          .split(String.fromCharCode(0x1e))
          .filter(Boolean);
        if (messages.length > 1) {
          const parsedData = JSON.parse(messages[1]);
          if (parsedData.target === "SendStateProcess") {
            setState(parsedData.arguments[0]);
            if (parsedData.arguments[0] == 0) {
              await getLastRound(token, electionId).then(
                (result) => (setRoundId(result?.id), setRoundData(result))
              );
            }
          }
        } else if (messages.length === 1) {
          const data = messages[0];
          const parsedData = JSON.parse(data);
          const typevalue = parsedData.type;

          if (typevalue === 1) {
            if (parsedData.target === "SendStateProcess") {
              setState(parsedData.arguments[0]);
              if (parsedData.arguments[0] == 0) {
                await getLastRound(token, electionId).then(
                  (result) => (setRoundId(result?.id), setRoundData(result))
                );
              }
            }
          }
        }
      };
    }
  }, [token]);
  const formatPercentage = (percentage) => {
    const formattedPercentage = parseFloat(percentage).toFixed(2);
    return formattedPercentage;
  };

  return (
    <div>
      <>
        {!loading ? (
          <div
            className="row"
            style={{ backgroundColor: "#DAE6B6", height: "80vh" }}
          >
            {/* Table Component */}
            <div
              className="col-9"
              style={{
                height: "fit-content",

                padding: "0.5rem",

                backgroundColor: "#DAE6B6",
                borderRadius: "1.25rem",
                border: "3px solid white",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "10px",
                  marginBottom: "20px",
                  padding: "0.5rem",
                }}
              >
                <h4 className="VotingTitle">Publication/Publication</h4>
                {localData && (
                  <div>
                    {hasWinner ? (
                      <button
                        className={
                          localData?.roles[0] === "Chairman" || !hasAccess
                            ? "disabledButton"
                            : "CreateRoundButton"
                        }
                        onClick={handleButtonPublish}
                        disabled={
                          localData?.roles[0] === "Chairman" || !hasAccess
                        }
                      >
                        Publier les résultats du tour/ Publish Final Results
                      </button>
                    ) : (
                      <div style={{ display: "flex" }}>
                        {alertMessage && (
                          <button
                            className={
                              localData?.roles[0] === "Chairman" || !hasAccess
                                ? "disabledButton"
                                : "AdjournButton"
                            }
                            onClick={handleButtonAdjourn}
                            style={{
                              marginRight: "5px",
                              paddingRight: "1rem",
                              paddingLeft: "1rem",
                            }}
                            disabled={
                              localData?.roles[0] === "Chairman" || !hasAccess
                            }
                          >
                            Ajourner/Adjourn
                          </button>
                        )}
                        <button
                          className={
                            localData?.roles[0] === "Chairman" || !hasAccess
                              ? "disabledButton"
                              : "CreateRoundButton"
                          }
                          onClick={handleButtonCreateNewRound}
                          disabled={
                            localData?.roles[0] === "Chairman" || !hasAccess
                          }
                        >
                          Créer / Create a new Round
                        </button>
                      </div>
                    )}
                  </div>
                )}
              </div>
              <div
                className="w-100"
                style={{ overflowY: "auto", height: "calc(100vh - 323px)" }}
              >
                {alertMessage && !hasWinner && (
                  <div className="alertBox">
                    <span style={{ paddingRight: "15px" }}>
                      {alertMessageIcon}
                    </span>
                    <span>
                      <p className="alertP">
                        Veuillez noter que vous avez atteint 5 tours, vous
                        pouvez ajourner l'élection/ Please note you have reached
                        5 rounds you may adjourn the election
                      </p>
                    </span>
                  </div>
                )}
                {hasWinner && (
                  <div className="winnerBox">
                    <p className="winnerP">
                      {greenDone} Double majorité : Vous avez un gagnant !
                      /Double Majority: You have a winner!
                    </p>
                  </div>
                )}
                <table className="w-100">
                  <thead>
                    <tr className="HeaderTitlesPublication">
                      <th style={{ padding: "1rem" }}>
                        N/S
                        <br />
                        S/N
                      </th>
                      <th>
                        Candidat
                        <br />
                        Candidate
                      </th>
                      <th>
                        Pays
                        <br />
                        Country
                      </th>
                      <th>
                        Total des pouvoirs de vote
                        <br />
                        Total of voting power
                      </th>
                      <th>
                        Total des pouvoirs de vote régionaux
                        <br />
                        Total of regional voting power
                      </th>
                      <th>
                        Statut
                        <br />
                        Status
                      </th>
                    </tr>
                  </thead>
                  {candidatesPerRound ? (
                    <tbody
                      style={{ padding: "1rem" }}
                      className="BodyTextPublication"
                    >
                      {candidatesPerRound?.map((item, index) => {
                        return (
                          <tr
                            key={index}
                            style={{
                              backgroundColor:
                                index === 0 && hasWinner
                                  ? "#029455"
                                  : index % 2 === 0
                                  ? "#F6F6F6"
                                  : "rgba(255, 255, 255, 0.46)",
                              color:
                                index === 0 && hasWinner
                                  ? "#FFFFFF"
                                  : "var(--Gray-Gray-700, #2D3748)",
                            }}
                            className="BodyTablePublication"
                          >
                            <td style={{ padding: "1rem" }}>{item.sn}</td>
                            <td>
                              {item.firstName} {item.lastName}
                            </td>
                            <td>{item.country}</td>
                            <td>{formatPercentage(item.total_VP)} %</td>
                            <td>{formatPercentage(item.total_RVP)} %</td>
                            <td>
                              {item.status === "Winner"
                                ? "Double majorité!/Double majority"
                                : item.status === "Eliminated"
                                ? "Eliminé/Eliminated"
                                : item.status === "Qualified"
                                ? "Qualifié/Qualified"
                                : ""}
                            </td>{" "}
                          </tr>
                        );
                      })}
                    </tbody>
                  ) : (
                    <div className="container-fluid" style={{ height: "80vh" }}>
                      <div
                        className="spinner-border text-success"
                        role="status"
                        style={{
                          marginLeft: "100%",
                          marginTop: "30%",
                          width: "70px",
                          height: "70px",
                        }}
                      ></div>
                    </div>
                  )}
                </table>
              </div>
            </div>

            {/* STATS COMPONENT */}
            <div className="col-3" style={{ height: "90%" }}>
              {/* COMPONENT 1 */}
              <div
                style={{
                  marginBottom: "4%",
                  borderRadius: "1.25rem",
                  paddingRight: "0.75rem",
                  paddingLeft: "0.75rem",
                  backgroundColor: "#DAE6B6",
                  border: "3px solid white",
                }}
              >
                <div>
                  <p className="StatTitle">Bulletin Null/ Void Ballot</p>
                </div>
                <div
                  className="new-space"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <CircleComponent
                    number={
                      stat ? formatPercentage(stat.voidBallots) + "%" : "0.00%"
                    }
                  />
                </div>
              </div>

              {/* COMPONENT 2 */}
              <div
                style={{
                  marginBottom: "4%",
                  borderRadius: "1.25rem",
                  paddingRight: "0.75rem",
                  paddingLeft: "0.75rem",
                  backgroundColor: "#DAE6B6",
                  border: "3px solid white",
                }}
              >
                <div>
                  <p className="StatTitle">
                    Pourcentage du total des votes exprimés / Percentage of
                    Total votes cast
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <CircleComponent
                    pourcentage={
                      stat
                        ? formatPercentage(stat.pourcentageVotingPower) + "%"
                        : "0.00%"
                    }
                  />
                </div>
              </div>

              {/* COMPONENT 3 */}
              <div
                style={{
                  borderRadius: "1.25rem",
                  paddingRight: "0.75rem",
                  paddingLeft: "0.75rem",
                  backgroundColor: "#DAE6B6",
                  border: "3px solid white",
                }}
              >
                <div>
                  <p className="StatTitle">
                    Pourcentage des votes régionaux / Percentage of Regional
                    votes
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <CircleComponent
                    pourcentage={
                      stat
                        ? formatPercentage(stat.pourcentageRegional) + "%"
                        : "0.00%"
                    }
                  />
                </div>
              </div>
              {/* COMPONENT 4 */}
              <div
                style={{
                  borderRadius: "1.25rem",
                  paddingRight: "0.75rem",
                  paddingLeft: "0.75rem",
                  backgroundColor: "#DAE6B6",
                  border: "3px solid white",
                  marginTop: "2%",
                }}
              >
                <div>
                  <p className="StatTitle">
                    Pourcentage du total des abstentions / Percentage of Total
                    abstention{" "}
                  </p>
                </div>
                <div
                  style={{
                    height: "80%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <CircleComponent
                    pourcentage={
                      stat
                        ? formatPercentage(stat.pourcentageAbstention) + "%"
                        : "0.00%"
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className="container-fluid text-center">
              <div
                className="w-100 d-flex  flex-column justify-content-center align-items-center"
                style={{ overflow: "hidden", height: "100vh" }}
              >
                <div className="w-100 d-flex flex-column justify-content-center align-items-center">
                  <div
                    className="spinner-border text-success"
                    role="status"
                    style={{ width: "100px", height: "100px" }}
                  ></div>
                  <p
                    style={{
                      fontFamily: "DM Sans",
                      fontSize: "1.7rem",
                      fontStyle: "normal",
                      fontWeight: "700",
                      lineHeight: "normal",
                      marginTop: "10px",
                    }}
                  >
                    Veuillez patienter / Please wait
                  </p>
                </div>
              </div>
            </div>
          </>
        )}
      </>

      {showPopupStart && (
        <div className="PopupConfirmStart">
          <div className="contentConfirmStart">
            <div>
              <div className="Header">
                <div className="TickIcon">{greenTick}</div>
                <button
                  style={{ border: "none", backgroundColor: "#FFF" }}
                  onClick={handlePopupClose}
                >
                  {GreyClose}
                </button>
              </div>
              <div className="Text">
                <div>
                  <div>
                    <h6>Confirmation/Confirmation</h6>
                  </div>
                  <div>
                    <p className="informingText">
                      Voulez-vous confirmer la création du tour N:{" "}
                      {roundNumber + 1}
                      <br />
                      Would you please confrm the creation of Round N:{" "}
                      {roundNumber + 1}
                    </p>
                  </div>
                </div>
              </div>
              <div className="abstainButtons">
                <button
                  className="buttonChange"
                  style={{ border: "1px solid #029455", color: "#029455" }}
                  onClick={handlePopupClose}
                >
                  Retourner/Go back
                </button>
                <button
                  className="buttonConfirm"
                  style={{ background: "#029455", color: "#FFF" }}
                  disabled={disableStartRallCallButton}
                  onClick={handleButtonConfirmCreateNewRound}
                >
                  Confirmer/Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {showPopupAdjourn && (
        <div className="PopupConfirmStart">
          <div className="contentConfirmStart">
            <div>
              <div className="Header">
                <div className="TickIcon">{greenTick}</div>
                <button
                  style={{ border: "none", backgroundColor: "#FFF" }}
                  onClick={handlePopupCloseAdjourn}
                >
                  {GreyClose}
                </button>
              </div>
              <div className="Text">
                <div>
                  <div>
                    <h6>Ajourner l'élection/ Adjourn Election</h6>
                  </div>
                  <div>
                    <p className="informingText">
                      Vous ajournez l'élection, merci de confirmer
                      <br />
                      You are adjourning the election, please confirm
                    </p>
                  </div>
                </div>
              </div>
              <div className="abstainButtons">
                <button
                  className="buttonChange"
                  style={{ border: "1px solid #029455", color: "#029455" }}
                  onClick={handlePopupCloseAdjourn}
                >
                  Retourner/Go back
                </button>
                <button
                  className="buttonConfirm"
                  style={{ background: "#029455", color: "#FFF" }}
                  onClick={handleButtonConfirmAdjourn}
                >
                  Ajourner/Adjourn
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {showPopupPublish && (
        <div className="PopupConfirmStart">
          <div className="contentConfirmStart">
            <div>
              <div className="Header">
                <div className="TickIcon">{greenTick}</div>
                <button
                  style={{ border: "none", backgroundColor: "#FFF" }}
                  onClick={handlePopupClosePublish}
                >
                  {GreyClose}
                </button>
              </div>
              <div className="Text">
                <div>
                  <div>
                    <h6>Publication des résultats/ Publication of results</h6>
                  </div>
                  <div>
                    <p className="informingText">
                      Si vous choisissez de publier le résultat final,
                      l'élection sera clôturée
                      <br />
                      If you choose to publish the final result, the election
                      will be closed.
                    </p>
                  </div>
                </div>
              </div>
              <div className="abstainButtons">
                <button
                  className="buttonConfirm"
                  style={{ background: "#029455", color: "#FFF" }}
                  onClick={handleButtonConfirmPublish}
                  disabled={disableStartRallCallButton}
                >
                  Publier/Publish
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PublicationComponent;
