import axios from "axios";

export const getCurrentElectionYear = async (secret, electionId) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/api/Elections/GetCurrentElectionYear?electionID=${electionId}`,
      {
        headers: {
          Authorization: `Bearer ${secret}`,
          Accept: "text/plain",
        },
      }
    );
    return response.data;
  } catch (error) {
    //  console.error("Error fetching data:", error);
  }
};

export const getCurrentElectionID = async (secret) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/api/Elections/GetElectionByStatus?status=Started`,
      {
        headers: {
          Authorization: `Bearer ${secret}`,
          Accept: "text/plain",
        },
      }
    );
    if (response.data) {
      // console.log("ongoing election", response.data);
      return response.data;
    } else {
      // console.log("No current election found.");
      return null;
    }
  } catch (error) {
    // console.error("Error fetching data:", error);
  }
};

export const getLastClosedElection = async (secret) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/api/Elections/GetElectionByStatus?status=Closed`,
      {
        headers: {
          Authorization: `Bearer ${secret}`,
          Accept: "text/plain",
        },
      }
    );
    return response.data;
  } catch (error) {
    // console.error("Error fetching data:", error);
  }
};

export const getAllElections = async (secret) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/api/Elections`,
      {
        headers: {
          Authorization: `Bearer ${secret}`,
          Accept: "text/plain",
        },
      }
    );
    return response.data;
  } catch (error) {
    //  console.error("Error fetching data:", error);
  }
};

export const DeleteElectionsById = async (token, electionId) => {
  try {
    const response = await axios.delete(
      `${process.env.REACT_APP_API_BASE_URL}/api/Elections/${electionId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
      }
    );
    return response;
  } catch (error) {
    //  console.error("Error deleting election:", error);
    throw error;
  }
};

export const PostElection = async (secret, name) => {
  const ElectionDefault = {
    title: "DefaultName",
    createdOn: null,
    createdBy: name,
    status: "Draft",
    applicationLog: [],
    candidate: [],
    country: [],
    round: [],
    settings: [],
    usersLogIn: [],
    voters: [],
    votingPower: [],
  };
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/api/Elections`,
      ElectionDefault, // Pass ElectionDefault as the request body
      {
        headers: {
          Authorization: `Bearer ${secret}`,
          Accept: "text/plain",
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

export const UpdateElectionsById = async (token, electionId, itemElection) => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_API_BASE_URL}/api/Elections/${electionId}`,
      itemElection,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
      }
    );
    return response;
  } catch (error) {
    //   console.error("Error deleting election:", error);
    throw error;
  }
};

export const getElectionById = async (secret, electionId) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/api/Elections/${electionId}`,
      {
        headers: {
          Authorization: `Bearer ${secret}`,
          Accept: "text/plain",
        },
      }
    );
    return response.data;
  } catch (error) {
    // console.error("Error fetching data:", error);
  }
};

export const adjournElection = async (secret, electionId) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/api/Elections/UpdateStatusElection?id=${electionId}&status=Adjourned`,
      {
        headers: {
          Authorization: `Bearer ${secret}`,
          Accept: "application/json",
        },
      }
    );
    return response;
  } catch (error) {
    //  console.error("Error adjourning election:", error);
    throw error;
  }
};

export const startElection = async (secret, electionId) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/api/Elections/StartElection?id=${electionId}`,
      {
        headers: {
          Authorization: `Bearer ${secret}`,
          Accept: "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    //  console.error("Error starting election:", error);
    throw error;
  }
};

export const closeElection = async (secret, electionId, status) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/api/Elections/UpdateStatusElection?id=${electionId}&status=${status}`,
      {
        headers: {
          Authorization: `Bearer ${secret}`,
          Accept: "application/json",
        },
      }
    );
    return response;
  } catch (error) {
    // console.error("Error closing election:", error);
    throw error;
  }
};

export const getElectionByStatus = async (secret, status) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/api/Elections/GetElectionByStatus?status=${status}`,
      {
        headers: {
          Authorization: `Bearer ${secret}`,
          Accept: "text/plain",
        },
      }
    );
    if (response.status === 200) {
      return response.data;
    } else if (response.status === 404) {
      return null;
    }
  } catch (error) {
    //  console.error("Error fetching data:", error);
  }
};

export const ExportElection = async (secret, id) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/api/Report/ExportReportAdmin?electionID=${id}`,
      {
        headers: {
          Authorization: `Bearer ${secret}`,
          Accept: "text/plain",
        },
      }
    );
    return response;
  } catch (error) {
    //  console.error("Error fetching data:", error);
  }
};
export const ExportElectionSG = async (secret, id) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/api/Report/ExportReportSG?electionID=${id}`,
      {
        headers: {
          Authorization: `Bearer ${secret}`,
          Accept: "text/plain",
        },
      }
    );
    return response;
  } catch (error) {
    //  console.error("Error fetching data:", error);
  }
};
