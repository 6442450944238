import React, { useEffect } from "react";
import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import ReactCountryFlag from "react-country-flag";
import { Card } from "react-bootstrap";
import { useState } from "react";
import { getCandidateData } from "../services/Candidate";
import { getCountryCode } from "../services/GetCountryCode";
import "./CardDisplay.css";
import { GreyClose, abstainIcon, greenTick } from "../assets/Icons";
import { addVote } from "../services/Votes";
import { Banner } from "./Banner";
import { SignOutButton } from "./SignOutButton";
import { notification } from "antd";
import DOMPurify from "dompurify";

function CenteredCardComponent({
  clientInfo,
  votingPower,
  roundId,
  countryStatus,
  voterData,
  currentYear,
  roundData,
  loading,
}) {
  const [candidatesData, setCandidatesData] = useState(null);
  const [loadingList, setLoadingList] = useState(null);
  useEffect(() => {
    if (!loading) {
      setLoadingList(true);
    }
  }, []);

  useEffect(() => {
    if (roundId > 0) {
      getCandidateData(clientInfo, roundId).then((result) => {
        setCandidatesData(result);
      });
    }
  }, [roundId]);

  useEffect(() => {
    if (candidatesData !== null) {
      setLoadingList(false);
    }
  }, [candidatesData]);

  const [selectedItem, setSelectedItem] = useState(null);
  const [abstain, setAbstain] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [vote, setVote] = useState(false);
  const [abstainvote, setAbstainvote] = useState(false);

  const handleCardClick = async (item) => {
    // Uncheck the noBody checkbox when a card is selected
    setAbstain(false);

    // Toggle the selection of the item
    setSelectedItem((prevSelectedItem) =>
      prevSelectedItem && prevSelectedItem.id === item.id ? null : item
    );
  };

  const handleSubmitVote = async () => {
    try {
      const response = await addVote(
        clientInfo,
        roundId,
        voterData,
        selectedItem.id
      );
      if (response.status == 200) {
        setVote(true);
        setShowPopup(false);
      } else if (response.name == "AxiosError") {
        notification.error({
          placement: "topRight",
          description: response.message,
          duration: 3,
        });
      }
    } catch (error) {}
  };

  const handleAbstainVote = async () => {
    try {
      // Call your API function here
      const result = await addVote(clientInfo, roundId, voterData);
      if (result.status == 200) {
        setAbstainvote(true);
        setShowPopup(false);
      } else if (result.name == "AxiosError") {
        notification.error({
          placement: "topRight",
          description: result.message,
          duration: 3,
        });
      }
    } catch (error) {
      console.error("Error confirming:", error);
    }
  };

  const handleAbstainChange = () => {
    // Unselect the card when the checkbox is checked
    if (selectedItem) {
      setSelectedItem(null);
    }
    // Toggle the state of the noBody checkbox
    setAbstain(!abstain);
  };

  const handleConfirmButtonClick = () => {
    setShowPopup(true);
  };

  const handlePopupClose = () => {
    setShowPopup(false);
  };

  let columnStyle = {};
  let contentStyle = {};
  let contentStyleSelected = {
    borderRadius: "8px",
    boxShadow: "1px -2px 19px 0px rgba(45, 50, 62, 0.44)",
  };

  if (candidatesData && candidatesData.length <= 4) {
    columnStyle = {
      width: "50%",
      margin: "auto",
    };

    contentStyleSelected = {
      borderRadius: "8px",
      boxShadow: "1px -2px 19px 0px rgba(45, 50, 62, 0.44)",
      backgroundColor: "black",
    };
  }
  if (
    candidatesData &&
    candidatesData.length > 4 &&
    candidatesData.length <= 9
  ) {
    columnStyle = {
      width: "33%",
    };

    contentStyleSelected = {
      borderRadius: "8px",
      boxShadow: "1px -2px 19px 0px rgba(45, 50, 62, 0.44)",
      backgroundColor: "black",
    };
  }
  if (
    candidatesData &&
    candidatesData.length > 9 &&
    candidatesData.length <= 12
  ) {
    columnStyle = {
      width: "25%",
    };

    contentStyleSelected = {
      borderRadius: "8px",
      boxShadow: "1px -2px 19px 0px rgba(45, 50, 62, 0.44)",
      backgroundColor: "black",
    };
  }
  if (candidatesData && candidatesData.length > 12) {
    columnStyle = {
      width: "20%",
    };
  }

  const splitDataIntoRows = (data) => {
    const rows = [];
    let numColumns;
    candidatesData && candidatesData.length < 2
      ? (numColumns = 1)
      : candidatesData && candidatesData.length <= 4
      ? (numColumns = 2)
      : candidatesData.length <= 9
      ? (numColumns = 3)
      : candidatesData.length <= 12
      ? (numColumns = 4)
      : (numColumns = 5);
    for (let i = 0; i < candidatesData.length; i += numColumns) {
      const row = data.slice(i, i + numColumns);
      while (row.length < numColumns) {
        row.push(null);
      }
      rows.push(row);
    }
    return rows;
  };

  function formatToThreeDecimals(number) {
    // Ensure the number is treated as a string to properly split it later
    let numberReceived = number?.toString();
    let integerPart;
    let decimalPart;

    // Split the number into integer and decimal parts
    if (numberReceived?.includes(".")) {
      [integerPart, decimalPart] = numberReceived?.split(".");
    } else {
      integerPart = numberReceived;
      decimalPart = "";
    }

    // Format the decimal part to ensure it has exactly three digits
    if (decimalPart.length === 0) {
      decimalPart = "000";
    } else if (decimalPart.length < 3) {
      decimalPart = decimalPart.padEnd(3, "0");
    } else if (decimalPart.length > 3) {
      decimalPart = decimalPart.slice(0, 3);
    }

    return `${integerPart}.${decimalPart}`;
  }

  return (
    <>
      {!loadingList ? (
        <>
          {!vote && !abstainvote ? (
            <>
              <Banner
                roundId={roundId}
                currentYear={currentYear}
                roundData={roundData}
              />
              <div
                className="card-bloc d-flex  justify-content-center"
                style={{
                  height: "calc(100vh - 177px)",
                  flexDirection: "column",
                  overflow: "hidden",
                }}
              >
                {candidatesData &&
                  splitDataIntoRows(candidatesData).map((row, rowIndex) => (
                    <div className="row px-2" key={rowIndex}>
                      {row.map((item, columnIndex) => (
                        <div
                          className="p-1 card-item"
                          style={columnStyle}
                          key={columnIndex}
                        >
                          {item && (
                            <Card
                              onClick={() => handleCardClick(item)}
                              className={
                                candidatesData.length < 2
                                  ? "card-for1 mx-auto"
                                  : candidatesData.length <= 2
                                  ? "card-for2"
                                  : candidatesData.length <= 4
                                  ? "card-for4"
                                  : candidatesData.length <= 6
                                  ? "card-for3"
                                  : candidatesData.length <= 9
                                  ? "card-for3-2"
                                  : candidatesData.length <= 12
                                  ? "card-for12"
                                  : candidatesData.length <= 15
                                  ? "card-for5"
                                  : candidatesData.length <= 20
                                  ? "card-for5 resizeimg1"
                                  : candidatesData.length <= 25
                                  ? "card-for5 resizeimg2"
                                  : "card-for5 resizeimgfinal"
                              }
                              style={
                                selectedItem && selectedItem.id === item.id
                                  ? contentStyleSelected
                                  : contentStyle
                              }
                            >
                              <span
                                className="background-image"
                                style={{
                                  borderRadius: "8px 8px 0 0",
                                  height: "100%",
                                  backgroundImage: DOMPurify.sanitize(
                                    `url(${item.urlPhoto}?${process.env.REACT_APP_SIGNATURE})`
                                  ),
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "cover",
                                  backgroundPosition: " center",
                                  width: "100%",
                                }}
                              />
                              <Card.Img
                                variant="top"
                                src={DOMPurify.sanitize(
                                  `url(${item.urlPhoto}?${process.env.REACT_APP_SIGNATURE})`
                                )}
                                alt={item.name}
                                style={{
                                  visibility: "hidden",
                                  height: 0,
                                  width: 0,
                                }}
                              />
                              <Card.Body
                                style={{
                                  backgroundColor: "white",
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  padding:
                                    candidatesData.length <= 7
                                      ? "1rem"
                                      : "0.2rem",
                                }}
                              >
                                <Card.Title
                                  style={{
                                    fontSize:
                                      candidatesData.length < 6
                                        ? "17px"
                                        : candidatesData.length >= 6 &&
                                          candidatesData.length < 9
                                        ? "24px"
                                        : "10px",
                                    // Center the title text
                                    textAlign: "center",
                                  }}
                                >
                                  {item.firstName} {item.lastName}
                                </Card.Title>
                                <div className="d-flex align-items-center ">
                                  {" "}
                                  {/* Flexbox for centered alignment */}
                                  <ReactCountryFlag
                                    countryCode={getCountryCode(item.country)}
                                    svg
                                    style={{
                                      width: "42px",

                                      marginRight: "0.5rem",
                                    }}
                                  />
                                  <Card.Text
                                    style={{
                                      fontSize: "18px",
                                      fontWeight: "400",
                                      margin: "0",
                                    }}
                                  >
                                    {" "}
                                    {item.country}
                                  </Card.Text>
                                </div>
                              </Card.Body>
                            </Card>
                          )}
                        </div>
                      ))}
                    </div>
                  ))}
                <div
                  className="footer-action"
                  style={{
                    position: "absolute",
                    bottom: "0px",
                    padding: "0.6rem 0.2rem",
                    width: "100%",
                  }}
                >
                  <div className="abstainCheckbox">
                    <label className="abstainCheckboxText">
                      <input
                        className="abstainCheckboxTick"
                        type="checkbox"
                        checked={abstain}
                        onChange={handleAbstainChange}
                      />
                      Je m'abstiens / I abstain
                    </label>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <h1
                      style={{
                        color: "#2B3674",
                        fontFamily: "DM Sans",
                        fontSize: "1.0625rem",
                        fontWeight: "700",
                        marginBottom: "0",
                        letterSpacing: "0.03125rem",
                      }}
                    >
                      Pouvoir de vote/ Voting Power:{" "}
                      {formatToThreeDecimals(votingPower)} %
                    </h1>
                    <div>
                      <button
                        className={
                          selectedItem || abstain
                            ? "buttonActive"
                            : "buttondisabeled"
                        }
                        disabled={!selectedItem && !abstain}
                        onClick={handleConfirmButtonClick}
                      >
                        Confirmer/Confirm
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {showPopup && (
                <div className="PopupConfirm">
                  <div className="contentConfirm">
                    {abstain ? (
                      <div className="contentAbstain">
                        <div className="Header">
                          <div>{abstainIcon}</div>
                          <button
                            style={{ border: "none", backgroundColor: "#FFF" }}
                            onClick={handlePopupClose}
                          >
                            {GreyClose}
                          </button>
                        </div>
                        <div className="Text">
                          <h6>Je m'abstiens / I abstain</h6>
                          <p>
                            Vous vous abstenez de voter / You are abstaining
                            from voting
                          </p>
                        </div>
                        <div className="abstainButtons">
                          <button
                            className="buttonChange"
                            style={{
                              border:
                                countryStatus === true
                                  ? "1px solid #FFD262"
                                  : "1px solid #029455",
                              color:
                                countryStatus === true ? "#464E5F" : "#029455",
                            }}
                            onClick={handlePopupClose}
                          >
                            Changer le vote/Change vote
                          </button>
                          <button
                            className="buttonConfirm"
                            style={{
                              background:
                                countryStatus === true ? "#FFD262" : "#029455",
                              color:
                                countryStatus === true ? "#464E5F" : "#FFF",
                            }}
                            onClick={handleAbstainVote}
                          >
                            Confirmer/Confirm
                          </button>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <div className="Header">
                          <div className="TickIcon">{greenTick}</div>
                          <button
                            style={{ border: "none", backgroundColor: "#FFF" }}
                            onClick={handlePopupClose}
                          >
                            {GreyClose}
                          </button>
                        </div>
                        <div className="Text">
                          <div>
                            <div>
                              <h6>Confirmation</h6>
                            </div>
                            <div>
                              <p className="informingText">
                                Vous votez pour / You are voting for :
                              </p>
                            </div>
                          </div>
                          <div
                            className="candidateDetails"
                            style={{
                              border:
                                countryStatus === true
                                  ? "1px solid #FFD262"
                                  : "1px solid #029455",
                            }}
                          >
                            <div
                              style={{
                                width: "15%",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <div className="Image">
                                <img
                                  src={DOMPurify.sanitize(
                                    `${selectedItem.urlPhoto}?${process.env.REACT_APP_SIGNATURE}`
                                  )}
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "cover",
                                  }}
                                />
                              </div>
                            </div>
                            <div className="candidateNameAndCountry">
                              <p className="candidateName">
                                {" "}
                                {selectedItem.firstName} {selectedItem.lastName}{" "}
                              </p>
                              <div className="candidateCountry">
                                <span>
                                  <ReactCountryFlag
                                    countryCode={getCountryCode(
                                      selectedItem.country
                                    )}
                                    svg
                                    style={{
                                      width: "1.18756rem",
                                      height: "0.89063rem",
                                      marginRight: "0.5rem",
                                      marginLeft: "0.5rem",
                                    }}
                                  />
                                </span>
                                <span>{selectedItem.country}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="abstainButtons">
                          <button
                            className="buttonChange"
                            style={{
                              border:
                                countryStatus === true
                                  ? "1px solid #FFD262"
                                  : "1px solid #029455",
                              color:
                                countryStatus === true ? "#464E5F" : "#029455",
                            }}
                            onClick={handlePopupClose}
                          >
                            Changer le vote/Change vote
                          </button>
                          <button
                            className="buttonConfirm"
                            style={{
                              background:
                                countryStatus === true ? "#FFD262" : "#029455",
                              color:
                                countryStatus === true ? "#464E5F" : "#FFF",
                            }}
                            onClick={handleSubmitVote}
                          >
                            Confirmer/Confirm
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </>
          ) : (
            <div>
              <div className="successVote">
                <div className="content">
                  <div
                    style={{
                      width: "0.875rem",
                      height: "13rem",
                      flexShrink: "0",
                    }}
                  >
                    {countryStatus === true ? (
                      <svg
                        width="14"
                        height="209"
                        viewBox="0 0 14 209"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M0 0H14V300H0V0Z" fill="#EBB64F" />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="209"
                        viewBox="0 0 14 209"
                        fill="none"
                      >
                        <path d="M0 0H14V209H0V0Z" fill="#029455" />
                      </svg>
                    )}
                  </div>
                  <div>
                    <div style={{ marginTop: "1.5rem", marginLeft: "1.5rem" }}>
                      {greenTick}
                    </div>
                    <h6 style={{ marginLeft: "1.5rem" }}>
                      Vote soumis avec succès /Vote successfully submitted{" "}
                    </h6>
                    <p style={{ marginLeft: "1.5rem" }}>
                      Vous avez soumis votre vote avec succès / You have
                      successfully submitted your vote.
                    </p>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginTop: "4%",
                      }}
                    >
                      <SignOutButton />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <>
          <div className="container-fluid text-center">
            <div
              className="w-100 d-flex flex-column align-items-center justify-content-center "
              style={{ overflow: "hidden", height: "100vh" }}
            >
              <div className="w-100 d-flex flex-column justify-content-center align-items-center">
                <div
                  className="spinner-border text-success"
                  role="status"
                  style={{ width: "100px", height: "100px" }}
                ></div>
                <p
                  style={{
                    fontFamily: "DM Sans",
                    fontSize: "1.7rem",
                    fontStyle: "normal",
                    fontWeight: "700",
                    lineHeight: "normal",
                    marginTop: "10px",
                  }}
                >
                  Veuillez patienter / Please wait
                </p>
                <SignOutButton />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default CenteredCardComponent;
